import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CallCalling } from 'iconsax-react';
import { signOut } from 'firebase/auth';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Close, Contrast, Logout } from '@mui/icons-material';
import { ReactComponent as LogoWhite } from '@userpath/components/assets/svg/logo_white.svg';
import { Lang, SUPPORTED_LANGUAGES } from '@userpath/localization';
import { useGlobalContext } from '@userpath/utils';
import {
  AppButton,
  ContactDialog,
  useFirebaseAuth,
} from '@userpath/components';
import { Styled } from './styles';

interface IProps {
  toggleDrawer: () => void;
  items: { title: string; path: string }[];
}
const HeaderDrawer: FC<IProps> = ({ toggleDrawer, items }) => {
  const { auth, isLoggedIn } = useFirebaseAuth();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const globalContext = useGlobalContext();
  const isArabic = i18n.language === Lang.AR;
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Styled.Container
      className={globalContext.isColorBlindActive ? 'blindness-mode' : ''}
    >
      <Styled.LogoRow>
        <LogoWhite height={44} width={undefined} />
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="start"
          onClick={toggleDrawer}
        >
          <Close />
        </IconButton>
      </Styled.LogoRow>
      <Styled.HeaderDivider />
      <List>
        {items.map((item, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton
              onClick={() => {
                toggleDrawer();
                navigate(item.path);
              }}
            >
              <ListItemText>
                <Stack alignItems="center" direction="row">
                  <Typography variant="h4" component="span">
                    {t(item.title)}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Styled.HeaderDivider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              i18n.changeLanguage(i18n.language == Lang.EN ? Lang.AR : Lang.EN)
            }
          >
            <ListItemText>
              <Stack alignItems="center" direction="row" gap={2}>
                <Styled.GlobeIcon />
                <Typography variant="h5" component="span">
                  {isArabic
                    ? SUPPORTED_LANGUAGES[0].name
                    : SUPPORTED_LANGUAGES[1].name}
                </Typography>
              </Stack>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              globalContext.updateColorBlind(!globalContext.isColorBlindActive);
            }}
          >
            <ListItemText>
              <Stack alignItems="center" direction="row" gap={2}>
                <Contrast fontSize="size24" />
                <Typography variant="h5" component="span">
                  {t('header.changeMode')}
                </Typography>
              </Stack>
            </ListItemText>
          </ListItemButton>
        </ListItem>
        {isLoggedIn && (
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                signOut(auth);
                toggleDrawer();
              }}
            >
              <ListItemText>
                <Stack alignItems="center" direction="row" gap={2}>
                  <Logout fontSize="size24" />
                  <Typography variant="h5" component="span">
                    {t('header.signOut')}
                  </Typography>
                </Stack>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <AppButton onClick={handleClickOpen}>
        <Stack alignItems="center" direction="row" gap={2}>
          <CallCalling variant="Bulk" />
          <Typography component="span">{t('header.contact')}</Typography>
        </Stack>
      </AppButton>
      <ContactDialog isOpen={open} handleClose={handleClose} />
    </Styled.Container>
  );
};

export default HeaderDrawer;
