export const testEmpty = (value: unknown) =>
  typeof value === 'string' && value.trim().length == 0;

export const getLocalized = <T>(
  isArabic: boolean,
  english: T | undefined,
  arabic: T | undefined,
): T | undefined => {
  if (isArabic) {
    return testEmpty(arabic) ? english : arabic;
  } else {
    return testEmpty(english) ? arabic : english;
  }
};

export const getImage = (
  image: string | File | undefined,
): string | undefined => {
  if (image === null || image === undefined || typeof image === 'string') {
    return image;
  } else {
    return URL.createObjectURL(image);
  }
};
