import React, { useEffect, useRef } from 'react';
import { DesktopStyled, MobileStyled } from './styles';

interface IDeviceProps {
  device: 'desktop' | 'mobile';
}

const Device: React.FC<React.PropsWithChildren<IDeviceProps>> = ({
  device,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>();
  const isArabic = document.body.dir == 'rtl';
  useEffect(() => {
    const containerElement = containerRef.current;
    const parentElement = containerElement?.parentElement;
    const topParentElement = parentElement?.parentElement;
    if (!containerElement || !parentElement || !topParentElement) return;
    const handleSize = () => {
      const computedStyle = getComputedStyle(parentElement);
      const parentWidth =
        parentElement.clientWidth -
        parseFloat(computedStyle.paddingTop) -
        parseFloat(computedStyle.paddingBottom);
      const parentHeight =
        parentElement.clientHeight -
        parseFloat(computedStyle.paddingLeft) -
        parseFloat(computedStyle.paddingRight);

      const childWidth = device == 'desktop' ? 1188 : 427;
      const childHeight = device == 'desktop' ? 720 : 864;

      const scaleX = parentWidth / childWidth;
      const scaleY = parentHeight / childHeight;
      const scale = Math.min(scaleX, scaleY);
      const mod = isArabic ? -1 : 1;
      const translateX = ((parentWidth - childWidth * scale) / 2 / scale) * mod;
      const translateY = (parentHeight - childHeight * scale) / 2 / scale;
      containerElement.style.transform = `scale(${scale}) translateX(${translateX}px) translateY(${translateY}px)`;
      containerElement.style.transformOrigin = isArabic
        ? 'top right'
        : 'top left';
    };
    handleSize();

    const resizeObserver = new ResizeObserver(handleSize);
    resizeObserver.observe(containerElement);

    return () => resizeObserver.disconnect();
  }, [device, isArabic]);

  if (device == 'desktop') {
    return (
      <DesktopStyled.Device ref={containerRef}>
        <DesktopStyled.TopBar />
        <DesktopStyled.Camera />
        <DesktopStyled.Screen>{children}</DesktopStyled.Screen>
        <DesktopStyled.BottomBar />
      </DesktopStyled.Device>
    );
  } else {
    return (
      <MobileStyled.Device ref={containerRef}>
        <MobileStyled.Notch>
          <MobileStyled.Camera />
          <MobileStyled.Speaker />
        </MobileStyled.Notch>
        <MobileStyled.TopBar />
        <MobileStyled.Sleep />
        <MobileStyled.BottomBar />
        <MobileStyled.Volume />
        <MobileStyled.Shadow>
          <MobileStyled.ShadowTR />
          <MobileStyled.ShadowTL />
          <MobileStyled.ShadowBR />
          <MobileStyled.ShadowBL />
        </MobileStyled.Shadow>
        <MobileStyled.InnerShadow />
        <MobileStyled.Screen>{children}</MobileStyled.Screen>
      </MobileStyled.Device>
    );
  }
};

export default Device;
