import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import HeaderPhone from '@userpath/components/assets/images/header_phone.png';
import { CustomContainer } from '@userpath/components';
import { Styled } from './styles';
import { HeaderItems } from '.';

const HomeHeader: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <CustomContainer sx={{ paddingBottom: { lg: '110px' } }}>
      <Grid container columnSpacing="124px">
        <Grid item xs={12} md={6} lg={7}>
          <Styled.Container>
            <Styled.Title>{t('header.title')}</Styled.Title>
            <Styled.ItemsContainer>
              <HeaderItems />
            </Styled.ItemsContainer>
          </Styled.Container>
        </Grid>
        <Grid
          textAlign="center"
          item
          display={{ xs: 'none', md: 'block' }}
          md={6}
          lg={5}
        >
          <Box display="flex" justifyContent="end">
            <Styled.FlippedImage src={HeaderPhone} />
          </Box>
        </Grid>
      </Grid>
    </CustomContainer>
  );
};

export default HomeHeader;
