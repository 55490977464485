import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Library } from '@userpath/constants';
import { ServicesConstants, ServiceIndex, ServiceIcon } from '../../../common';
import { Styled } from './styles';

interface IServiceHeaderItemProps {
  index: ServiceIndex;
  selected?: boolean;
  onSelected?: () => void;
}

const ServiceHeaderItem: React.FC<IServiceHeaderItemProps> = ({
  index,
  selected,
  onSelected,
}) => {
  const { t } = useTranslation(Library.userpath);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Styled.HeaderItem
      ref={ref}
      onClick={() => {
        ref.current?.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
          inline: 'center',
        });
        onSelected?.();
      }}
      selected={selected}
      itemColor={ServicesConstants.IconColors[index]}
    >
      <Stack alignItems="center" direction="row" gap="4px">
        <ServiceIcon index={index} selected={selected} />
        {t(`services.${index}.title`)}
      </Stack>
    </Styled.HeaderItem>
  );
};

export default ServiceHeaderItem;
