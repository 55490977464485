import * as React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Fade, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ContactForm from './ContactForm';
import { Styled } from './styles';

interface IContactDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

const ContactDialog: React.FC<IContactDialogProps> = ({
  isOpen,
  handleClose,
}) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      transitionDuration={500}
      TransitionComponent={Transition}
    >
      <Styled.Dialog>
        <IconButton
          edge="start"
          color="secondaryLight"
          onClick={handleClose}
          aria-label="close"
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Close />
        </IconButton>
        <ContactForm
          variant="dialog"
          closeButton={handleClose}
          sx={{
            paddingTop: { md: '64px', lg: '128px' },
          }}
        />
      </Styled.Dialog>
    </Dialog>
  );
};

export default ContactDialog;
