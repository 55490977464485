import { Box, Fab, styled } from '@mui/material';

export const Styled = {
  Container: styled(Box)(({ theme }) => ({
    padding: '36px',
    display: 'flex',
    gap: '18px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '18px',
    },
  })),
  ContactFAB: styled(Fab)(({ theme }) => ({
    margin: 0,
    position: 'fixed',
    top: 'auto',
    bottom: '24px',
    ...theme.directional({
      right: '16px',
      left: 'auto',
    }),
    opacity: 0.72,
    background: theme.palette.text.neutral20,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  })),
};
