import React from 'react';
import { Box, alpha } from '@mui/material';

interface IIndicatorProps {
  page: number;
  totalPages: number;
}

const Indicator: React.FC<IIndicatorProps> = ({ page, totalPages }) => {
  return (
    <Box display="flex" gap="16px">
      {Array.from(Array(totalPages)).map((_, i) => {
        return (
          <Box
            key={i}
            sx={(theme) => ({
              transition: 'background-color 300ms',
              width: '24px',
              height: '8px',
              borderRadius: '8px',
              backgroundColor:
                page == i
                  ? theme.palette.primary.secondary
                  : alpha(theme.palette.background.default, 0.16),
            })}
          />
        );
      })}
    </Box>
  );
};

export default Indicator;
