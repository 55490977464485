import { Typography } from '@mui/material/styles/createTypography';
const FONT_PRIMARY = 'Neo Sans Pro, sans-serif';
const FONT_SECONDARY = 'DIN Next LT Arabic, Neo Sans Pro, sans-serif';

export const getTypographyConfig = (
  dir: string,
  isMobile: boolean,
  fontIncrease: number,
): Typography => {
  const sizes = {
    h1: isMobile ? 48 : 128 + fontIncrease,
    h2: isMobile ? 34 : 72 + fontIncrease,
    h3: isMobile ? 24 : 64 + fontIncrease,
    h4: isMobile ? 16 : 32 + fontIncrease,
    h5: isMobile ? 16 : 24 + fontIncrease,
    h6: isMobile ? 14 : 16 + fontIncrease,
    h7: isMobile ? 14 : 14 + fontIncrease,
    h8: isMobile ? 16 : 16 + fontIncrease,
    h9: isMobile ? 12 : 12 + fontIncrease,
    contactHeader: isMobile ? 24 : 54 + fontIncrease,
    custom: (props: { lg: number; sm: number }) =>
      isMobile ? props.sm : props.lg + fontIncrease,
  };
  const fontFamily = dir === 'ltr' ? FONT_PRIMARY : FONT_SECONDARY;
  const pxToRem = (value: number) => `${value / 12}rem`;

  return {
    pxToRem,
    htmlFontSize: 12,
    fontSize: sizes.h6,
    fontPrimary: FONT_PRIMARY,
    fontSecondary: FONT_SECONDARY,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontWeight: 500,
      fontSize: sizes.h1,
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: 700,
      fontSize: sizes.h2,
      lineHeight: dir === 'ltr' ? 1.0 : 1.5,
    },
    h3: {
      fontWeight: 500,
      fontSize: sizes.h3,
      lineHeight: 1.5,
    },
    h4: {
      fontWeight: 400,
      fontSize: sizes.h4,
      lineHeight: 1.5,
    },
    h5: {
      fontWeight: 400,
      fontSize: sizes.h5,
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 400,
      fontSize: sizes.h6,
      lineHeight: 1.5,
    },
    h7: {
      fontWeight: 400,
      fontSize: sizes.h7,
      lineHeight: 1.5,
    },
    h8: {
      fontWeight: 400,
      fontSize: sizes.h8,
      lineHeight: 1.5,
    },
    h9: {
      fontWeight: 400,
      fontSize: sizes.h9,
      lineHeight: 1.5,
    },
    body1: {
      fontWeight: 400,
      fontSize: sizes.h6, // not used
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 500,
      fontSize: sizes.contactHeader,
      lineHeight: 1.5,
    },
    subtitle1: {},
    subtitle2: {
      fontWeight: 400,
      fontSize: sizes.h9,
      lineHeight: 1.5,
    },
    caption: {
      fontWeight: 500,
      fontSize: sizes.h8,
      lineHeight: 1.5,
    },
    button: {
      fontSize: sizes.h7,
      lineHeight: 1.5,
      fontWeight: 400,
      textTransform: 'none',
    },
    overline: {
      fontWeight: 400,
      fontSize: sizes.h9,
      lineHeight: 1.5,
    },
    sizes,
  };
};
