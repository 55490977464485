import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Box, Grid } from '@mui/material';
import { Check } from '@mui/icons-material';
import { Library } from '@userpath/constants';
import {
  CanvasLines,
  CustomContainer,
  GradientTypography,
} from '@userpath/components';

import { CareerItems } from './components';

const CareersPage: React.FC = () => {
  const { t } = useTranslation(Library.userpath);
  return (
    <Box>
      <CanvasLines
        getPoints={({ height, isMobile }) => {
          const st = isMobile ? height - 200 : Math.min(height - 200, 104);
          const p1 = isMobile ? 40 : 140;
          const extraX = isMobile ? 120 : 280;
          const radius = isMobile ? 70 : 140;
          return [
            { x: 0, y: st },
            { x: p1, y: st, radius: radius },
            { x: p1, y: height + 100 },
            { x: p1 + extraX, y: height + 100 },
            { x: p1 + extraX, y: height - 70, radius: radius },
            { x: 0, y: height - 70 },
          ];
        }}
      >
        <CustomContainer
          sx={{
            paddingTop: { xs: '50px', md: '104px' },
            paddingBottom: '80px',
          }}
        >
          <Stack textAlign="center" alignItems="center">
            <GradientTypography variant="h1" gradient="primary" animated>
              {t('careers.partnership')}
            </GradientTypography>
            <Typography
              variant="h5"
              sx={(theme) => ({
                margin: { md: '0px 64px', lg: '0px 124px', xl: '0px 194px' },
                marginTop: '32px',
                lineHeight: 1.5,
                maxWidth: '1024px',
                color: theme.palette.text.neutral80,
              })}
            >
              {t('careers.partnershipDetails')}
            </Typography>
          </Stack>
          <br />
          <Stack textAlign="center" direction="row" gap={1}>
            <GradientTypography
              variant="h5"
              component="span"
              gradient="variant3"
              width="100%"
            >
              <Typography
                variant="h5"
                component="span"
                sx={(theme) => ({
                  WebkitTextFillColor: theme.palette.text.neutral80,
                })}
              >
                {t('careers.emailUs')}
              </Typography>{' '}
              <a
                style={{ fontWeight: 500 }}
                href="mailto:Partnerships@userpath.sa"
              >
                Partnerships@userpath.sa
              </a>
            </GradientTypography>
          </Stack>
        </CustomContainer>
      </CanvasLines>
      <Box
        paddingBottom={{ xs: '60px', md: '36px' }}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          paddingTop: '60px',
        })}
      >
        <CustomContainer>
          <Box paddingTop="36px">
            <Typography variant="h3" component="span" lineHeight={0.8}>
              {t('careers.careers')}
            </Typography>
          </Box>
          <Box padding="36px 0px">
            <Typography variant="h5" component="span">
              {t('careers.workUs')}
            </Typography>
          </Box>
          <CareerItems />
        </CustomContainer>
      </Box>
      <CustomContainer sx={{ paddingTop: '76px' }}>
        <Stack direction="row" gap={1}>
          <GradientTypography
            variant="h5"
            component="span"
            gradient="variant3"
            width="100%"
          >
            <Typography
              variant="h5"
              component="span"
              sx={(theme) => ({
                WebkitTextFillColor: theme.palette.text.neutral80,
              })}
            >
              {t('careers.workDetails')}
            </Typography>{' '}
            <a style={{ fontWeight: 500 }} href="mailto:Jobs@userpath.sa">
              Jobs@userpath.sa
            </a>
          </GradientTypography>
        </Stack>
        <Box padding={{ xs: '60px 0px', md: '84px 0px' }}>
          <Grid container alignItems="flex-start" spacing="18px">
            {Array.from(Array(5)).map((_, i) => (
              <Grid item xs={6} sm={4} md={3} lg key={i}>
                <Stack direction="row" gap="8px" padding="32px 0px">
                  <Check fontSize="responsive24" color="neutral90" />
                  <Typography
                    variant="h5"
                    component="span"
                    color={(theme) => theme.palette.text.neutral100}
                  >
                    {t(`careers.workRequirement${i + 1}`)}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Typography variant="h5" fontWeight={500}>
          {t('careers.lookForward')}
        </Typography>
      </CustomContainer>
    </Box>
  );
};

export default CareersPage;
