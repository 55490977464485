import { useCallback, useEffect, useReducer } from 'react';
import { signInAnonymously } from 'firebase/auth';
import { Action, apiReducer, initialAPIState } from '@userpath/utils';
import { useArticles } from '@userpath/services';
import { AppError } from '@userpath/types';
import { useFirebaseAuth } from './useFirebaseAuth';

export const useArticleLike = (id: string) => {
  const articlesServices = useArticles();
  const auth = useFirebaseAuth();
  const [{ data, loading, error }, dispatchArticles] = useReducer(
    apiReducer<{ liked: boolean; changed: boolean }, AppError>(),
    initialAPIState,
  );
  const checkUserLike = useCallback(
    async (uid: string) => {
      dispatchArticles({ type: Action.loading });
      try {
        const didLike = await articlesServices.didUserLikeArticle(uid, id);
        dispatchArticles({
          type: Action.success,
          payload: { liked: didLike, changed: false },
        });
      } catch (error) {
        dispatchArticles({
          type: Action.error,
          error: AppError.fromError(error),
        });
      }
    },
    [articlesServices, id],
  );
  const toggleArticleLike = useCallback(async () => {
    if (data == undefined || auth.uid == undefined) return;
    dispatchArticles({ type: Action.loading });
    try {
      if (data.liked) {
        await articlesServices.dislikeArticle(auth.uid, id);
      } else {
        await articlesServices.likeArticle(auth.uid, id);
      }
      dispatchArticles({
        type: Action.success,
        payload: { liked: !data.liked, changed: !data.changed },
      });
    } catch (error) {
      dispatchArticles({
        type: Action.error,
        error: AppError.fromError(error),
      });
    }
  }, [articlesServices, auth.uid, data, id]);

  useEffect(() => {
    if (auth.isAnonymousLoggedIn == false) {
      signInAnonymously(auth.auth);
    }
  }, [auth.auth, auth.isAnonymousLoggedIn]);
  useEffect(() => {
    if (auth.uid) {
      checkUserLike(auth.uid);
    } else {
      dispatchArticles({
        type: Action.success,
        payload: { liked: false, changed: false },
      });
    }
  }, [auth.uid, checkUserLike]);

  return {
    liked: data?.liked,
    loading,
    error,
    changed: data?.changed || false,
    toggleArticleLike,
  };
};
