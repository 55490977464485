import { Button, IconButton, styled } from '@mui/material';

export const Styled = {
  Button: styled(Button)(({ theme }) => ({
    color: theme.palette.text.neutral,
    display: 'flex',
    alignItems: 'start',
    fontSize: theme.typography.sizes.h8,
    gap: 8,
  })),
  IconButton: styled(IconButton)(({ theme }) => ({
    color: theme.palette.text.neutral,
    padding: 4,
    '&.Mui-disabled': {
      color: theme.palette.text.neutral50,
    },
  })),
  Separator: styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.text.neutral,
    width: 1,
    height: 22,
    margin: '0px 14px',
  })),
};
