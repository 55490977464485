import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme, useTheme } from '@mui/material';
import {
  useBreakpoints,
  BreakpointsResult,
  useElementInView,
} from '@userpath/components';

export interface ICanvasDrawProps<TCache> {
  context: CanvasRenderingContext2D;
  theme: Theme;
  timestamp: number;
  isMobile: boolean;
  isRTL: boolean;
  preparedValue?: TCache;
  breakpoints: BreakpointsResult;
}
export interface ICanvasPrepareProps {
  width: number;
  height: number;
  breakpoints: BreakpointsResult;
  isMobile: boolean;
  isRTL: boolean;
}

interface ICanvasProps<TCache> {
  withAnimation?: boolean;
  draw: (props: ICanvasDrawProps<TCache>) => boolean;
  prepare?: (props: ICanvasPrepareProps) => TCache;
}

const Canvas = <TCache = never,>({
  children,
  withAnimation,
  draw,
  prepare,
  ...props
}: ICanvasProps<TCache> &
  Omit<React.CanvasHTMLAttributes<HTMLCanvasElement>, 'ref'>): JSX.Element => {
  const { i18n } = useTranslation();
  const { inView, assignRef } = useElementInView();
  const isRTL = i18n.language === 'ar';
  const theme = useTheme();
  const breakpoints = useBreakpoints();
  const isMobile = !breakpoints.isMD;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const startTimestamp = useRef<number>(-1);
  useEffect(() => {
    assignRef(canvasRef.current);
  }, [assignRef, canvasRef]);

  useEffect(() => {
    if (!inView) return;
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (!context || !canvas) return;
    let lastTimestamp = 0;
    let animationFrameId = -1;
    let preparedValue: TCache | undefined;
    const handleResize = () => {
      if (prepare) {
        preparedValue = prepare({
          width: canvas.offsetWidth,
          height: canvas.offsetHeight,
          breakpoints,
          isMobile,
          isRTL,
        });
      }
      if (withAnimation)
        animationFrameId = window.requestAnimationFrame(render);
      else render(lastTimestamp);
    };
    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(canvas);
    const render = (timestamp: number) => {
      if (startTimestamp.current == -1) startTimestamp.current = timestamp;
      lastTimestamp = timestamp;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      context.clearRect(0, 0, canvas.width, canvas.height);
      if (preparedValue == undefined && prepare) {
        preparedValue = prepare({
          width: canvas.width,
          height: canvas.height,
          isMobile,
          breakpoints,
          isRTL,
        });
      }
      const result = draw({
        context,
        theme,
        timestamp: timestamp - startTimestamp.current,
        isMobile,
        isRTL,
        preparedValue,
        breakpoints,
      });
      if (withAnimation && result)
        animationFrameId = window.requestAnimationFrame(render);
    };
    handleResize();
    return () => {
      if (animationFrameId != -1) window.cancelAnimationFrame(animationFrameId);
      resizeObserver.disconnect();
    };
  }, [
    draw,
    prepare,
    withAnimation,
    canvasRef,
    theme,
    isMobile,
    isRTL,
    breakpoints,
    inView,
  ]);

  return (
    <canvas ref={canvasRef} {...props}>
      {children}
    </canvas>
  );
};

export default Canvas;
