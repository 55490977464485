import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerProps } from '@mui/material';
import { Styled } from './styles';

interface INotFoundPageProps {
  article?: boolean;
}

const NotFoundPage: React.FC<INotFoundPageProps & ContainerProps> = ({
  article,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Styled.Container {...props}>
      <svg
        width="275"
        height="168"
        viewBox="0 0 275 168"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50.0515 33.7366L15.6765 88.7835C14.8601 90.0877 14.8257 91.7306 15.5862 93.0686C16.3468 94.4067 17.7819 95.2366 19.3374 95.2366H58.0093C60.3811 95.2366 62.3061 93.3396 62.3061 91.0023C62.3061 88.6649 60.3811 86.7679 58.0093 86.7679H27.0288L57.3733 38.1742C58.6151 36.1841 57.9835 33.5757 55.964 32.3477C53.9444 31.124 51.2976 31.7464 50.0515 33.7366Z"
          fill="url(#paint0_linear_1619_26902)"
        />
        <path
          d="M53.7109 69.8301V129.111C53.7109 131.449 55.6359 133.346 58.0078 133.346C60.3797 133.346 62.3047 131.449 62.3047 129.111V69.8301C62.3047 67.4927 60.3797 65.5957 58.0078 65.5957C55.6359 65.5957 53.7109 67.4927 53.7109 69.8301Z"
          fill="url(#paint1_linear_1619_26902)"
        />
        <path
          d="M247.708 33.7366L213.333 88.7835C212.516 90.0877 212.482 91.7306 213.242 93.0686C214.003 94.4067 215.438 95.2366 216.994 95.2366H255.666C258.037 95.2366 259.962 93.3396 259.962 91.0023C259.962 88.6649 258.037 86.7679 255.666 86.7679H224.685L255.03 38.1742C256.271 36.1841 255.64 33.5757 253.62 32.3477C251.601 31.124 248.954 31.7464 247.708 33.7366Z"
          fill="url(#paint2_linear_1619_26902)"
        />
        <path
          d="M251.367 69.8301V129.111C251.367 131.449 253.292 133.346 255.664 133.346C258.036 133.346 259.961 131.449 259.961 129.111V69.8301C259.961 67.4927 258.036 65.5957 255.664 65.5957C253.292 65.5957 251.367 67.4927 251.367 69.8301Z"
          fill="url(#paint3_linear_1619_26902)"
        />
        <path
          d="M82.7154 68.1501C87.8502 65.2284 96.3494 70.2503 101.686 79.3585C107.023 88.4666 107.186 98.2311 102.051 101.153C96.9166 104.075 88.4174 99.0525 83.0807 89.9444C77.744 80.8363 77.5807 71.0718 82.7154 68.1501Z"
          fill="#CADCF0"
        >
          <Animate />
        </path>
        <path
          d="M192.286 68.1501C187.151 65.2284 178.652 70.2503 173.315 79.3585C167.978 88.4666 167.815 98.2311 172.95 101.153C178.085 104.075 186.584 99.0525 191.921 89.9444C197.257 80.8363 197.421 71.0718 192.286 68.1501Z"
          fill="#CADCF0"
        >
          <Animate />
        </path>
        <path
          d="M141.797 18.1331C165.017 18.5142 186.51 38.0812 184.766 73.18C183.691 94.7245 170.152 100.602 162.645 123.988C158.984 135.396 158.606 146.913 158.739 153.146C158.795 154.374 158.305 155.564 157.399 156.402C156.492 157.245 155.255 157.656 154.017 157.524C126.07 153.451 90.2344 116.913 90.2344 81.6487C90.2344 44.2549 113.343 17.6631 141.797 18.1331Z"
          fill="#CADCF0"
        >
          <Animate />
        </path>
        <path
          d="M92.7352 98.2898C91.1238 92.7936 90.2344 87.2084 90.2344 81.6487C90.2344 44.2549 113.343 17.6631 141.797 18.1331C159.388 18.421 175.991 29.7226 182.243 50.4456C178.922 82.4532 153.738 107.373 123.209 107.373C112.071 107.373 101.647 104.053 92.7352 98.2898Z"
          fill="#E9F3FC"
        >
          <Animate />
        </path>
        <path
          d="M137.5 91.002C142.246 91.002 146.094 85.3146 146.094 78.2988C146.094 71.2831 142.246 65.5957 137.5 65.5957C132.754 65.5957 128.906 71.2831 128.906 78.2988C128.906 85.3146 132.754 91.002 137.5 91.002Z"
          fill="#738BAB"
        >
          <Animate />
        </path>
        <path
          d="M124.446 49.2167C125.223 52.0198 125.271 56.775 121.021 59.6078C120.037 60.2599 119.779 61.5768 120.441 62.5465C121.107 63.5162 122.443 63.7702 123.423 63.1139C129.598 59.0065 129.718 52.1723 128.592 48.103C128.278 46.9725 127.097 46.3077 125.954 46.6168C124.811 46.9216 124.136 48.0861 124.446 49.2167Z"
          fill="#738BAB"
        >
          <Animate />
        </path>
        <path
          d="M146.407 48.103C145.281 52.1723 145.402 59.0065 151.576 63.1139C152.556 63.7702 153.892 63.5162 154.558 62.5465C155.22 61.5768 154.962 60.2599 153.978 59.6078C149.729 56.775 149.776 52.0198 150.554 49.2167C150.863 48.0861 150.188 46.9216 149.045 46.6168C147.902 46.3077 146.721 46.9725 146.407 48.103Z"
          fill="#738BAB"
        >
          <Animate />
        </path>
        <defs>
          <linearGradient
            id="paint0_linear_1619_26902"
            x1="15.0129"
            y1="63.479"
            x2="135.5"
            y2="-32.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FED573" />
            <stop offset="0.41" stopColor="#E5774D" />
            <stop offset="0.64" stopColor="#AE02A3" />
            <stop offset="1" stopColor="#1801A1" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1619_26902"
            x1="53.7062"
            y1="99.471"
            x2="131.5"
            y2="40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FED573" />
            <stop offset="0.41" stopColor="#E5774D" />
            <stop offset="0.64" stopColor="#AE02A3" />
            <stop offset="1" stopColor="#1801A1" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1619_26902"
            x1="212.5"
            y1="100.5"
            x2="333"
            y2="-30"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FED573" />
            <stop offset="0.41" stopColor="#E5774D" />
            <stop offset="0.64" stopColor="#AE02A3" />
            <stop offset="1" stopColor="#1801A1" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1619_26902"
            x1="251.362"
            y1="99.471"
            x2="323.5"
            y2="61"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FED573" />
            <stop offset="0.41" stopColor="#E5774D" />
            <stop offset="0.64" stopColor="#AE02A3" />
            <stop offset="1" stopColor="#1801A1" />
          </linearGradient>
        </defs>
      </svg>

      <Styled.Title>
        {t(article ? 'errors.articleNotFound' : 'errors.pageNotFound')}
      </Styled.Title>
      <Styled.Details>
        {t(
          article
            ? 'errors.articleNotFoundDetails'
            : 'errors.pageNotFoundDetails',
        )}
      </Styled.Details>
    </Styled.Container>
  );
};
const Animate: React.FC = () => {
  return (
    <animateTransform
      attributeName="transform"
      type="rotate"
      values="0 137.5 84; 15 137.5 84; 0 137.5 84; -15 137.5 84; 0 137.5 84"
      dur="4s"
      repeatCount="indefinite"
    />
  );
};
export default NotFoundPage;
