import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { Library } from '@userpath/constants';
import { Lang } from '@userpath/localization';
import {
  ArticleContent,
  CustomContainer,
  useArticleLoader,
  useFirebaseAuth,
  useScrollThreshold,
} from '@userpath/components';
import { NotFoundPage } from '../not-found';
import { Styled } from './styles';

const ArticleDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation(Library.userpath);
  const { isLoggedIn } = useFirebaseAuth();
  const isScrolled = useScrollThreshold(300);
  const { loadArticle, data, error, loading } = useArticleLoader();
  const isArabic = i18n.language == Lang.AR;
  const Icon = useMemo(
    () => (isArabic ? ArrowCircleRightOutlined : ArrowCircleLeftOutlined),
    [isArabic],
  );
  useEffect(() => {
    if (id && data?.id != id) loadArticle(id);
  }, [loadArticle, id, data?.id]);

  return (
    <CustomContainer sx={{ paddingTop: { xs: '50px', md: '104px' } }}>
      <Button
        variant="text"
        sx={(theme) => ({
          display: { xs: 'none', md: 'inline-flex' },
          borderRadius: '4px',
          marginBottom: '24px',
          '& .MuiButton-startIcon': {
            marginLeft: isArabic ? '8px' : '-4px',
            marginRight: isArabic ? '-4px' : '8px',
          },
          ...theme.directional({
            marginLeft: '-8px',
          }),
        })}
        startIcon={<Icon />}
        onClick={() => {
          navigate(`/articles`);
        }}
      >
        {t('articles.goBack')}
      </Button>
      {loading && (
        <Styled.Container>
          <CircularProgress />
        </Styled.Container>
      )}
      {error != undefined && <NotFoundPage article />}
      {data && (
        <ArticleContent
          article={data}
          showAdminButtons={isLoggedIn}
          content={
            isArabic
              ? data.contentArabic ?? data.contentEnglish
              : data.contentEnglish ?? data.contentArabic
          }
        />
      )}
      {isScrolled && (
        <Styled.ContactFAB
          color="primary"
          onClick={() =>
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
          }
        >
          <KeyboardArrowUp color="neutral" fontSize="large" />
        </Styled.ContactFAB>
      )}
    </CustomContainer>
  );
};

export default ArticleDetailsPage;
