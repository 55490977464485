import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, useController, FieldPath } from 'react-hook-form';
import {
  LinkBubbleMenu,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from 'mui-tiptap';
import { EditorOptions } from '@tiptap/core';
import { Box } from '@mui/material';
import { AppInputLabel, useTiptapExtensions } from '@userpath/components';
import { Lang } from '@userpath/localization';
import { Library } from '@userpath/constants';
import { IArticleInputs } from '../../constants';
import { Styled } from './styles';
import ContentMenuControls from './ContentMenuControls';

interface IContentInputProps {
  control: Control<IArticleInputs>;
  index: number;
  disableFields?: boolean;
  getOtherValue: (key: FieldPath<IArticleInputs>) => string | undefined;
}

function fileListToImageFiles(fileList: FileList): File[] {
  // You may want to use a package like attr-accept
  // (https://www.npmjs.com/package/attr-accept) to restrict to certain file
  // types.
  return Array.from(fileList).filter((file) => {
    const mimeType = (file.type || '').toLowerCase();
    return mimeType.startsWith('image/');
  });
}

const ContentInput: React.FunctionComponent<IContentInputProps> = ({
  control,
  index,
  disableFields,
  getOtherValue,
}) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const isArabic = i18n.language == Lang.AR;
  const lang = index == 0 ? 'english' : 'arabic';
  const otherLang = index == 0 ? 'arabic' : 'english';
  const rteRef = useRef<RichTextEditorRef>(null);
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: `content_${lang}`,
    control: control,
  });
  const isEmpty = !value || value.trim().length == 0 || value == '<p></p>';
  const editor = rteRef.current?.editor;

  const getOtherContent = useCallback(
    () => getOtherValue(`content_${otherLang}`),
    [getOtherValue, otherLang],
  );

  const handleNewImageFiles = useCallback(
    async (files: File[], insertPosition?: number): Promise<void> => {
      if (!rteRef.current?.editor) {
        return;
      }
      // toggleLoader(true);
      // await new Promise((f) => setTimeout(f, 2000));
      // toggleLoader(false);
      const attributesForImageFiles = files.map((file) => ({
        src: URL.createObjectURL(file),
        alt: file.name,
      }));

      insertImages({
        images: attributesForImageFiles,
        editor: rteRef.current.editor,
        position: insertPosition,
      });
    },
    [],
  );

  // Allow for dropping images into the editor
  const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> =
    useCallback(
      (view, event) => {
        if (!(event instanceof DragEvent) || !event.dataTransfer) {
          return false;
        }

        const imageFiles = fileListToImageFiles(event.dataTransfer.files);
        if (imageFiles.length > 0) {
          const insertPosition = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          })?.pos;

          handleNewImageFiles(imageFiles, insertPosition);

          event.preventDefault();
          return true;
        }

        return false;
      },
      [handleNewImageFiles],
    );

  // Allow for pasting images
  const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> =
    useCallback(
      (_view, event) => {
        if (!event.clipboardData) {
          return false;
        }
        const pastedImageFiles = fileListToImageFiles(
          event.clipboardData.files,
        );
        if (pastedImageFiles.length > 0) {
          handleNewImageFiles(pastedImageFiles);
          return true;
        }
        return false;
      },
      [handleNewImageFiles],
    );

  useEffect(() => {
    const isEmpty = !value || value.trim().length == 0 || value == '<p></p>';
    if (!editor || editor.isDestroyed || isEmpty) {
      return;
    }
    if (!editor.isFocused || !editor.isEditable) {
      queueMicrotask(() => {
        const currentSelection = editor.state.selection;
        editor
          .chain()
          .setContent(value ?? '')
          .setTextSelection(currentSelection)
          .run();
      });
    }
  }, [value, editor, editor?.isEditable, editor?.isFocused]);

  return (
    <Box>
      <AppInputLabel variantColor="light_background">
        {t('admin.article.content')}
      </AppInputLabel>

      <Styled.ContentInput
        ref={rteRef}
        error={!!error}
        extensions={useTiptapExtensions({
          placeholder: t('admin.article.enterContent'),
        })}
        editorDependencies={[isArabic]}
        content={isEmpty ? getOtherContent() : value}
        editable={!disableFields}
        editorProps={{
          handleDrop: handleDrop,
          handlePaste: handlePaste,
        }}
        onFocus={({ editor }) => {
          if (isEmpty) {
            editor.commands.setContent('');
          }
        }}
        onBlur={({ editor }) => {
          const content = editor.getHTML().trim();
          onChange(content);
          if (content.length == 0 || content === '<p></p>') {
            editor.commands.setContent(getOtherContent() ?? '');
          }
        }}
        renderControls={() => <ContentMenuControls />}
      >
        {() => (
          <>
            <LinkBubbleMenu />
            <TableBubbleMenu />
          </>
        )}
      </Styled.ContentInput>
      <Styled.HelperText error={!!error}>
        {error?.message && t(error?.message)}
      </Styled.HelperText>
    </Box>
  );
};

export default ContentInput;
