import { Box, styled } from '@mui/material';
interface IFadeTransition {
  timeout: number;
}
export const Styled = {
  FadeTransition: styled(Box)<IFadeTransition>(({ timeout }) => ({
    '&.fade-enter': {
      opacity: 0,
    },
    '&.fade-enter-active': {
      opacity: 1,
      transition: `opacity ${timeout}ms`,
    },
    '&.fade-exit': {
      opacity: 1,
    },
    '&.fade-exit-active': {
      opacity: 0,
      transition: `opacity ${timeout}ms`,
    },
  })),
};
