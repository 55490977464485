import { styled } from '@mui/material';
import { CustomContainer } from '@userpath/components';

export const Styled = {
  BGContainer: styled('div')(({ theme }) => ({
    position: 'relative',
    marginTop: '72px',
    '&::before': {
      content: '""',
      zIndex: -4,
      background: theme.palette.background.primary,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  })),
  Container: styled(CustomContainer)(({ theme }) => ({
    paddingBottom: '170px',
    display: 'flex',
    flexDirection: 'column',
    gap: '190px',
    [theme.breakpoints.down('md')]: {
      marginTop: '50px',
      gap: '210px',
    },
  })),
};
