import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  AccessibilityHeader,
  Header,
  Footer,
  useScrollToTop,
  usePageTracking,
} from '@userpath/components';

export const Layout: React.FC = () => {
  useScrollToTop();
  usePageTracking();
  return (
    <>
      <AccessibilityHeader />
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};
