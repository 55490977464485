import React from 'react';
import { Styled } from './styles';

export const toggleLoader = (active: boolean) => {
  if (active) {
    document.body.classList.add('loading-state');
  } else {
    document.body.classList.remove('loading-state');
  }
};

const Loader: React.FC = () => {
  return <Styled.Logo />;
};

export default Loader;
