import { Box, Divider, styled } from '@mui/material';
import { Public } from '@mui/icons-material';
import { CustomContainer } from '../custom-container';

export const Styled = {
  GlobeIcon: styled(Public)(({ theme }) => ({
    fontSize: '24px',
    color: theme.palette.text.neutral,
  })),
  HeaderDivider: styled(Divider)(({ theme }) => ({
    borderBottomWidth: '1px',
    borderColor: theme.palette.primary.main70,
    opacity: '20%',
    padding: '4px 0px',
  })),
  Container: styled(CustomContainer)(({ theme }) => ({
    background: `linear-gradient(180deg, ${theme.palette.background.linear1End} 0%, ${theme.palette.background.linear1Start} 100%);`,
    color: theme.palette.text.neutral,
    minHeight: '100vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: '48px',
    paddingBottom: '48px',
    width: '100%',
  })),
  LogoRow: styled(Box)(() => ({
    display: 'flex',
    height: '44px',
    width: '100%',
    justifyContent: 'space-between',
  })),
};
