import i18n, { InitOptions, use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Library } from '@userpath/constants';
import { en } from './locales/en';
import { ar } from './locales/ar';
import { Lang, SUPPORTED_LANGUAGES } from './constants';

export const defaultOptions: InitOptions = {
  resources: {
    en,
    ar,
  },
  supportedLngs: SUPPORTED_LANGUAGES.map(({ code }) => code),
  ns: Object.values(Library),
  debug: false,
  fallbackLng: Lang.EN,
  lng: localStorage.getItem('language') || undefined,
  fallbackNS: Library.common,
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  detection: {
    caches: [],
  },
};
export const setupI18next = (
  ns = Library.common,
  options: InitOptions | undefined = undefined,
): typeof i18n => {
  use(LanguageDetector)
    // .use(initReactI18next)
    .init({
      ...defaultOptions,
      defaultNS: ns,
      ...(options != undefined && options),
    });

  return i18n;
};
