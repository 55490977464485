import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Styled } from './styles';

const totalItems = 11;

const HeaderItems: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" overflow="hidden" alignItems="center" columnGap={3}>
      <Styled.With>{t('header.with')}</Styled.With>
      <Styled.List totalItems={totalItems}>
        <Styled.ListItem>
          <Styled.Item>{t(`header.item${totalItems}`)}</Styled.Item>
        </Styled.ListItem>
        {new Array(totalItems).fill(0).map((_, i) => (
          <Styled.ListItem key={i}>
            <Styled.Item>{t(`header.item${i + 1}`)}</Styled.Item>
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Box>
  );
};

export default HeaderItems;
