import React from 'react';
import { BoxProps } from '@mui/material';
import { EmptySVG, ErrorSVG } from '../animated-svg';
import { Styled } from './styles';

interface INetworkStateProps {
  type?: 'error' | 'empty';
  title?: string;
  details: string;
  variant?: 'light' | 'dark';
}

const NetworkState: React.FunctionComponent<INetworkStateProps & BoxProps> = ({
  type = 'error',
  title,
  details,
  variant = 'dark',
  ...props
}) => {
  return (
    <Styled.Content {...props}>
      {type == 'error' && <ErrorSVG />}
      {type == 'empty' && <EmptySVG />}
      {title && <Styled.Title variantColor={variant}>{title}</Styled.Title>}
      {details && (
        <Styled.Details variantColor={variant}>{details}</Styled.Details>
      )}
    </Styled.Content>
  );
};

export default NetworkState;
