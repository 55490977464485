import { Box, alpha, styled } from '@mui/material';
import { ReactComponent as Map } from '@userpath/userpath/assets/images/map.svg';

export const Styled = {
  MapContainer: styled(Box)(({ theme }) => ({
    position: 'relative',
    overflow: 'hidden',
    height: '800px',
    [theme.breakpoints.down('md')]: {
      height: '592px',
    },
  })),
  MapHeader: styled(Box)(({ theme }) => ({
    zIndex: -3,
    position: 'absolute',
    display: 'none',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `radial-gradient( 
        ${theme.palette.background.paper} 11.96%, 
        ${alpha(theme.palette.background.paper, 0.20609)} 49.37%,
        ${alpha(theme.palette.background.paper, 0.118073)} 55.58%,
        ${alpha(theme.palette.background.paper, 0)} 100%)`,
    [theme.breakpoints.down(1450)]: {
      display: 'block',
    },
    [theme.breakpoints.down('md')]: {
      background: `linear-gradient(180deg, 
            ${theme.palette.background.paper} 11.96%, 
            ${alpha(theme.palette.background.paper, 0.20609)} 49.37%,
            ${alpha(theme.palette.background.paper, 0.118073)} 55.58%,
            ${alpha(theme.palette.background.paper, 0)} 100%)`,
    },
  })),
  Located: styled('span')(({ theme }) => ({
    '@keyframes locatedSlide': generateSlideKeyframes(true),
    animation: `locatedSlide 3s`,
    position: 'absolute',
    right: '300px',
    bottom: `calc((800px * 0.505) + (${theme.typography.sizes.h2}px * ${theme.direction == 'ltr' ? '0.1' : '0.25'}))`,
    color: theme.palette.text.neutral20,
    ...theme.typography.h2,
    [theme.breakpoints.down('md')]: {
      right: '210px',
      left: '18px',
      bottom: `calc(492px + ${theme.direction == 'ltr' ? '0px' : '4px'} + (${theme.typography.sizes.h2}px * ${theme.direction == 'ltr' ? '0.1' : '0.25'}))`,
      textAlign: 'end',
    },
  })),
  Location: styled('span')(({ theme }) => ({
    '@keyframes locationSlide': generateSlideKeyframes(false),
    animation: `locationSlide 3s`,
    position: 'absolute',
    right: '300px',
    top: `calc(800px * 0.495 + ${theme.direction == 'ltr' ? '12px' : '6px'})`,
    color: theme.palette.text.neutral80,
    ...theme.typography.h4,
    [theme.breakpoints.down('md')]: {
      right: '210px',
      left: '18px',
      top: `calc(100px + ${theme.direction == 'ltr' ? '8px' : '4px'})`,
      textAlign: 'end',
    },
  })),
  Map: styled(Map)(({ theme }) => ({
    '--height': '800px',
    '--width': 'calc(var(--height) * 1.7482517483)',
    '--centerX': 'calc(var(--width) * 0.611645)',
    '--centerY': 0.4977534965,
    zIndex: -4,
    position: 'absolute',
    height: 'var(--height)',
    width: 'var(--width)',
    top: '0px',
    left: 'calc(25vw - var(--centerX))',
    [theme.breakpoints.down('md')]: {
      '--height': '500px',
      top: '92px',
      left: 'calc(50vw - var(--centerX))',
    },
  })),
};

const generateSlideKeyframes = (downToUp: boolean) => {
  return {
    '0%, 55%': {
      clipPath: downToUp ? 'inset(100% 0 0 0)' : 'inset(0 0 100% 0)',
    },
    '90%': {
      clipPath: 'inset(0)',
    },
    '91%': {
      clipPath: 'unset',
    },
  };
};
