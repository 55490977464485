import React, {
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Styled } from './styles';

interface IScrollableListProps {
  children?: ReactNode | undefined;
}

const ScrollableList = forwardRef<HTMLDivElement, IScrollableListProps>(
  ({ children }, ref) => {
    const containerRef = useRef<HTMLDivElement>();
    const [maxHeight, setMaxHeight] = useState(100);
    useEffect(() => {
      if (!containerRef.current) return;
      if (typeof ref === 'function') {
        ref(containerRef.current);
      } else if (ref) {
        ref.current = containerRef.current;
      }
    }, [containerRef, ref]);

    useEffect(() => {
      const containerElement = containerRef.current;
      if (!containerElement) return;
      const handleResize = () => {
        const itemElements = containerElement.querySelectorAll('div');
        let maxItemHeight = 0;
        itemElements.forEach((element) => {
          element.style.setProperty('height', 'unset');
        });
        itemElements.forEach((item) => {
          const itemHeight = item.offsetHeight;
          if (itemHeight > maxItemHeight) {
            maxItemHeight = itemHeight;
          }
        });
        itemElements.forEach((element) => {
          element.style.removeProperty('height');
        });
        setMaxHeight(maxItemHeight);
      };

      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(containerElement);
      handleResize();
      return () => {
        resizeObserver.disconnect();
      };
    }, [children]);

    return (
      <Styled.ItemsContainer itemHeight={maxHeight}>
        <Styled.ItemsList itemHeight={maxHeight} ref={containerRef}>
          {children}
        </Styled.ItemsList>
      </Styled.ItemsContainer>
    );
  },
);

export default ScrollableList;
