import React, { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Breakpoint } from '@mui/material';
import { Lang } from '@userpath/localization';
import { ThemeConfigProvider } from '@userpath/theme';
import { createI82n } from '@userpath/localization/src/LocaleProvider';

interface IConfigOverriderProps {
  lang?: Lang;
  forceBreakpoint?: Breakpoint;
  breakpoints?: {
    values: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
    };
  };
}

const ConfigOverrider: React.FC<
  React.PropsWithChildren<IConfigOverriderProps>
> = ({ children, lang, forceBreakpoint }) => {
  const i82n = useMemo(
    () =>
      createI82n({
        initImmediate: true,
        lng: lang,
        detection: {
          caches: [],
          order: [],
          lookupLocalStorage: 'i82nPreviewLng',
        },
      }),
    [lang],
  );

  if (lang) {
    return (
      <I18nextProvider i18n={i82n}>
        <ThemeConfigProvider forceBreakpoint={forceBreakpoint}>
          {children}
        </ThemeConfigProvider>
      </I18nextProvider>
    );
  } else if (forceBreakpoint != undefined) {
    return (
      <ThemeConfigProvider forceBreakpoint={forceBreakpoint}>
        {children}
      </ThemeConfigProvider>
    );
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default ConfigOverrider;
