import { Box, Typography, alpha, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.palette.text.neutral,
    ...(theme.direction == 'ltr' && {
      lineHeight: 1,
    }),
  })),
  Details: styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.neutral10,
    marginTop: '34px',
    [theme.breakpoints.down('md')]: {
      marginTop: '16px',
    },
  })),
  PointsContainer: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemHeight']),
  })<{ itemHeight: number }>(({ theme, itemHeight }) => ({
    '--list-padding': '32px',
    overflow: 'hidden',
    height: itemHeight,
    marginBottom: '56px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '34px',
      '--list-padding': '24px',
    },
  })),
  PointsList: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemHeight', 'page']),
  })<{ itemHeight: number; page: number }>(({ theme, itemHeight, page }) => ({
    position: 'relative',
    width: 'calc(100% + var(--list-padding, 0px))',
    height: `${itemHeight}px`,
    transition: 'transform 2000ms',
    transform: `translateX(calc(${page} * 100% * ${theme.direction == 'ltr' ? '-1' : '1'}))`,
  })),
  Point: styled(Box, {
    shouldForwardProp: shouldForwardProp(['index']),
  })<{ index: number }>(({ theme, index }) => ({
    ...theme.typography.h8,
    color: theme.palette.text.neutral80,
    position: 'absolute',
    top: 0,
    width: '100%',
    ...theme.directional({
      left: `calc(100% * ${index})`,
      paddingRight: 'var(--list-padding, 0px)',
    }),
  })),
  VideoContainer: styled(Box, {
    shouldForwardProp: shouldForwardProp(['path']),
  })<{ path: string }>(({ theme, path }) => ({
    position: 'relative',
    width: '592px',
    height: 'calc(592px * 1.06)',
    [theme.breakpoints.down('lg')]: {
      width: '372px',
      height: 'calc(372px * 1.06)',
    },
    [theme.breakpoints.down('md')]: {
      width: 'min(286px, calc(100vw - 48px))',
      height: 'calc(min(286px, calc(100vw - 48px)) * 1.06)',
    },
    '&::before': {
      zIndex: -3,
      content: '""',
      clipPath: `path("${path}")`,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: alpha(
        theme.palette.primary.main130 ?? theme.palette.primary.main,
        0.24,
      ),
    },
  })),
  Video: styled('video', {
    shouldForwardProp: shouldForwardProp(['path']),
  })<{ path: string }>(({ path }) => ({
    position: 'relative',
    zIndex: -4,
    clipPath: `path("${path}")`,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  })),
};
