import { useMemo } from 'react';
import { getStorage, FirebaseStorage } from 'firebase/storage';
import { useFirebaseApp } from '@userpath/utils';

export const useStorage = (): FirebaseStorage => {
  const app = useFirebaseApp();
  const storage = useMemo(() => getStorage(app), [app]);

  return storage;
};
