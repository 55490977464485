import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeConfigProvider } from '@userpath/theme';
import { LocaleProvider } from '@userpath/localization';
import {
  FirebaseProvider,
  GlobalContextProvider,
  ToastContextProvider,
} from '@userpath/utils';
import { Loader } from '@userpath/components';
import { createRouter } from '../../routes';

const Root: React.FC = () => {
  return (
    <LocaleProvider>
      <GlobalContextProvider>
        <ThemeConfigProvider>
          <ToastContextProvider>
            <FirebaseProvider>
              <RouterProvider router={createRouter()} />
            </FirebaseProvider>
          </ToastContextProvider>
        </ThemeConfigProvider>
        <div className="app-loader">
          <Loader />
        </div>
      </GlobalContextProvider>
    </LocaleProvider>
  );
};

export default Root;
