import { useMemo } from 'react';
import { Firestore, getFirestore } from 'firebase/firestore/lite';
import { useFirebaseApp } from '@userpath/utils';

export const useFirestore = (): Firestore => {
  const app = useFirebaseApp();
  const firestore = useMemo(() => getFirestore(app), [app]);

  return firestore;
};
