import { Typography, TypographyProps, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

type GradientTypographyVariant =
  | 'primary'
  | 'variant1'
  | 'variant2'
  | 'variant3';

interface IGradientTypographyProps {
  gradient?: GradientTypographyVariant;
  animated?: boolean;
}
type GradientTypographyType = React.ComponentType<
  TypographyProps<'span', { component?: 'span' }> & IGradientTypographyProps
>;

export const GradientTypography: GradientTypographyType = styled(Typography, {
  shouldForwardProp: shouldForwardProp(['gradient', 'animated']),
})<IGradientTypographyProps>(({ gradient = 'primary', theme, animated }) => ({
  '@keyframes animated-gradient': {
    to: {
      backgroundPosition: 'var(--bg-size) 0',
    },
  },
  '--gss': theme.palette.secondary.secondary,
  '--gsm': theme.palette.secondary.main,
  '--gps': theme.palette.primary.secondary,
  '--gpm': theme.palette.primary.main,
  ...(gradient == 'primary' && {
    ...(animated && {
      '--bg-size': '200%',
      animation: 'animated-gradient 8s infinite linear',
      background:
        'linear-gradient(87.15deg, var(--gss) 0.0%, var(--gsm) 20.5%, var(--gps) 32.0%, var(--gpm) 50.0%, var(--gps) 68.0%, var(--gsm) 79.5%, var(--gss) 100.0%) 0 0 / var(--bg-size) 100%',
    }),
    ...(!animated && {
      background:
        'linear-gradient(87.15deg, var(--gss) -19.12%, var(--gsm) 41.21%, var(--gps) 75.05%, var(--gpm) 128.02%)',
    }),
  }),
  ...(gradient == 'variant1' && {
    background:
      'linear-gradient(88.68deg, var(--gss) 1%, var(--gsm) 16.26%, var(--gps) 24.79%, var(--gpm) 38.13%)',
  }),
  ...(gradient == 'variant2' && {
    background:
      'linear-gradient(90deg, var(--gss) 0%, var(--gsm) 27.63%, var(--gps) 43.17%, var(--gpm) 67.48%)',
  }),
  ...(gradient == 'variant3' && {
    background:
      'linear-gradient(90deg, var(--gss) 0%, var(--gsm) 40.99%, var(--gps) 64.02%, var(--gpm) 100%)',
  }),
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));
