import React from 'react';

interface IAnimateProps {
  name: string;
  from: string;
  to: string;
}

const paperPath1 =
  'M42.7105 95.2223V25.8574C42.7105 25.0677 43.0251 24.3137 43.5803 23.7585C44.1384 23.2004 44.8925 22.8887 45.6792 22.8887H112.636L146.617 60.5681V107.929C146.617 107.929 146.629 111.536 146.329 117.081L129.612 111.016C127.614 110.292 125.378 110.689 123.754 112.064C121.172 114.246 117.262 117.553 114.753 119.673C113.239 120.952 102.903 116.914 101 116.371C95.2288 114.72 91.5523 115.781 91.5523 115.781C91.5523 115.781 103.344 115.14 109.305 119.4C111.467 120.944 113.723 120.147 115.799 118.488C118.465 116.353 122.078 113.465 124.5 111.526C126.136 110.22 129.003 110.586 130.962 111.319L146.329 117.081C145.15 129.888 141.816 155.298 135.614 165.724C135.071 166.582 134.127 167.102 133.112 167.102C121.75 167.111 54.2975 167.111 35.443 167.111C34.4009 167.111 33.4361 166.564 32.8987 165.671C32.3644 164.777 32.3347 163.667 32.8245 162.749C42.0366 142.746 42.7105 107.929 42.7105 107.929V94.9996L55.7373 90.116C57.6967 89.3827 59.8965 89.7331 61.5294 91.0393C63.9548 92.9779 67.5678 95.8665 70.2337 98.001C72.3089 99.6606 76.3388 101.121 78.5 99.5771C85.6056 98.3801 94.5211 94.9996 94.5211 94.9996C94.5211 94.9996 82.557 98.4167 76.7858 100.067C74.8828 100.611 72.8314 100.171 71.3203 98.8917C68.8117 96.772 64.9019 93.4648 62.319 91.2827C60.6951 89.9082 58.4597 89.5104 56.4617 90.2348L42.7105 95.2223Z';
const paperPath2 =
  'M42.7105 95.2223V25.8574C42.7105 25.0677 43.0251 24.3137 43.5803 23.7585C44.1384 23.2004 44.8925 22.8887 45.6792 22.8887H112.636L146.617 60.5681V107.929C146.617 107.929 146.629 111.536 146.329 117.081L129.612 111.016C127.614 110.292 125.378 110.689 123.754 112.064C121.172 114.246 117.262 117.553 114.753 119.673C113.239 120.952 111.191 121.392 109.288 120.849C103.516 119.198 91.5523 115.781 91.5523 115.781C91.5523 115.781 102.718 123.755 108.679 128.015C110.84 129.559 113.765 129.479 115.84 127.819C118.506 125.685 122.119 122.796 124.541 120.857C126.177 119.551 128.377 119.201 130.336 119.934L145.702 125.697C144.524 138.504 141.816 155.298 135.614 165.724C135.071 166.582 134.127 167.102 133.112 167.102C121.75 167.111 54.2975 167.111 35.443 167.111C34.4009 167.111 33.4361 166.564 32.8987 165.671C32.3644 164.777 32.3347 163.667 32.8245 162.749C42.0366 142.746 42.7105 107.929 42.7105 107.929V104.036L55.7373 99.1529C57.6967 98.4196 59.8965 98.7699 61.5294 100.076C63.9548 102.015 67.5678 104.903 70.2337 107.038C72.3089 108.697 75.2331 108.778 77.3944 107.234C83.3556 102.974 94.5211 94.9996 94.5211 94.9996C94.5211 94.9996 82.557 98.4167 76.7858 100.067C74.8828 100.611 72.8314 100.171 71.3203 98.8917C68.8117 96.772 64.9019 93.4648 62.319 91.2827C60.6951 89.9082 58.4597 89.5104 56.4617 90.2348L42.7105 95.2223Z';
const leftPath1 =
  'M12.3559 73.2331L31.2042 86.224C32.5542 87.1562 34.4021 86.8147 35.3314 85.4654C36.2607 84.1161 35.9221 82.2675 34.5728 81.3382L15.7245 68.3472C14.3745 67.4151 12.5266 67.7565 11.5944 69.1066C10.6651 70.4559 11.0066 72.3038 12.3559 73.2331ZM19.2161 98.2143L30.1239 98.2145C31.7623 98.215 33.093 96.8841 33.0935 95.2458C33.094 93.6075 31.7632 92.2768 30.1249 92.2763L19.2171 92.2761C17.5759 92.2764 16.248 93.6064 16.2475 95.2447C16.247 96.8831 17.5778 98.2138 19.2161 98.2143ZM40.3729 105.973L24.1859 122.162C23.0273 123.32 21.1463 123.32 19.9876 122.162C18.8289 121.003 18.8289 119.122 19.9875 117.963L36.1745 101.775C37.3331 100.616 39.2141 100.616 40.3728 101.775C41.5316 102.933 41.5315 104.814 40.3729 105.973Z';
const leftPath2 =
  'M18.0414 70.5912L32.8852 88.0177C33.948 89.2676 35.8213 89.416 37.0682 88.3532C38.315 87.2904 38.4664 85.4171 37.4036 84.1702L22.5599 66.7437C21.4971 65.4938 19.6238 65.3454 18.3739 66.4082C17.1271 67.471 16.9786 69.3443 18.0414 70.5912ZM18.2023 96.4968L28.7384 99.32C30.3208 99.7446 31.9506 98.8035 32.3751 97.2211C32.7997 95.6388 31.8586 94.0089 30.2762 93.5844L19.7401 90.7611C18.1548 90.3366 16.528 91.2777 16.1034 92.86C15.6789 94.4424 16.62 96.0722 18.2023 96.4968ZM36.6301 109.467L16.8047 120.914C15.3857 121.734 13.5688 121.247 12.7494 119.828C11.9301 118.409 12.4169 116.592 13.836 115.773L33.6613 104.325C35.0804 103.506 36.8972 103.993 37.7166 105.412C38.536 106.831 38.0491 108.648 36.6301 109.467Z';
const rightPath1 =
  'M157.888 110.761L180 104.837C181.583 104.413 183.212 105.354 183.636 106.936C184.06 108.519 183.12 110.148 181.537 110.572L159.425 116.496C157.842 116.92 156.213 115.979 155.789 114.396C155.365 112.814 156.306 111.185 157.888 110.761ZM172.331 128.061L162.885 122.607C161.466 121.788 159.648 122.275 158.829 123.694C158.009 125.112 158.496 126.93 159.915 127.75L169.361 133.204C170.783 134.021 172.597 133.536 173.418 132.115C174.237 130.696 173.749 128.881 172.331 128.061ZM165.782 153.123L155.954 132.448C155.25 130.969 153.479 130.34 152 131.041C150.521 131.745 149.889 133.518 150.593 134.998L160.421 155.673C161.124 157.152 162.895 157.783 164.377 157.08C165.856 156.376 166.485 154.602 165.782 153.123Z';
const rightPath2 =
  'M153.367 113.167L173.193 101.722C174.612 100.903 176.429 101.39 177.248 102.809C178.067 104.228 177.58 106.045 176.161 106.864L156.336 118.309C154.917 119.128 153.1 118.641 152.281 117.222C151.461 115.803 151.948 113.986 153.367 113.167ZM171.795 126.14L161.259 123.317C159.677 122.892 158.047 123.833 157.623 125.416C157.198 126.998 158.139 128.628 159.722 129.052L170.258 131.876C171.843 132.297 173.47 131.359 173.894 129.774C174.319 128.191 173.378 126.564 171.795 126.14ZM171.956 152.042L157.112 134.616C156.049 133.369 154.176 133.221 152.929 134.28C151.682 135.343 151.531 137.219 152.593 138.466L167.437 155.893C168.5 157.14 170.373 157.291 171.623 156.228C172.87 155.166 173.018 153.289 171.956 152.042Z';

const ErrorSVG: React.FC = () => {
  return (
    <svg
      width="190"
      height="190"
      viewBox="0 0 190 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={paperPath1} fill="#F3F3F7">
        <Animate name="d" from={paperPath1} to={paperPath2} />
      </path>
      <path
        d="M144.218 125.141L145.705 125.734C144.527 138.542 141.819 155.351 135.615 165.783C135.071 166.641 134.127 167.16 133.112 167.16C121.75 167.169 54.2975 167.169 35.443 167.169C34.4009 167.169 33.4361 166.62 32.8987 165.726C32.3644 164.836 32.3347 163.725 32.8245 162.805L32.9403 162.55C69.4975 164.269 122.585 157.865 129.576 155.128C134.771 153.094 140.911 138.904 144.218 125.141Z"
        fill="#E9E9EA"
      />
      <path
        d="M146.617 60.5681C137.607 57.0085 128.062 56.9076 118.268 58.4217C117.36 58.5642 116.437 58.2762 115.769 57.6409C115.104 57.0056 114.768 56.0971 114.866 55.1827C115.941 44.2162 114.857 33.4871 112.637 22.8887C127 33.5495 138.343 46.1013 146.617 60.5681Z"
        fill="url(#error_svg_a)"
      />
      <path d={leftPath1} fill="url(#error_svg_b)">
        <Animate name="d" from={leftPath1} to={leftPath2} />
      </path>
      <path d={rightPath1} fill="url(#error_svg_c)">
        <Animate name="d" from={rightPath1} to={rightPath2} />
      </path>
      <path
        d="M62.1187 53.3204L58.9699 50.1716C57.8121 49.0138 57.8121 47.1316 58.9699 45.9738C60.1277 44.816 62.0099 44.816 63.1677 45.9738L66.3176 49.1228L69.4674 45.9738C70.6252 44.816 72.5074 44.816 73.6652 45.9738C74.823 47.1316 74.823 49.0138 73.6652 50.1716L70.5164 53.3204L73.6652 56.4684C74.823 57.6291 74.823 59.5084 73.6652 60.6691C72.5074 61.827 70.6252 61.827 69.4674 60.6691L66.3176 57.5193L63.1677 60.6691C62.0099 61.827 60.1277 61.827 58.9699 60.6691C57.8121 59.5084 57.8121 57.6291 58.9699 56.4684L62.1187 53.3204Z"
        fill="#E9E9EA"
      />
      <path
        d="M94.775 53.3204L91.6262 50.1716C90.4684 49.0138 90.4684 47.1316 91.6262 45.9738C92.784 44.816 94.6662 44.816 95.824 45.9738L98.9738 49.1228L102.124 45.9738C103.281 44.816 105.164 44.816 106.321 45.9738C107.479 47.1316 107.479 49.0138 106.321 50.1716L103.173 53.3204L106.321 56.4684C107.479 57.6291 107.479 59.5084 106.321 60.6691C105.164 61.827 103.281 61.827 102.124 60.6691L98.9738 57.5193L95.824 60.6691C94.6662 61.827 92.784 61.827 91.6262 60.6691C90.4684 59.5084 90.4684 57.6291 91.6262 56.4684L94.775 53.3204Z"
        fill="#E9E9EA"
      />
      <path
        d="M66.6136 78.4007L70.7788 75.2805L74.9261 78.3977C75.983 79.1904 77.4347 79.1904 78.4916 78.3977L82.6478 75.2805L86.8041 78.3977C87.858 79.1904 89.3097 79.1904 90.3666 78.3977L94.5228 75.2805L98.6791 78.3977C99.9883 79.3804 101.853 79.1161 102.835 77.8039C103.818 76.4947 103.551 74.6333 102.242 73.6477L96.307 69.1946C95.2502 68.4049 93.7985 68.4049 92.7445 69.1946L88.5853 72.3118L84.4291 69.1946C83.3752 68.4049 81.9235 68.4049 80.8666 69.1946L76.7103 72.3118L72.566 69.1975C71.5121 68.4049 70.0603 68.4019 69.0035 69.1946L63.0571 73.6477C61.7449 74.6304 61.4777 76.4918 62.4574 77.8039C63.44 79.1132 65.3014 79.3833 66.6136 78.4007Z"
        fill="#E9E9EA"
      />
      <defs>
        <linearGradient
          id="error_svg_a"
          x1="112.642"
          y1="67.518"
          x2="164.892"
          y2="27.4823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset="0.41" stopColor="#E5774D" />
          <stop offset="0.64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
        </linearGradient>
        <linearGradient
          id="error_svg_b"
          x1="6.95889"
          y1="108.38"
          x2="76.9445"
          y2="75.9288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset="0.41" stopColor="#E5774D" />
          <stop offset="0.64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
          <Animate name="x1" from="6.95889" to="3.73159" />
          <Animate name="y1" from="108.38" to="103.144" />
          <Animate name="x2" from="76.9445" to="79.7316" />
          <Animate name="y2" from="75.9288" to="89.9118" />
        </linearGradient>
        <linearGradient
          id="error_svg_c"
          x1="134.756"
          y1="113.154"
          x2="192.384"
          y2="117.004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset="0.41" stopColor="#E5774D" />
          <stop offset="0.64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
          <Animate name="x1" from="134.756" to="131.642" />
          <Animate name="y1" from="113.154" to="121.465" />
          <Animate name="x2" from="192.384" to="188.303" />
          <Animate name="y2" from="117.004" to="110.269" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const Animate: React.FC<IAnimateProps> = ({ name, from, to }) => {
  return (
    <animate
      fill="freeze"
      attributeName={name}
      dur="3s"
      repeatCount="indefinite"
      values={`${from};${to};${from};`}
    />
  );
};

export default ErrorSVG;
