export interface Interval {
  start: number;
  end: number;
}

export interface Point {
  x: number;
  y: number;
  radius?: number;
}

export interface CurvePoint extends Point {
  cp1x: number;
  cp1y: number;
  cp2x: number;
  cp2y: number;
}

export const drawAnimatedLine = (
  ctx: CanvasRenderingContext2D,
  points: Point[],
  intervals: Interval[],
  t: number,
) => {
  if (points.length != intervals.length) return;
  let lastPoint = points[0];
  ctx.beginPath();
  ctx.moveTo(points[0].x, points[0].y);
  for (let i = 1; i < points.length; i++) {
    const point: Point = points[i];
    const interval = intervals[i];
    if (interval.end <= t) {
      // animation done
      if (instanceOfCurvePoint(point)) {
        ctx.quadraticCurveTo(point.cp2x, point.cp2y, point.x, point.y);
        lastPoint = { x: point.x, y: point.y };
      } else {
        ctx.lineTo(point.x, point.y);
        lastPoint = point;
      }
    } else {
      const currentT = (t - interval.start) / (interval.end - interval.start);
      if (instanceOfCurvePoint(point)) {
        const Q0 = {
          x: lastPoint.x + currentT * (point.cp2x - lastPoint.x),
          y: lastPoint.y + currentT * (point.cp2y - lastPoint.y),
        };
        const Q1 = {
          x: point.cp2x + currentT * (point.x - point.cp2x),
          y: point.cp2y + currentT * (point.y - point.cp2y),
        };
        const B = {
          x: Q0.x + currentT * (Q1.x - Q0.x),
          y: Q0.y + currentT * (Q1.y - Q0.y),
        };
        ctx.quadraticCurveTo(Q0.x, Q0.y, B.x, B.y);
        // ctx.quadraticCurveTo(point.cp2x, point.cp2y, point.x, point.y);
      } else {
        ctx.lineTo(
          lerp(lastPoint.x, point.x, currentT),
          lerp(lastPoint.y, point.y, currentT),
        );
      }
      break;
    }
  }
  ctx.stroke();
};

export const drawLine = (ctx: CanvasRenderingContext2D, points: Point[]) => {
  if (points.length < 2) return false;
  ctx.beginPath();
  ctx.moveTo(points[0].x, points[0].y);
  for (let i = 1; i < points.length; i++) {
    const point: Point = points[i];
    if (instanceOfCurvePoint(point)) {
      ctx.quadraticCurveTo(point.cp2x, point.cp2y, point.x, point.y);
    } else {
      ctx.lineTo(point.x, point.y);
    }
  }
  ctx.stroke();
};

export const flipPoints = (points: Point[], width: number): Point[] => {
  return points.map<Point>((p) => {
    if (instanceOfCurvePoint(p)) {
      return <CurvePoint>{
        ...p,
        x: width - p.x,
        cp1x: width - p.cp1x,
        cp2x: width - p.cp2x,
      };
    } else {
      return { ...p, x: width - p.x };
    }
  });
};

export const pointsToPath = (points: Point[]): string => {
  points = addControlPoints(points);
  let path = `M ${points[0].x} ${points[0].y} `;
  for (let i = 1; i < points.length; i++) {
    const p = points[i];
    if (instanceOfCurvePoint(p)) {
      path += `Q ${p.cp2x} ${p.cp2y} ${p.x} ${p.y} `;
    } else {
      path += `L ${p.x} ${p.y} `;
    }
  }
  path += 'Z';
  return path;
};

export const addControlPoints = (points: Point[]): Point[] => {
  points = adjustRadii(points);
  const newPoints: Point[] = [];
  newPoints.push(points[0]);
  for (let i = 1; i < points.length - 1; i++) {
    const point = points[i];
    if (point.radius) {
      const nextPoint = points[i + 1];
      const prevPoint = points[i - 1];
      const curvePoint: CurvePoint = {
        cp1x:
          prevPoint.x < point.x
            ? Math.max(prevPoint.x, point.x - point.radius)
            : Math.min(prevPoint.x, point.x + point.radius),
        cp1y:
          prevPoint.y < point.y
            ? Math.max(prevPoint.y, point.y - point.radius)
            : Math.min(prevPoint.y, point.y + point.radius),
        cp2x: point.x,
        cp2y: point.y,
        x:
          nextPoint.x > point.x
            ? Math.min(point.x + point.radius, nextPoint.x)
            : Math.max(point.x - point.radius, nextPoint.x),
        y:
          nextPoint.y > point.y
            ? Math.min(point.y + point.radius, nextPoint.y)
            : Math.max(point.y - point.radius, nextPoint.y),
      };
      newPoints.push({ x: curvePoint.cp1x, y: curvePoint.cp1y });
      newPoints.push(curvePoint);
      // newPoints.push({ x: curvePoint.x, y: curvePoint.y });
    } else {
      newPoints.push(point);
    }
  }
  newPoints.push(points[points.length - 1]);
  return newPoints;
};

export const computeLineIntervals = (points: Point[]) => {
  if (points.length < 2) return [];
  const computed: number[] = [0];
  let lastPoint = points[0];
  for (let i = 1; i < points.length; i++) {
    const point: Point = points[i];
    if (instanceOfCurvePoint(point)) {
      const l = bezierLength(point);
      computed.push(l);
      lastPoint = { x: point.x, y: point.y };
    } else {
      const l = calculateDistance(lastPoint, point);
      computed.push(l);
      lastPoint = point;
    }
  }
  return computeIntervals(computed);
};

export const bezierLength = (point: CurvePoint, steps = 100) => {
  let length = 0.0;
  let previousDot = { x: point.cp1x, y: point.cp1y };

  for (let i = 0; i <= steps; i++) {
    const t = i / steps;
    const dot = {
      x: bezierPoint(t, point.cp1x, point.cp1x, point.cp2x, point.x),
      y: bezierPoint(t, point.cp1y, point.cp1y, point.cp2y, point.y),
    };

    if (i > 0) {
      const xDiff = dot.x - previousDot.x;
      const yDiff = dot.y - previousDot.y;
      length += Math.sqrt(xDiff * xDiff + yDiff * yDiff);
    }

    previousDot = dot;
  }

  return length;
};

const adjustRadii = (points: Point[]): Point[] => {
  const adjustedPoints: Point[] = [];

  for (let i = 0; i < points.length; i++) {
    const currentPoint = points[i];
    const nextPoint = points[i + 1];
    const prevPoint = points[i - 1];

    let adjustedRadius: number | undefined;

    if (currentPoint.radius !== undefined) {
      let prevDistance = Infinity;
      let nextDistance = Infinity;

      if (prevPoint) {
        const distance = calculateDistance(prevPoint, currentPoint);
        prevDistance = prevPoint.radius
          ? distance - prevPoint.radius
          : distance;
      }
      if (nextPoint) {
        const distance = calculateDistance(currentPoint, nextPoint);
        nextDistance = nextPoint.radius
          ? distance - nextPoint.radius
          : distance;
      }

      if (
        prevDistance >= currentPoint.radius &&
        nextDistance >= currentPoint.radius
      ) {
        adjustedRadius = currentPoint.radius;
      } else if (nextDistance < currentPoint.radius) {
        const nextShare =
          nextPoint && nextPoint.radius
            ? nextPoint.radius / (nextPoint.radius + currentPoint.radius)
            : 0;
        if (nextShare != 0 && nextPoint.radius) {
          adjustedRadius = (1 - nextShare) * (nextDistance + nextPoint.radius);
        } else {
          adjustedRadius = nextDistance;
        }
      } else if (prevDistance < currentPoint.radius) {
        const prevShare =
          prevPoint && prevPoint.radius
            ? prevPoint.radius / (prevPoint.radius + currentPoint.radius)
            : 0;
        if (prevShare != 0 && prevPoint.radius) {
          adjustedRadius = (1 - prevShare) * (prevDistance + prevPoint.radius);
        } else {
          adjustedRadius = prevDistance;
        }
      }
    }

    adjustedPoints.push({ ...currentPoint, radius: adjustedRadius });
  }

  return adjustedPoints;
};

const calculateDistance = (point1: Point, point2: Point): number => {
  const dx = point1.x - point2.x;
  const dy = point1.y - point2.y;
  return Math.sqrt(dx * dx + dy * dy);
};

const computeIntervals = (lengths: number[]): Interval[] => {
  const totalLength = lengths.reduce((partialSum, a) => partialSum + a, 0);
  const intervals: Interval[] = [];
  let start = 0;
  for (let i = 0; i < lengths.length; i++) {
    const end =
      i == lengths.length - 1 ? 1.0 : start + lengths[i] / totalLength;
    intervals.push({ start: start, end: end });
    start = end;
  }
  return intervals;
};

const bezierPoint = (
  t: number,
  start: number,
  control1: number,
  control2: number,
  end: number,
) => {
  // Formula from Wikipedia article on Bezier curves.
  return (
    start * (1.0 - t) * (1.0 - t) * (1.0 - t) +
    3.0 * control1 * (1.0 - t) * (1.0 - t) * t +
    3.0 * control2 * (1.0 - t) * t * t +
    end * t * t * t
  );
};

const instanceOfCurvePoint = (object: Point): object is CurvePoint => {
  return (
    'cp1x' in object && 'cp1y' in object && 'cp2x' in object && 'cp2y' in object
  );
};

const lerp = (v0: number, v1: number, t: number) => {
  return (1.0 - t) * v0 + t * v1;
};
