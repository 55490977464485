import { AppBar, Box, Button, Drawer, Fab, alpha, styled } from '@mui/material';
import { ReactComponent as LogoWhite } from '@userpath/components/assets/svg/logo_white.svg';
import { shouldForwardProp } from '@userpath/utils';
import { CustomContainer } from '../custom-container';
import { AppButton } from '../styled-components';

/*
  The curve was made using this 
  https://css-tricks.com/between-the-lines/
  To generate a css from sass
  */

interface IAppBarButtonProps {
  isActive?: boolean;
}

export const Styled = {
  ClipContainer: styled(Box, {
    shouldForwardProp: shouldForwardProp(['path']),
  })<{ path: string }>(({ path }) => ({
    clipPath: `path("${path}")`,
  })),
  Logo: styled(LogoWhite)(({ theme }) => ({
    height: '88px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '44px',
    },
  })),
  Curve: styled('svg')<React.SVGProps<SVGSVGElement>>({
    width: '100%',
    height: 'calc(27.4371257485px + 2.6946107784vw)',
    position: 'absolute',
    bottom: '-1px',
  }),
  CurvePath: styled('path')(({ theme }) => ({
    fill: `${theme.palette.background.default}`,
  })),
  AppBar: styled(AppBar)(({ theme }) => ({
    background: theme.palette.background.primary,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'static',
  })),
  AppBarContent: styled(CustomContainer)(({ theme }) => ({
    display: 'flex',
    gap: '48px',
    alignItems: 'end',
    paddingTop: '24px !important',
    paddingBottom: '40px !important',
    [theme.breakpoints.down('md')]: {
      paddingTop: '48px !important',
    },
    [theme.breakpoints.down('lg')]: {
      gap: '24px',
    },
  })),
  AppBarButton: styled(Button, {
    shouldForwardProp: shouldForwardProp(['isActive']),
  })<IAppBarButtonProps>(({ isActive, theme }) => ({
    display: 'block',
    position: 'relative',
    fontSize: theme.typography.sizes.h8,
    fontWeight: isActive ? 'bold' : 'normal',
    fontFamily: theme.typography.fontFamily,
    color: isActive ? theme.palette.text.neutral : theme.palette.text.neutral20,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '0px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: isActive ? 'min(100%, 32px)' : '0px',
      height: '1px',
      backgroundColor: theme.palette.text.neutral,
      transition: theme.transitions.create('width'),
    },
    '&:hover::after': {
      width: 'min(100%, 32px)',
    },
  })),
  Drawer: styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '100%',
      overflow: 'auto',
      height: '100%',
      scrollbarColor: `${theme.palette.text.neutral}  #0000`,
    },
  })),
  Container: styled('div')({
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: 'calc(27.4371257485px + 2.6946107784vw)',
    minHeight: 'calc(27.4371257485px + 2.6946107784vw)',
  }),
  LayerEffect: styled('div')(({ theme }) => ({
    position: 'absolute',
    overflow: 'hidden',
    background: theme.palette.background.primary,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: -1000,
  })),
  Circle: styled('div')(({ theme }) => ({
    '--size': '200px',
    position: 'absolute',
    top: 'calc(348px - var(--size))',
    left: '0px',
    zIndex: -999,
    width: 'var(--size)',
    height: 'var(--size)',
    borderRadius: '50%',
    background: theme.palette.primary.secondary, // Set circle background color
    filter: 'blur(calc(var(--size) * 0.7))', // Adjust blur strength as needed
    // backdropFilter: 'blur(300px)',
  })),
  TransitionContent: styled(Box)(({ theme }) => ({
    transition: 'max-height 300ms',
    '--height': '734px',
    [theme.breakpoints.down('md')]: {
      '--height': '650px',
    },
    '&.fade-enter': {
      maxHeight: '0px',
    },
    '&.fade-enter-active': {
      maxHeight: 'var(--height)',
    },
    '&.fade-exit': {
      maxHeight: 'var(--height)',
    },
    '&.fade-exit-active': {
      maxHeight: '0px',
    },
  })),
  ContactButton: styled(AppButton)(({ theme }) => ({
    '@keyframes pulse': generatePulseKeyframes(
      theme.palette.secondary.main130 ?? '#fff',
    ),
    animation: 'pulse 5s ease-in-out infinite',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  })),
  ContactText: styled('span')({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  ContactFAB: styled(Fab)(({ theme }) => ({
    position: 'fixed',
    margin: 0,
    top: 'auto',
    bottom: '24px',
    ...theme.directional({
      right: '16px',
      left: 'auto',
    }),
    '@keyframes pulse': generatePulseKeyframes(
      theme.palette.secondary.main130 ?? '#fff',
    ),
    animation: 'pulse 5s ease-in-out infinite',
    background: `linear-gradient(52.58deg,
    ${theme.palette.secondary.secondary} -116.59%,
    ${theme.palette.secondary.main} 34.66%,
    ${theme.palette.primary.secondary} 119.51%,
    ${theme.palette.primary.main} 252.32%)`,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  })),
};

const generatePulseKeyframes = (color: string) => {
  const color1 = alpha(color, 0.12);
  const color2 = alpha(color, 0.2);
  return {
    '0%, 40%': {
      boxShadow: `0 0 0 0px ${color1}, 0 0 0 0px ${color2}`,
    },
    '55%': {
      boxShadow: `0 0 0 10px ${color1}, 0 0 0 0px ${color2}`,
    },
    '75%, 85%': {
      boxShadow: `0 0 0 10px ${color1}, 0 0 0 5px ${color2}`,
    },
    '100%': {
      boxShadow: `0 0 0 0px ${color1}, 0 0 0 px ${color2}`,
    },
  };
};
