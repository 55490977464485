export const normalizedTimestamp = (
  start: number,
  end: number,
  timestamp: number,
) => {
  if (timestamp <= start) return 0;
  if (timestamp >= end) return 1;
  return (timestamp - start) / (end - start);
};

export const repeatingTimestamp = (
  timestamp: number,
  period: number,
  delay = 0,
) => {
  timestamp -= delay;
  if (timestamp <= 0) return 0;
  const phase = (timestamp % period) / period;
  return phase;
};

export const lerpGradient = (colors: string[], t: number): string[] => {
  const newColors: string[] = [];
  const ratio = 1 / colors.length;
  const step = (t % ratio) / ratio;
  const add = Math.floor(t / ratio);
  for (let i = 0; i < colors.length; i++) {
    const colorA = colors[(i + add) % colors.length];
    const colorB = colors[(i + add + 1) % colors.length];
    newColors.push(lerpColor(colorA, colorB, step));
  }
  return newColors;
};

/**
 * Linearly interpolate (lerp) between two colors.
 * @param colorA First color in hexadecimal format
 * @param colorB Second color in hexadecimal format.
 * @param t Normalized value between 0 and 1.
 * @returns Interpolated color in hexadecimal format.
 */
export const lerpColor = (
  colorA: string,
  colorB: string,
  t: number,
): string => {
  t = Math.max(0, Math.min(t, 1));
  // Convert hexadecimal colors to RGBA format
  const rgbaA = hexToRgba(colorA);
  const rgbaB = hexToRgba(colorB);

  // Interpolate each channel (red, green, blue, alpha)
  const interpolatedR = Math.round(lerp(rgbaA.r, rgbaB.r, t));
  const interpolatedG = Math.round(lerp(rgbaA.g, rgbaB.g, t));
  const interpolatedB = Math.round(lerp(rgbaA.b, rgbaB.b, t));
  const interpolatedA = lerp(rgbaA.a, rgbaB.a, t);

  // Convert back to hexadecimal format
  return rgbaToHex(interpolatedR, interpolatedG, interpolatedB, interpolatedA);
};

export const lerp = (value1: number, value2: number, t: number) => {
  return (1.0 - t) * value1 + t * value2;
};

const hexToRgba = (
  hexColor: string,
): {
  r: number;
  g: number;
  b: number;
  a: number;
} => {
  // Remove the "#" symbol if present
  hexColor = hexColor.replace(/^#/, '');

  // Extract red, green, blue, and alpha channels
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Extract alpha channel if available (default to 1.0 if not provided)
  const a =
    hexColor.length === 8
      ? parseInt(hexColor.substring(6, 8), 16) / 255.0
      : 1.0;

  return { r, g, b, a };
};

const rgbaToHex = (r: number, g: number, b: number, a: number): string => {
  const outParts = [
    r.toString(16),
    g.toString(16),
    b.toString(16),
    Math.round(a * 255)
      .toString(16)
      .substring(0, 2),
  ];

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = '0' + part;
    }
  });

  return '#' + outParts.join('');
};
