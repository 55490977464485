import React from 'react';
import { useTranslation } from 'react-i18next';
import { Control, useFieldArray, FieldPath } from 'react-hook-form';
import { Button, Grid, IconButton } from '@mui/material';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import { AppInputLabel, ColorInput, InputField } from '@userpath/components';
import { Lang } from '@userpath/localization';
import { Library } from '@userpath/constants';
import { IArticleInputs } from '../constants';

interface ITagsInputProps {
  control: Control<IArticleInputs>;
  index: number;
  disableFields?: boolean;
  getOtherValue: (key: FieldPath<IArticleInputs>) => string | undefined;
}

const TagsInput: React.FC<ITagsInputProps> = ({
  control,
  index,
  disableFields,
  getOtherValue,
}) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const isArabic = i18n.language == Lang.AR;
  const lang = index == 0 ? 'english' : 'arabic';
  const otherLang = index == 0 ? 'arabic' : 'english';
  const { fields, append, remove } = useFieldArray<IArticleInputs>({
    control: control,
    name: 'tags',
  });
  return (
    <div style={{ margin: '18px 0px' }}>
      <Grid container spacing="18px">
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <AppInputLabel variantColor="light_background">
            {t('admin.article.tags')}
          </AppInputLabel>
          <Button
            color="info"
            variant="contained"
            endIcon={<AddCircleOutline />}
            onClick={() =>
              append({
                title_arabic: '',
                title_english: '',
                color: '#1801A1',
              })
            }
            sx={{
              '& .MuiButton-endIcon': {
                marginRight: isArabic ? '8px' : '-4px',
                marginLeft: isArabic ? '-4px' : '8px',
              },
            }}
          >
            {t('admin.article.addTag')}
          </Button>
        </Grid>
        {fields.map((field, index) => (
          <Grid item container xs={12} spacing="18px" key={field.id}>
            <Grid item flex={1}>
              <InputField<IArticleInputs>
                control={control}
                variantColor="light_background"
                name={`tags.${index}.title_${lang}`}
                label={t('admin.article.tagName')}
                type="text"
                inputMode="text"
                autoComplete="name"
                readOnly={disableFields}
                fullWidth
                placeholder={
                  getOtherValue(`tags.${index}.title_${otherLang}`) ??
                  t('admin.article.input')
                }
              />
            </Grid>
            <Grid item flex={1}>
              <ColorInput<IArticleInputs>
                control={control}
                variantColor="light_background"
                name={`tags.${index}.color`}
                label={t('admin.article.tagColor')}
                disabled={disableFields}
                labelProps={{ optional: true }}
                placeholder={t('admin.article.input')}
                fallbackValue="#1801A1"
                fullWidth
                isAlphaHidden
              />
            </Grid>
            <Grid item>
              <IconButton
                color="error"
                onClick={() => remove(index)}
                sx={{ marginTop: '24px' }}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default TagsInput;
