import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Box, CircularProgress, Paper, Tab, Tabs, alpha } from '@mui/material';
import { Library } from '@userpath/constants';
import { Lang } from '@userpath/localization';
import {
  AppButton,
  CustomContainer,
  GradientTypography,
  toggleLoader,
  useArticleLoader,
  yupResolver,
} from '@userpath/components';
import { useToastContext } from '@userpath/utils';
import { AppError } from '@userpath/types';
import { NotFoundPage } from '../../../not-found';
import { ArticleInputs } from './components';
import {
  validationSchema,
  defaultValues,
  IArticleInputs,
  fromServer,
} from './constants';
import { Styled } from './styles';
import { useArticleSaver } from './useArticleSaver';

const AdminArticlePage: React.FC = () => {
  const { t, i18n } = useTranslation(Library.userpath);
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToastContext();
  const [language, setLanguage] = useState(i18n.language == Lang.AR ? 1 : 0);
  const resolver = yupResolver(validationSchema);
  const [isLoading, setIsLoading] = useState(false);
  const { loadArticle, data, error, loading } = useArticleLoader();
  const isArticleLoading =
    loading || (data == undefined && error == undefined && id != undefined);
  const { handleSubmit, control, getValues, clearErrors, reset, setValue } =
    useForm<IArticleInputs>({
      resolver,
      defaultValues: {
        id: id,
        ...defaultValues,
      },
    });
  const saveArticle = useArticleSaver(id == undefined, setValue);
  const onSubmit = useCallback(
    async (data: IArticleInputs) => {
      try {
        setIsLoading(true);
        toggleLoader(true);
        const id = await saveArticle(data);
        navigate(`/article/${id}`);
      } catch (error) {
        addToast({
          message: AppError.fromError(error).translate(t),
          type: 'error',
        });
      } finally {
        setIsLoading(false);
        toggleLoader(false);
      }
    },
    [addToast, navigate, saveArticle, t],
  );

  useEffect(() => {
    if (id) {
      loadArticle(id).then((article) => {
        if (article) reset(fromServer(article));
      });
    }
  }, [loadArticle, id, reset]);

  return (
    <CustomContainer
      sx={{ paddingTop: { xs: '50px', md: '104px' }, paddingBottom: '80px' }}
    >
      <GradientTypography
        variant="h1"
        gradient="primary"
        textAlign="center"
        animated
      >
        {id == undefined
          ? t('admin.article.newArticle')
          : t('admin.article.editArticle')}
      </GradientTypography>
      <Paper
        elevation={4}
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          margin: '64px 0px',
          borderRadius: '12px',
        }}
      >
        {isArticleLoading && (
          <Styled.Container>
            <CircularProgress />
          </Styled.Container>
        )}
        {error != undefined && (
          <NotFoundPage article sx={{ padding: '50px 0px' }} />
        )}
        {!isArticleLoading && error == undefined && (
          <React.Fragment>
            <Tabs
              //   orientation="vertical"
              variant="standard"
              value={language}
              onChange={(_, value: number) => setLanguage(value)}
              aria-label="Language"
              sx={(theme) => ({
                minWidth: '150px',
                borderBottom: 1,
                borderColor: alpha(theme.palette.divider, 0.12),
                '& .MuiTabs-flexContainer': {
                  justifyContent: 'space-evenly',
                },
                '& .MuiTabs-indicator': {
                  ...theme.directional({
                    right: 0,
                    left: 'unset',
                  }),
                },
              })}
            >
              <Tab
                id="article-tab-0"
                aria-controls="article-tabpanel-0"
                label={t('admin.article.english')}
                sx={(theme) => ({
                  flex: 1,
                  ...theme.directional({ borderTopLeftRadius: '12px' }),
                })}
              />
              <Tab
                id="article-tab-1"
                aria-controls="article-tabpanel-1"
                label={t('admin.article.arabic')}
                sx={(theme) => ({
                  flex: 1,
                  ...theme.directional({ borderTopRightRadius: '12px' }),
                })}
              />
            </Tabs>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <ArticleInputs
                index={0}
                shown={language == 0}
                control={control}
                getValues={getValues}
                clearErrors={clearErrors}
                disableFields={isLoading}
              />
              <ArticleInputs
                index={1}
                shown={language == 1}
                control={control}
                getValues={getValues}
                clearErrors={clearErrors}
                disableFields={isLoading}
              />
              <hr />
              <Box textAlign="center" margin="24px 0px">
                <AppButton type="submit" size="large">
                  {id
                    ? t('admin.article.saveArticle')
                    : t('admin.article.addArticle')}
                </AppButton>
              </Box>
            </form>
          </React.Fragment>
        )}
      </Paper>
    </CustomContainer>
  );
};

export default AdminArticlePage;
