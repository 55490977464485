import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import {
  Control,
  FieldValues,
  FieldPathByValue,
  PathValue,
  useController,
  UseFormClearErrors,
} from 'react-hook-form';
import {
  CloudUploadOutlined,
  CloudOffOutlined,
  SvgIconComponent,
  CancelOutlined,
} from '@mui/icons-material';
import {
  BoxProps,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabelProps,
} from '@mui/material';
import { IInputLabelProps } from './InputLabel';
import { Styled } from './styles';
import { AppInputLabel } from '.';

interface IImageInputProps<TFieldValues extends FieldValues = FieldValues> {
  name: FieldPathByValue<TFieldValues, File | string | undefined>;
  type?: string;
  control: Control<TFieldValues>;
  label?: string;
  labelProps?: IInputLabelProps & InputLabelProps;
  defaultValue?: PathValue<
    TFieldValues,
    FieldPathByValue<TFieldValues, File | string | undefined>
  >;
  variantColor?: 'dark_background' | 'light_background' | 'red_background';
  fullWidth?: boolean;
  readOnly?: boolean;
  clearErrors?: UseFormClearErrors<TFieldValues>;
}

const ImageInput = <TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  label,
  labelProps,
  defaultValue,
  variantColor,
  fullWidth,
  readOnly,
  clearErrors,
}: IImageInputProps<TFieldValues>): JSX.Element => {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = React.useState(false);

  const handleClickOpen = () => setShowDelete(true);
  const handleClose = () => setShowDelete(false);

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<
    TFieldValues,
    FieldPathByValue<TFieldValues, File | string | undefined>
  >({
    name: name,
    control: control,
    defaultValue: defaultValue,
  });

  const dropHandler = useCallback(
    (acceptedFiles: File[]) => {
      const data: File = acceptedFiles[0];
      // 1,000,000 bytes equals 1.0 MB
      if (data.size > 1000000) {
        control.setError(name, {
          type: 'value',
          message: t('files.largeFile', { limit: '1.0' }),
        });
      } else {
        onChange(data);
        clearErrors?.(name);
      }
    },
    [clearErrors, control, name, onChange, t],
  );
  const {
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: 1,
    noClick: true,
    onDrop: dropHandler,
    multiple: false,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/gif': ['.gif'],
    },
    onError: (e) => {
      console.log(e);
    },
  });
  const content = useMemo(() => {
    if (value != undefined && typeof value != 'string') {
      return URL.createObjectURL(value);
    } else {
      return value;
    }
  }, [value]);

  return (
    <React.Fragment>
      {label && (
        <AppInputLabel
          id={`${name}-label`}
          htmlFor={name}
          variantColor={variantColor}
          {...labelProps}
        >
          {label}
        </AppInputLabel>
      )}
      <FormControl variant="filled" fullWidth={fullWidth}>
        <div {...getRootProps()}>
          <input {...getInputProps()} readOnly={readOnly} />

          <Styled.ImageInput
            id={name}
            variantColor={variantColor}
            onChange={onChange}
            onClick={value == undefined && !readOnly ? open : undefined}
            isDrag={isDragActive}
            isInvalidDrag={isDragActive && isDragReject}
            error={!!error}
            aria-labelledby={`${name}-label`}
            aria-describedby={`${name}-helper`}
          >
            {isDragActive ? (
              <InfoBox
                Icon={isDragAccept ? CloudUploadOutlined : CloudOffOutlined}
                error={!isDragAccept}
                message={t(isDragAccept ? 'files.dropHere' : 'files.wrongFile')}
              />
            ) : value ? (
              <>
                {!readOnly && (
                  <Styled.RemoveIcon color="warning" onClick={handleClickOpen}>
                    <CancelOutlined />
                  </Styled.RemoveIcon>
                )}
                <Styled.Image src={content} />
              </>
            ) : (
              <InfoBox
                Icon={CloudUploadOutlined}
                message={t('files.dragHere')}
              />
            )}
          </Styled.ImageInput>
        </div>
        <Styled.HelperText
          id={`${name}-helper`}
          variantColor={variantColor}
          error={!!error}
        >
          {error?.message && t(error?.message)}
        </Styled.HelperText>
      </FormControl>
      <Dialog
        open={showDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('files.confirmDelete')}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>{t('files.cancel')}</Button>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
              onChange(null);
            }}
          >
            {t('files.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const InfoBox: React.FC<
  { Icon: SvgIconComponent; message: string; error?: boolean } & BoxProps
> = ({ Icon, message, error, ...props }) => {
  return (
    <Styled.Content {...props}>
      <Icon color={error ? 'error' : 'neutral80'} fontSize="large" />
      <Styled.DropboxText error={error}>{message}</Styled.DropboxText>
    </Styled.Content>
  );
};

export default ImageInput;
