import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SwitchTransition } from 'react-transition-group';
import { Box } from '@mui/material';
import { ReactComponent as Icon1 } from '@userpath/userpath/assets/icons/about_icon_1.svg';
import { ReactComponent as Icon2 } from '@userpath/userpath/assets/icons/about_icon_2.svg';
import { ReactComponent as Icon3 } from '@userpath/userpath/assets/icons/about_icon_3.svg';
import { ReactComponent as Icon4 } from '@userpath/userpath/assets/icons/about_icon_4.svg';
import { SVGFunctionComponent } from '@userpath/types';
import { Lang } from '@userpath/localization';
import { Library } from '@userpath/constants';
import { useElementSize } from '@userpath/components';
import { Styled } from './styles';

const minSwipeDistance = 50;
const autoPlayDuration = 5000;
const items: IItem[] = [
  {
    title: 'about.item1Title',
    details: 'about.item1Details',
    icon: Icon1,
  },
  {
    title: 'about.item2Title',
    details: 'about.item2Details',
    icon: Icon2,
  },
  {
    title: 'about.item3Title',
    details: 'about.item3Details',
    icon: Icon3,
  },
  {
    title: 'about.item4Title',
    details: 'about.item4Details',
    icon: Icon4,
  },
];
const useAutoNextPage = (nextPage: () => void) => {
  const timerRef = useRef<NodeJS.Timeout | undefined>();

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(nextPage, autoPlayDuration);
  }, [nextPage]);

  useEffect(() => {
    resetTimer();

    return () => {
      clearInterval(timerRef.current);
    };
  }, [nextPage, resetTimer]);

  return resetTimer;
};

interface IItem {
  title: string;
  details: string;
  icon: SVGFunctionComponent;
}

const InfoSection: React.FunctionComponent = () => {
  const { t, i18n } = useTranslation(Library.userpath);
  const isArabic = i18n.language == Lang.AR;
  const touchStart = useRef<number | undefined>();
  const touchEnd = useRef<number | undefined>();
  const [setRef, size] = useElementSize();
  const [index, setIndex] = React.useState<number>(0);
  const item = useMemo(() => items[index], [index]);
  const [path, indicatorPath] = useMemo(() => {
    const st = size.height * (size.width < 650 ? 0.9 : 0.6);
    const extra = size.height * (size.width < 650 ? 1.1 : 1.4);
    const curve = `0 ${st} Q ${size.width * 0.5} ${extra} ${size.width} ${st}`;
    return [
      `M 0 0 L ${curve} L ${size.width} ${st} L ${size.width} 0 Z`,
      `M ${curve}`,
    ];
  }, [size.width, size.height]);
  const navigate = useCallback(
    (to: 'next' | 'back', dirAware = true) => {
      if (isArabic && dirAware) to = to == 'next' ? 'back' : 'next';
      if (to == 'next') {
        setIndex((oldIndex) => (oldIndex + 1) % 4);
      } else {
        setIndex((oldIndex) => (oldIndex - 1 + 4) % 4);
      }
    },
    [isArabic],
  );
  const resetTimer = useAutoNextPage(() => navigate('next', false));

  useEffect(() => {
    const downHandler = ({ code }: KeyboardEvent) => {
      if (code == 'ArrowLeft') {
        navigate('back');
        resetTimer();
      } else if (code == 'ArrowRight') {
        navigate('next');
        resetTimer();
      }
    };

    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [navigate, resetTimer]);

  const onTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    touchEnd.current = undefined;
    touchStart.current = e.targetTouches[0].clientX;
  }, []);

  const onTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) =>
      (touchEnd.current = e.targetTouches[0].clientX),
    [],
  );

  const onTouchEnd = useCallback(() => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      navigate('next');
      resetTimer();
    } else if (isRightSwipe) {
      navigate('back');
      resetTimer();
    }
  }, [navigate, resetTimer]);

  return (
    <Box position="relative">
      <Styled.Container
        ref={setRef}
        path={path}
        index={index}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="10px"
          paddingBottom={{ md: '40px' }}
        >
          <Box flex={1}>
            <SwitchTransition>
              <Styled.Transition key={index} timeout={500}>
                <Styled.IconContainer>
                  <item.icon width="100%" height="100%" />
                </Styled.IconContainer>
                <Styled.Title>{t(item.title)}</Styled.Title>
                <Styled.Subtitle>{t(item.details)}</Styled.Subtitle>
              </Styled.Transition>
            </SwitchTransition>
          </Box>
          <Styled.Arrows />
          <Styled.ArrowsInfo>{t('about.navigate')}</Styled.ArrowsInfo>
        </Box>
      </Styled.Container>
      <Box position="absolute" top={0} left={0}>
        <Styled.Indicator flip={isArabic} path={indicatorPath} index={index} />
      </Box>
    </Box>
  );
};

export default InfoSection;
