import { RichTextEditor } from 'mui-tiptap';
import { Typography, alpha, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

interface IContentInputProps {
  error?: boolean;
  isPlaceholder?: boolean;
}

export const Styled = {
  ContentInput: styled(RichTextEditor, {
    shouldForwardProp: shouldForwardProp(['error', 'isPlaceholder']),
  })<IContentInputProps>(({ theme, error, isPlaceholder }) => ({
    backgroundColor: theme.palette.background.default,
    '& .MuiTiptap-FieldContainer-notchedOutline': {
      border: '1px solid',
      borderRadius: 4,
      borderColor: error
        ? theme.borders.inputError
        : theme.palette.text.neutral30,
      transition: theme.transitions.create(['border-color']),
      '&:hover': {
        borderColor: theme.borders.selectedInput,
      },
    },
    '& .MuiSvgIcon-root': {
      color: `${theme.palette.primary.main} !important`,
    },
    '& p.is-editor-empty:first-of-type::before': {
      float: 'inline-start !important',
    },
    '& .MuiSelect-select': {
      minWidth: '32px',
    },
    '&.MuiTiptap-FieldContainer-focused > .MuiTiptap-FieldContainer-notchedOutline':
      {
        border: '1px solid !important',
        borderColor: `${theme.palette.primary.main} !important`,
      },
    '& .ProseMirror': {
      minHeight: '200px',
      maxHeight: '80vh',
      overflow: 'auto',
      scrollbarColor: `${alpha(theme.palette.text.inputDark, 0.4)} #0000`,
    },
    '& .MuiTiptap-RichTextField-content': {
      padding: '8px',
      fontSize: theme.typography.sizes.h8,
      fontFamily: theme.typography.fontFamily,
      color: isPlaceholder
        ? theme.palette.text.neutral30
        : theme.palette.text.inputDark,
    },
  })),
  HelperText: styled(Typography, {
    shouldForwardProp: shouldForwardProp(['error']),
  })<{ error?: boolean }>(({ theme, error }) => ({
    margin: '3px 0px 0px 0px',
    color: error ? theme.palette.text.danger : theme.palette.text.inputLight,
  })),
};
