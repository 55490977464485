import { Box, alpha, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  Parent: styled(Box, { shouldForwardProp: shouldForwardProp(['device']) })<{
    device?: 'desktop' | 'mobile';
  }>(({ device }) => ({
    ...(device == 'desktop' && {
      padding: '24px 76px',
    }),
    ...(device == 'mobile' && {
      padding: '18px 36px',
    }),
  })),
  Child: styled(Box)({}),
};

export const DesktopStyled = {
  Device: styled(Box)({
    display: 'inline-block',
    position: 'relative',
    boxSizing: 'content-box',
    width: '960px',
    height: '600px',
    padding: '44px 114px 76px',
    // background: '#bebebe',
    borderRadius: '34px',
    '&::before': {
      width: 'calc(100% - 148px)',
      height: 'calc(100% - 8px)',
      position: 'absolute',
      content: '""',
      display: 'block',
      top: '4px',
      left: '74px',
      borderRadius: '30px',
      background: '#1e1e1e',
    },
  }),
  Screen: styled('div')(({ theme }) => ({
    width: '100%',
    position: 'relative',
    height: '100%',
    zIndex: '3',
    background: 'white',
    overflow: 'auto',
    display: 'block',
    borderRadius: '1px',
    boxShadow: '0 0 0 3px #111',
    scrollbarColor: `${alpha(theme.palette.text.neutral40, 0.6)} #0000`,
  })),
  TopBar: styled('div')({
    width: 'calc(100%)',
    height: '40px',
    position: 'absolute',
    content: '""',
    display: 'block',
    top: '680px',
    left: '0px',
    borderBottomLeftRadius: '90px 18px',
    borderBottomRightRadius: '90px 18px',
    background: '#bebebe',
    boxShadow: 'inset 0px -4px 13px 3px rgba(34, 34, 34, 0.6)',
    '&::before': {
      width: '100%',
      height: '24px',
      content: '""',
      display: 'block',
      top: '0',
      left: '0',
      background: '#f0f0f0',
      borderBottom: '2px solid #aaa',
      borderRadius: '5px',
      position: 'relative',
    },
    '&::after': {
      width: '16%',
      height: '14px',
      content: '""',
      display: 'block',
      top: '0',
      background: '#ddd',
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: '0',
      right: '0',
      borderRadius: '0 0 20px 20px',
      boxShadow: 'inset 0px -3px 10px #999',
    },
  }),
  BottomBar: styled('div')({
    background: 'transparent',
    width: '100%',
    height: '26px',
    position: 'absolute',
    content: '""',
    display: 'block',
    top: '680px',
    left: '0px',
    '&::before, &::after': {
      height: 'calc(100% - 2px)',
      width: '80px',
      content: '""',
      display: 'block',
      top: '0',
      position: 'absolute',
    },
    '&::before': {
      left: '0',
      //   background: '#f0f0f0',
      background:
        'linear-gradient(to right, #747474 0%, #c3c3c3 5%, #ebebeb 14%, #979797 41%, #f0f0f0 80%, #f0f0f0 100%, #f0f0f0 100%)',
    },
    '&::after': {
      right: '0',
      //   background: '#f0f0f0',
      background:
        'linear-gradient(to right, #f0f0f0 0%, #f0f0f0 0%, #f0f0f0 20%, #979797 59%, #ebebeb 86%, #c3c3c3 95%, #747474 100%)',
    },
  }),
  Camera: styled('div')({
    background: '#3c3d3d',
    width: '10px',
    height: '10px',
    position: 'absolute',
    top: '20px',
    left: '50%',
    marginLeft: '-5px',
    borderRadius: '100%',
  }),
};

export const MobileStyled = {
  Device: styled(Box)({
    display: 'inline-block',
    position: 'relative',
    boxSizing: 'content-box',
    width: '375px',
    height: '812px',
    padding: '26px',
    background: '#fdfdfd',
    boxShadow: 'inset 0 0 11px 0 black',
    borderRadius: '66px',
    '&::before': {
      width: 'calc(100% - 10px)',
      height: 'calc(100% - 10px)',
      position: 'absolute',
      top: '5px',
      content: '""',
      left: '5px',
      borderRadius: '61px',
      background: 'black',
      zIndex: '1',
    },
  }),
  Screen: styled('div')(({ theme }) => ({
    width: '100%',
    position: 'relative',
    height: '100%',
    zIndex: '3',
    background: 'white',
    overflow: 'auto',
    display: 'block',
    borderRadius: '40px',
    scrollbarColor: `${alpha(theme.palette.text.neutral40, 0.6)} #0000`,
  })),
  TopBar: styled('div')({
    display: 'block',
    width: '100%',
    position: 'absolute',
    height: '8px',
    background: 'rgba(black, 0.1)',
    left: '0',
    top: '80px',
  }),
  BottomBar: styled('div')({
    display: 'block',
    width: '100%',
    position: 'absolute',
    height: '8px',
    background: 'rgba(black, 0.1)',
    left: '0',
    bottom: '80px',
  }),
  Overflow: styled('div')({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    borderRadius: '66px',
    overflow: 'hidden',
  }),
  Shadow: styled('div')({
    borderRadius: '100%',
    width: '90px',
    height: '90px',
    position: 'absolute',
    background:
      'radial-gradient(ellipse at center, rgba(0,0,0,0.6) 0%,rgba(255,255,255,0) 60%)',
  }),
  ShadowTL: styled('div')({
    top: '-20px',
    left: '-20px',
  }),
  ShadowTR: styled('div')({
    top: '-20px',
    right: '-20px',
  }),
  ShadowBL: styled('div')({
    bottom: '-20px',
    left: '-20px',
  }),
  ShadowBR: styled('div')({
    bottom: '-20px',
    right: '-20px',
  }),
  InnerShadow: styled('div')({
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    position: 'absolute',
    top: '10px',
    overflow: 'hidden',
    left: '10px',
    borderRadius: '56px',
    boxShadow: 'inset 0 0 15px 0 rgba(white, 0.66)',
    zIndex: '1',
    '&::before': {
      boxSshadow: 'inset 0 0 20px 0 #FFFFFF',
      width: '100%',
      height: '116%',
      position: 'absolute',
      top: '-8%',
      content: '""',
      left: '0',
      borderRadius: '200px / 112px',
      zIndex: '2',
    },
  }),
  Volume: styled('div')({
    width: '3px',
    background: '#b5b5b5',
    position: 'absolute',
    left: '-3px',
    top: '116px',
    height: '32px',
    '&::before, &::after': {
      width: '3px',
      background: '#b5b5b5',
      position: 'absolute',
      height: '62px',
      content: '""',
      left: '0',
    },
    '&::before': {
      top: '62px',
    },
    '&::after': {
      top: '140px',
    },
  }),
  Sleep: styled('div')({
    width: '3px',
    background: '#b5b5b5',
    position: 'absolute',
    height: '96px',
    top: '200px',
    right: '-3px',
  }),
  Camera: styled('div')({
    width: '6px',
    height: '6px',
    top: '9px',
    borderRadius: '100%',
    position: 'absolute',
    left: '154px',
    background: '#0d4d71',
  }),
  Speaker: styled('div')({
    height: '6px',
    width: '60px',
    left: '50%',
    position: 'absolute',
    top: '9px',
    marginLeft: '-30px',
    background: '#171818',
    borderRadius: '6px',
  }),
  Notch: styled('div')({
    position: 'absolute',
    width: '210px',
    height: '30px',
    top: '26px',
    left: '108px',
    zIndex: '4',
    background: 'black',
    borderBottomLeftRadius: '24px',
    borderBottomRightRadius: '24px',
    '&::before, &::after': {
      content: '""',
      height: '8px',
      position: 'absolute',
      top: '0',
      width: '8px',
    },
    '&::after': {
      background:
        'radial-gradient(circle at bottom left, transparent 0, transparent 70%, black 70%, black 100%)',
      left: '-8px',
    },
    '&::before': {
      background:
        'radial-gradient(circle at bottom right, transparent 0, transparent 70%, black 70%, black 100%)',
      right: '-8px',
    },
  }),
};
