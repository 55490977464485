import * as Yup from 'yup';
import { isPhoneValid } from '../input/phone-input/phone_mask';

export interface IContactFormInputs {
  name: string;
  email: string;
  phone?: string;
  phone_formatted?: string;
  company?: string;
  message: string;
}

export const defaultValues = {
  name: '',
  email: '',
  phone: '',
  phone_formatted: '',
  company: '',
  message: '',
};

export const validationSchema: Yup.ObjectSchema<IContactFormInputs> =
  Yup.object().shape({
    name: Yup.string().required('errors.fieldRequired'),
    email: Yup.string()
      .email('errors.invalidEmail')
      .required('errors.fieldRequired'),
    phone: Yup.string().optional(),
    phone_formatted: Yup.string()
      .optional()
      .test(
        'phone-formatted',
        'errors.invalidPhoneNumber',
        (value, context) => {
          if (!value) return true;
          const rawPhone = context.parent.phone;
          return isPhoneValid(rawPhone);
        },
      ),
    company: Yup.string().optional(),
    message: Yup.string().required('errors.fieldRequired'),
  });
