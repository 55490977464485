import React, {
  useLayoutEffect,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import { I18nextProvider } from 'react-i18next';
import { CloneOptions } from 'i18next';
import { Library } from '@userpath/constants';
import { setupI18next } from './i18n';

export const i18n = setupI18next(Library.common);

export const createI82n = (options: CloneOptions | undefined = undefined) => {
  return i18n.cloneInstance(options);
};

export const LocaleProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [direction, setDirection] = useState(i18n.dir());
  useEffect(() => {
    const languageChanged = (lang: string) => {
      localStorage.setItem('language', lang);
      setDirection(i18n.dir());
    };
    i18n.on('languageChanged', languageChanged);
    return () => i18n.off('languageChanged', languageChanged);
  });
  useLayoutEffect(() => {
    document.body.dir = direction;
  }, [direction]);

  if (isValidElement(children)) {
    return (
      <I18nextProvider i18n={i18n}>
        {cloneElement(children, { direction } as never)}
      </I18nextProvider>
    );
  }

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
