import { Container, styled } from '@mui/material';

const CustomContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: '104px',
    paddingRight: '104px',
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
}));

CustomContainer.defaultProps = {
  maxWidth: 'xl',
};

export default CustomContainer;
