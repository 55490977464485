import React from 'react';
import { type ResponsiveStyleValue } from '@mui/system';
import { useStyleResolver } from '@userpath/components';
import { ServicesConstants, ServiceIndex } from './';

interface IServiceIconProps {
  selected?: boolean;
  size?: string | number | ResponsiveStyleValue<string | number>;
  index: ServiceIndex;
}

const ServiceIcon: React.FC<IServiceIconProps> = ({
  selected,
  size,
  index,
}) => {
  size = useStyleResolver(size);
  const Icon = ServicesConstants.Icons[index];
  return (
    <Icon
      variant="Bulk"
      color={selected ? '#FFF' : ServicesConstants.IconColors[index]}
      size={size}
    />
  );
};

export default ServiceIcon;
