import { Box, Button, IconButton, alpha, styled } from '@mui/material';
import { ReactComponent as LogoOriginal } from '@userpath/components/assets/svg/logo_original.svg';
import { ReactComponent as LogoWhite } from '@userpath/components/assets/svg/logo_white.svg';
import { shouldForwardProp } from '@userpath/utils';
import { CustomContainer } from '../custom-container';

export type FooterVariant = 'landing' | 'normal';

interface IFooterSharedProps {
  footerVariant: FooterVariant;
}

export const Styled = {
  Container: styled(Box, {
    shouldForwardProp: shouldForwardProp(['footerVariant', 'padTop']),
  })<IFooterSharedProps & { padTop: boolean }>(
    ({ footerVariant, padTop, theme }) => ({
      ...(footerVariant == 'normal' && {
        borderTop: `1px solid ${theme.palette.text.neutral10}`,
        ...(padTop && {
          marginTop: '172px',
          [theme.breakpoints.down('md')]: {
            marginTop: '92px',
          },
        }),
      }),
      ...(footerVariant == 'landing' && {
        position: 'relative',
        '&::before': {
          content: '""',
          zIndex: -3,
          background: theme.palette.background.primary,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        },
      }),
    }),
  ),
  BGFooter: styled('div', {
    shouldForwardProp: shouldForwardProp(['footerVariant']),
  })<IFooterSharedProps>(({ footerVariant, theme }) => ({
    ...(footerVariant == 'landing' && {
      background: `linear-gradient(180deg, 
          ${alpha(theme.palette.primary.main40 ?? '#FFF', 0)} 10.17%, 
          ${alpha(theme.palette.primary.main40 ?? '#FFF', 0.2)} 94.19%)`,
    }),
  })),
  Footer: styled(CustomContainer, {
    shouldForwardProp: shouldForwardProp(['footerVariant']),
  })<IFooterSharedProps>(({ footerVariant, theme }) => ({
    display: 'flex',
    gap: '48px',
    paddingTop: `${footerVariant == 'normal' ? '24px' : '68px'} !important`,
    paddingBottom: `${footerVariant == 'normal' ? '24px' : '68px'} !important`,
    [theme.breakpoints.down('md')]: {
      paddingTop: '48px !important',
    },
  })),
  Logo: styled(LogoOriginal)(({ theme }) => ({
    height: '56px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '48px',
    },
  })),
  LogoWhite: styled(LogoWhite)(({ theme }) => ({
    height: '78px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '48px',
    },
  })),
  MediaButton: styled(IconButton, {
    shouldForwardProp: shouldForwardProp(['footerVariant']),
  })<IFooterSharedProps>(({ footerVariant, theme }) => ({
    ...(footerVariant == 'landing' && {
      boxShadow: `inset 0px 0px 0px 2px ${alpha(theme.palette.text.neutral, 0.2)}`,
      '& path': {
        fill: `white`,
      },
    }),
    ...(footerVariant == 'normal' && {
      boxShadow: `inset 0px 0px 0px 2px ${theme.palette.text.neutral20}`,
      '&:hover': {
        '& path': {
          fill: `white`,
        },
        boxShadow: `inset 0px 0px 0px 2px #FFF0`,
        background: `linear-gradient(49.64deg,
            ${theme.palette.secondary.secondary} 11.52%,
            ${theme.palette.secondary.main} 78.33%,
            ${theme.palette.primary.secondary} 115.8%,
            ${theme.palette.primary.main} 174.46%)`,
      },
    }),
  })),
  AppBarButton: styled(Button, {
    shouldForwardProp: shouldForwardProp(['footerVariant']),
  })<IFooterSharedProps>(({ footerVariant, theme }) => ({
    display: 'block',
    fontSize: theme.typography.sizes.h8,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    color:
      footerVariant == 'landing'
        ? theme.palette.text.neutral
        : theme.palette.text.neutral40,
  })),
};

Styled.MediaButton.defaultProps = {
  color: 'secondaryLight',
  size: 'small',
};
