import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  useController,
} from 'react-hook-form';
import {
  Box,
  Button,
  Stack,
  FormControl,
  InputAdornment,
  InputBaseProps,
  InputLabelProps,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { Lang } from '@userpath/localization';
import { IInputLabelProps } from '../InputLabel';
import { AppInputLabel, Styled } from '..';
import { countries } from './countries';
import { getFormattedNumber, getNumber, useMask } from './phone_mask';

interface IPhoneInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = never,
> {
  name: Path<TFieldValues>;
  type?: string;
  control: Control<TFieldValues, TContext>;
  label?: string;
  labelProps?: IInputLabelProps & InputLabelProps;
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
  onPhoneChanged?: (phone: string) => void;
  variantColor?: 'dark_background' | 'light_background' | 'red_background';
}

const PhoneInput = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = never,
>({
  name,
  control,
  label,
  labelProps,
  defaultValue,
  variantColor,
  onPhoneChanged,
  ...props
}: React.PropsWithChildren<
  IPhoneInputProps<TFieldValues, TContext> &
    Omit<InputBaseProps, 'defaultValue' | 'name'>
>): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const mask = useMask(selectedCountry);
  const isArabic = i18n.language == Lang.AR;
  const open = Boolean(anchorEl);

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController<TFieldValues, Path<TFieldValues>>({
    name: name,
    control: control,
    defaultValue: defaultValue,
  });

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  useEffect(() => {
    if (value && typeof value == 'string') {
      if (onPhoneChanged)
        onPhoneChanged(getNumber(value, selectedCountry.format));
      onChange(getFormattedNumber(value, selectedCountry.format));
    }
  }, [onChange, onPhoneChanged, selectedCountry.format, value]);
  return (
    <Box>
      {label && (
        <AppInputLabel
          id={`${name}-label`}
          htmlFor={name}
          variantColor={variantColor}
          {...labelProps}
        >
          {label}
        </AppInputLabel>
      )}
      <FormControl variant="filled" fullWidth={props.fullWidth}>
        <Styled.PhoneInput
          {...props}
          {...mask}
          name={name}
          id={name}
          variantColor={variantColor}
          onChange={onChange}
          value={value}
          error={!!error}
          aria-labelledby={`${name}-label`}
          aria-describedby={`${name}-helper`}
          size="small"
          startAdornment={
            <InputAdornment position="start" variant="outlined">
              <Button size="small" color="neutral20" onClick={handleClick}>
                <Stack alignItems="center" direction="row" gap="2px">
                  <img
                    alt="Country"
                    height={16}
                    src={`https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/flags/4x3/${selectedCountry.iso2.toLowerCase()}.svg`}
                  />
                  <span style={{ lineHeight: 1 }}>+{selectedCountry.code}</span>
                  <KeyboardArrowDownOutlined fontSize="size18" />
                </Stack>
              </Button>
            </InputAdornment>
          }
          sx={{
            backgroundColor: '#EEEEEE',
          }}
        />
        <Styled.HelperText
          id={`${name}-helper`}
          variantColor={variantColor}
          error={!!error}
        >
          {error?.message && t(error?.message)}
        </Styled.HelperText>
      </FormControl>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{}}>
        {countries.map((country, index) => (
          <MenuItem
            key={index}
            selected={country.iso2 === selectedCountry.iso2}
            onClick={() => {
              setSelectedCountry(country);
              handleClose();
            }}
          >
            <ListItemIcon>
              <img
                alt="United States"
                height={16}
                src={`https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.0.0/flags/4x3/${country.iso2.toLowerCase()}.svg`}
              />
            </ListItemIcon>
            <ListItemText>{isArabic ? country.ar : country.en}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default PhoneInput;
