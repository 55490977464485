import { Box, Typography, alpha, styled } from '@mui/material';
import { FadeTransition } from '@userpath/components';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  Transition: styled(FadeTransition)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '26px',
  }),
  Header: styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    gap: '8px',
    '&::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0px -24px',
      padding: '0px 18px',
    },
  })),
  HeaderItem: styled(Box, {
    shouldForwardProp: shouldForwardProp(['selected', 'itemColor']),
  })<{ selected?: boolean; itemColor: string }>(
    ({ theme, itemColor, selected = false }) => ({
      ...theme.typography.h8,
      whiteSpace: 'nowrap',
      height: `calc(32px + ${theme.typography.sizes.h8}px * 1.5)`,
      borderRadius: '90px',
      color: selected
        ? theme.palette.text.neutral
        : theme.palette.text.neutral80,
      padding: '16px',
      background: selected ? itemColor : theme.palette.background.default,
      border: `1px solid ${selected ? itemColor : theme.palette.text.neutral20}`,
      cursor: 'pointer',
      transition: 'background 300ms, color 300ms, border 300ms',
      '&:hover': {
        border: `1px solid ${itemColor}`,
      },
      '> svg': {
        width: '24px',
        height: '24px',
      },
      '> svg path': {
        transition: 'stroke 300ms',
      },
      [theme.breakpoints.down('md')]: {
        '> svg': {
          width: '18px',
          height: '18px',
        },
      },
    }),
  ),
  ContentContainer: styled(Box)(({ theme }) => ({
    marginTop: '16px',
    border: '1px solid #E5E5E5',
    borderRadius: '16px',
    background: theme.palette.background.default,
    boxShadow: '0px 0px 24px 0px rgba(0, 0, 0, 0.08)',
    padding: '40px 32px',
    minHeight: '500px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '40px 40px 0px 0px',
      padding: '40px 20px',
      marginLeft: '-24px',
      marginRight: '-24px',
    },
  })),
  Title: styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: theme.typography.sizes.custom({ lg: 40, sm: 24 }),
  })),
  Details: styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: theme.typography.sizes.custom({ lg: 20, sm: 14 }),
    [theme.breakpoints.down('md')]: {
      lineHeight: 1.8,
    },
  })),
  ItemsContainer: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemColor', 'empty']),
  })<{ itemColor: string; empty: boolean }>(({ theme, itemColor, empty }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    color: itemColor,
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: theme.typography.sizes.custom({ lg: 16, sm: 14 }),
    transition: 'padding 300ms, background 500ms, color 300ms, border 300ms',
    [theme.breakpoints.up('md')]: {
      gap: '24px',
      padding: empty ? '0px' : '32px 0px',
    },
    [theme.breakpoints.down('md')]: {
      columnGap: '18px',
      rowGap: '8px',
      background: alpha(itemColor, 0.08),
      borderRadius: '10px',
      padding: empty ? '0px' : '16px 0px',
      marginTop: '24px',
    },
  })),
  ServiceItem: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemColor']),
  })<{ itemColor: string }>(({ theme, itemColor }) => ({
    [theme.breakpoints.up('md')]: {
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.1)',
      background: theme.palette.background.default,
      padding: '18px 10px',
      ...theme.directional({
        borderLeft: `3px solid ${itemColor}`,
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      }),
    },
    [theme.breakpoints.down('md')]: {
      padding: '10px 0px',
      ...theme.directional({
        paddingLeft: '8px',
      }),
      '&::before': {
        content: '"•"',
        ...theme.directional({
          paddingRight: '8px',
        }),
      },
    },
  })),
  AnimatedHeight: styled('div', {
    shouldForwardProp: shouldForwardProp(['lastHeight']),
  })<{ lastHeight: number }>(({ lastHeight }) => ({
    [`@keyframes shrinkHeight${lastHeight}`]: {
      '0%, 50%': {
        height: lastHeight,
      },
      '100%': {
        height: '0px',
      },
    },
    animation: `shrinkHeight${lastHeight} 800ms`,
  })),
};
