import { useMemo } from 'react';
import {
  collection,
  doc,
  CollectionReference,
  WithFieldValue,
  PartialWithFieldValue,
  setDoc,
} from 'firebase/firestore/lite';
import { useFirestore } from '@userpath/components';
import {
  ContactFormConverter,
  ContactFormDTO,
  ContactForm,
} from '@userpath/types';

const saveContactForm = async (
  formsRef: CollectionReference<ContactFormDTO, WithFieldValue<ContactForm>>,
  id: string,
  form: PartialWithFieldValue<ContactFormDTO>,
) => {
  const formDocRef = doc(formsRef, id);
  await setDoc(formDocRef, form, { merge: true });
};

export const useContactForms = () => {
  const firestore = useFirestore();
  const contactFormsRef = useMemo(
    () =>
      collection(firestore, 'contact_forms').withConverter(
        new ContactFormConverter(),
      ),
    [firestore],
  );
  const result = useMemo(
    () => ({
      contactFormsReference: contactFormsRef,
      generateId: () => doc(contactFormsRef).id,
      saveContactForm: (
        id: string,
        form: PartialWithFieldValue<ContactFormDTO>,
      ) => saveContactForm(contactFormsRef, id, form),
    }),
    [contactFormsRef],
  );
  return result;
};
