import React, { createContext, useContext, useMemo, useState } from 'react';

interface IContext {
  isColorBlindActive: boolean;
  fontSize: number;
  increaseFontSize: () => void;
  decreaseFontSize: () => void;
  updateColorBlind: (active: boolean) => void;
}

const Context = createContext({} as IContext);

export const GlobalContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isColorBlindActive, setIsColorBlindActive] = useState(
    Boolean(localStorage.getItem('color_blind') ?? false),
  );
  const [fontSize, setFontSize] = useState(
    Number(localStorage.getItem('font_size') ?? 0),
  );
  const updateColorBlind = useMemo(
    () => (active: boolean) => {
      setIsColorBlindActive(active);
    },
    [setIsColorBlindActive],
  );
  const increaseFontSize = useMemo(
    () => () => setFontSize((old) => Math.min(10, old + 1)),
    [setFontSize],
  );
  const decreaseFontSize = useMemo(
    () => () => setFontSize((old) => Math.max(0, old - 1)),
    [setFontSize],
  );

  return (
    <Context.Provider
      value={{
        isColorBlindActive,
        fontSize,
        updateColorBlind,
        increaseFontSize,
        decreaseFontSize,
      }}
    >
      <div className={isColorBlindActive ? 'blindness-mode' : ''}>
        {children}
      </div>
    </Context.Provider>
  );
};

export const useGlobalContext = () => useContext(Context);
