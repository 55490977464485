export const common = {
  yesLabel: 'Yes',
  noLabel: 'No',
  confirmButton: 'Confirm',
  cancelButton: 'Cancel',
  closeButton: 'Close',
  backButton: 'Back',
  doneButton: 'Done',
  header: {
    signOut: 'Sign out',
    home: 'Home',
    about: 'About us',
    services: 'Services',
    articles: 'Articles',
    careers: 'Careers & Partnerships',
    contact: 'Contact Us',
    changeMode: 'Colorblind',
    title: 'Elevate Your Business',
    with: 'With',
    item1: 'User Experience',
    item2: 'Research',
    item3: 'User Testing',
    item4: 'UX Audits',
    item5: 'Digital Transformation',
    item6: 'Cultural Understanding',
    item7: 'Design Thinking',
    item8: 'Strategy',
    item9: 'Customer Centricity',
    item10: 'Data ',
    item11: 'Real Users',
  },
  contact: {
    letsTalk: 'We Look Forward to Knowing your Business!',
    notSure: "Not Sure What's Needed For Your Business?",
    letsTalkInfo:
      "Email us at <1><2 href='mailto:info@userpath.sa'>info@userpath.sa</2></1>, or fill out this simple form and we'll walk you through our services and provide an honest consultation.",
    at: 'at',
    optional: '(optional)',
    name: 'Name',
    writeHere: 'Write here',
    email: 'Email',
    phone: 'Phone Number',
    company: 'Company/Organization',
    message: 'Message',
    messageInfo: "Tell us about your project or inquire (don't overthink it)",
    submit: 'Submit',
    socialInfo:
      'Our social media accounts will be activated soon. Thank you for understanding',
    sentSuccessfully:
      'Your information has been submitted successfully, we will contact you shortly',
  },
  files: {
    dragHere: 'Select a file or drag here',
    dropHere: 'Drop the file here',
    wrongFile: 'This file is not supported',
    largeFile: "The file shouldn't exceed {{limit}} MB",
    confirmDelete: 'Are you sure you want delete the image?',
    delete: 'Delete',
    cancel: 'Cancel',
  },
  errors: {
    sorry: 'So Sorry!',
    fieldRequired: 'This field is required.',
    invalidEmail: 'Please enter a valid email.',
    invalidPhoneNumber: 'Please enter a valid phone.',
    invalidCredentials: 'Invalid credentials',
    invalidPassword: 'Invalid password',
    loginFailed: 'Login failed (Code: {{code}})',
    error: 'Error',
    notFound: "The content you are looking for couldn't be found",
    unknownError: 'An unknown error has occurred',
    unknownCodeError: 'An unknown error has occurred (Code: {{code}})',
    unknownMessageError: 'An unknown error has occurred ({{code}})',
    pageNotFound: 'Page not found',
    pageNotFoundDetails:
      'Oops! The page you are looking for does not exist. It might have been moved or deleted.',
    articleNotFound: 'Article not found',
    articleNotFoundDetails:
      'Oops! The article you are looking for does not exist. It might have been moved or deleted.',
  },
};
