import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SVGFunctionComponent } from '@userpath/types';

interface ICareerItemProps {
  icon: SVGFunctionComponent;
  title: string;
  details?: string;
}

const CareerItem: React.FC<ICareerItemProps> = ({
  icon: Icon,
  title,
  details,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const iconSize = isMobile ? 40 : 48;
  return (
    <Box
      sx={{
        padding: '8px 24px',
        height: '100%',
      }}
    >
      <Grid
        container
        rowSpacing="16px"
        columnSpacing="16px"
        alignItems="center"
      >
        <Grid item sm={12}>
          <Icon width={iconSize} height={iconSize} />
        </Grid>
        <Grid item sm={12}>
          <Typography
            variant="h5"
            fontWeight={500}
            sx={(theme) => ({ color: theme.palette.text.neutral100 })}
          >
            {title}
          </Typography>
          {details && (
            <Typography
              variant="h5"
              sx={(theme) => ({ color: theme.palette.text.neutral50 })}
            >
              {details}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareerItem;
