/* eslint-disable import/no-unresolved */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper as SwiperType } from 'swiper/types';
import { EffectCoverflow } from 'swiper/modules';
import { Box, Grid, Skeleton, useTheme } from '@mui/material';
import { AppError, ArticleDTO } from '@userpath/types';
import { Lang } from '@userpath/localization';
import { HomeArticleCard, NetworkState } from '@userpath/components';
import { Library } from '@userpath/constants';
import { useArticles } from '@userpath/services';
import { apiReducer, initialAPIState, Action } from '@userpath/utils';
import Navigation from '../Navigation';
import { Styled } from './styles';

import 'swiper/css';
import 'swiper/css/effect-coverflow';

const autoPlayDuration = 15000;

const useAutoNextPage = (nextPage: () => void) => {
  const timerRef = useRef<NodeJS.Timeout | undefined>();

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(nextPage, autoPlayDuration);
  }, [nextPage]);

  useEffect(() => {
    resetTimer();

    return () => {
      clearInterval(timerRef.current);
    };
  }, [nextPage, resetTimer]);

  return resetTimer;
};

const Articles: React.FC = () => {
  const { t, i18n } = useTranslation(Library.userpath);
  const isArabic = i18n.language == Lang.AR;
  const theme = useTheme();
  const [swiper, setSwiper] = useState<SwiperType | undefined>();
  const articlesServices = useArticles();
  const [{ data, loading, error }, dispatchArticles] = useReducer(
    apiReducer<ArticleDTO[], AppError>(),
    initialAPIState,
  );

  useEffect(() => {
    dispatchArticles({ type: Action.loading });

    articlesServices
      .getLatestArticles()
      .then((payload) => dispatchArticles({ type: Action.success, payload }))
      .catch((error) => {
        dispatchArticles({
          type: Action.error,
          error: AppError.fromError(error),
        });
      });
  }, [articlesServices]);

  const navigate = useCallback(
    (to: 'next' | 'back') => {
      if (!data) return;
      if (to == 'next') {
        if (swiper?.slideNext() == false) swiper.slideTo(0);
      } else {
        if (swiper?.slidePrev() == false) swiper.slideTo(data.length - 1);
      }
    },
    [data, swiper],
  );

  const resetTimer = useAutoNextPage(() => navigate('next'));
  const previousPage = useCallback(() => {
    navigate('back');
    resetTimer();
  }, [navigate, resetTimer]);
  const nextPage = useCallback(() => {
    navigate('next');
    resetTimer();
  }, [navigate, resetTimer]);
  if (data && data.length == 0) {
    return <Box />;
  }
  return (
    <Grid container gap="40px">
      <Grid item xs={12} textAlign="center">
        <Styled.Title>{t('home.articles')}</Styled.Title>
      </Grid>
      <Grid item xs={12} textAlign="center">
        {error != undefined && (
          <NetworkState
            variant="light"
            title={t('errors.sorry')}
            details={error.translate(t)}
          />
        )}
        {error == undefined && (
          <Styled.Swiper
            key={isArabic ? 'swiper_rtl' : 'swiper_ltr'}
            dir={isArabic ? 'rtl' : 'ltr'}
            loop={data && data.length > 3}
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
            onAny={(name) => {
              console.log(name);
            }}
            autoplay={{
              delay: 10000,
            }}
            spaceBetween={62}
            slidesPerView={1.2}
            centeredSlides={true}
            effect="coverflow"
            coverflowEffect={{
              slideShadows: false,
              rotate: 0,
              stretch: 0,
              modifier: 1,
              depth: 250,
            }}
            breakpoints={{
              // [theme.breakpoints.values.xs]: {
              //   slidesPerView: 1.8,
              //   spaceBetween: -60,
              // },
              // [theme.breakpoints.values.sm]: {
              //   spaceBetween: -60,
              // },
              [theme.breakpoints.values.sm]: {
                slidesPerView: 1.2,
                spaceBetween: 100,
              },
              [theme.breakpoints.values.md]: {
                slidesPerView: 1.8,
                spaceBetween: 84,
              },
              [theme.breakpoints.values.lg]: {
                slidesPerView: 1.8,
                spaceBetween: 124,
              },
              [theme.breakpoints.values.xl]: {
                slidesPerView: 1.8,
                spaceBetween: 130,
              },
            }}
            modules={[EffectCoverflow]}
          >
            {loading &&
              Array.from(Array(4)).map((_, index) => (
                <Styled.SwiperSlide key={index}>
                  <Skeleton
                    variant="rounded"
                    sx={(theme) => ({
                      bgcolor: '#FFF2',
                      height: '400px',
                      [theme.breakpoints.down('md')]: {
                        height: '300px',
                      },
                    })}
                  />
                </Styled.SwiperSlide>
              ))}
            {data &&
              data.map((article, i) => (
                <Styled.SwiperSlide key={i}>
                  <HomeArticleCard article={article} />
                </Styled.SwiperSlide>
              ))}
          </Styled.Swiper>
        )}
      </Grid>
      <Grid item xs={12}>
        {data && data?.length > 1 && (
          <Navigation
            justifyContent="center"
            onBackPressed={previousPage}
            onNextPressed={nextPage}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Articles;
