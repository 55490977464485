export const common = {
  yesLabel: 'نعم',
  noLabel: 'لا',
  confirmButton: 'تأكيد',
  cancelButton: 'إلغاء',
  closeButton: 'إغلاق',
  backButton: 'العودة',
  doneButton: 'منتهي',
  header: {
    signOut: 'تسجيل الخروج',
    home: 'الصفحة الرئيسية',
    about: 'عن الشركة',
    services: 'الخدمات',
    articles: 'المقالات',
    careers: 'الوظائف والشراكات',
    contact: 'تواصل معنا',
    changeMode: 'عمى الألوان',
    title: 'طوّر مشروعك',
    with: 'من خلال',
    item1: 'تجربة المستخدم',
    item2: 'الأبحاث',
    item3: 'اختبار المستخدم',
    item4: 'مراجعة تجربة المستخدم',
    item5: 'التحول الرقمي',
    item6: 'فهم الثقافة',
    item7: 'التصميم الفعّال',
    item8: 'الاستراتيجية',
    item9: 'مركزية المستخدم',
    item10: 'البيانات',
    item11: 'المستخدمين الحقيقيين',
  },
  contact: {
    letsTalk: 'نتطلّع للتعرف على تفاصيل مشروعك!',
    notSure: 'لست متأكداً من الخدمة المناسبة لمشروعك؟',
    letsTalkInfo:
      "تواصل معنا عبر البريد إلكتروني <1><2 href='mailto:info@userpath.sa'>info@userpath.sa</2></1> أو من خلال هذا النموذج البسيط وسنشرح لك جميع الخدمات وأي نوع منها يناسب مشروعك بكل صدق وأمانة.",
    at: 'في',
    optional: '(اختياري)',
    name: 'الاسم',
    writeHere: 'اكتب هنا',
    email: 'البريد الإلكتروني',
    phone: 'رقم الجوال',
    company: 'اسم الشركة/الجهة',
    message: 'الرسالة',
    messageInfo: 'أخبرنا عن مشروعك بشكل مختصر أو أي استفسار لديك',
    submit: 'إرسال',
    socialInfo: 'سيتم تفعيل شبكات التواصل الاجتماعي قريبا. نفدر انتظاركم',
    sentSuccessfully:
      'تم إرسال البيانات بنجاح, وسنقوم بالتواصل معك في أقرب وقت ممكن',
  },
  files: {
    dragHere: 'اختر ملف أو قم بسحبه هنا',
    dropHere: 'ضع الملف هنا',
    wrongFile: 'هذا الملف غير مدعوم',
    largeFile: 'يجب أن يكون حجم الملف أقل من {{limit}} ميجا',
    confirmDelete: 'هل أنت متأكد من رغبتك بحذف الصورة؟',
    delete: 'حذف',
    cancel: 'إلغاء',
  },
  errors: {
    sorry: 'نعتذر!',
    fieldRequired: 'هذا الحقل مطلوب',
    invalidEmail: 'الرجاء إدخال بريد إلكتروني صالح',
    invalidPhoneNumber: 'الرجاء إدخال رقم هاتف صالح',
    invalidCredentials: 'البريد الإلكتروني أو كلمة السر غير صحيحة',
    invalidPassword: 'كلمة السر غير صحيحة',
    loginFailed: 'فشلت عملية تسجيل الدخول (رمز الخطأ: {{code}})',
    error: 'خطأ',
    notFound: 'المحتوى الذي تبحث عنه غير موجود',
    unknownError: 'حدث خطأ مجهول',
    unknownCodeError: 'حدث خطأ مجهول (رمز الخطأ: {{code}})',
    unknownMessageError: 'حدث خطأ مجهول ({{message}})',
    pageNotFound: 'الصفحة غير موجودة',
    pageNotFoundDetails:
      'عذرًا! الصفحة التي تبحث عنها غير موجودة. ربما قد تم نقلها أو حذفها.',
    articleNotFound: 'المقال غير موجود',
    articleNotFoundDetails:
      'عذرًا! المقال الذي تبحث عنه غير موجود. ربما قد تم نقله أو حذفه.',
  },
};
