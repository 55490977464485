import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { Library } from '@userpath/constants';
import { Lang } from '@userpath/localization';
import { HomeArticleCard } from '@userpath/components';
import { ConfigOverrider } from '@userpath/utils';
import { ArticleDTO } from '@userpath/types';
import { Styled } from './styles';
import { Device } from './Device';

interface IPreviewHomeProps {
  article: ArticleDTO;
  open?: boolean;
  onClose: () => void;
}

const PreviewHome: React.FC<IPreviewHomeProps> = ({
  article,
  open = false,
  onClose,
}) => {
  const { t: ot, i18n } = useTranslation(Library.userpath);
  const [isMobile, setIsMobile] = useState(false);
  const [isArabic, setIsArabic] = useState(i18n.language == Lang.AR);
  const { t } = useTranslation(Library.userpath, {
    lng: isArabic ? Lang.AR : Lang.EN,
  });
  const updateLanguage = useCallback(() => {
    setIsArabic((old) => !old);
  }, []);
  const updateView = useCallback(() => {
    setIsMobile((old) => !old);
  }, []);

  return (
    <Styled.Dialog
      open={open}
      maxWidth="xl"
      onClose={onClose}
      aria-labelledby="preview-home-title"
    >
      <DialogTitle id="preview-home-title">
        {ot('admin.article.previewHome')}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent dividers>
        <ConfigOverrider
          lang={isArabic ? Lang.AR : Lang.EN}
          forceBreakpoint={isMobile ? 'xs' : 'md'}
        >
          <Device device={isMobile ? 'mobile' : 'desktop'}>
            <Styled.Container
              bgcolor={(theme) => theme.palette.background.primary}
              isArabic={isArabic}
            >
              <Grid container gap="40px">
                <Grid item xs={12} md={12} textAlign="center">
                  <Styled.LatestArticlesTitle>
                    {t('home.articles')}
                  </Styled.LatestArticlesTitle>
                </Grid>
                <Grid item xs={12} md={12}>
                  <HomeArticleCard disableInteractions article={article} />
                </Grid>
              </Grid>
            </Styled.Container>
          </Device>
        </ConfigOverrider>
      </DialogContent>
      <DialogActions>
        <Button onClick={updateLanguage}>
          {ot(isArabic ? 'admin.article.english' : 'admin.article.arabic')}
        </Button>
        <Button onClick={updateView}>
          {ot(isMobile ? 'admin.article.desktop' : 'admin.article.mobile')}
        </Button>
      </DialogActions>
    </Styled.Dialog>
  );
};

export default PreviewHome;
