import { styled } from '@mui/material';
import { CustomContainer } from '@userpath/components';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  BGContainer: styled('div', {
    shouldForwardProp: shouldForwardProp(['error']),
  })<{ error?: boolean }>(({ theme, error = false }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      zIndex: -3,
      background: error
        ? theme.palette.background.default
        : theme.palette.background.paper,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      ...(error && {
        borderTop: `10px solid ${theme.palette.background.paper}`,
      }),
    },
  })),
  Container: styled(CustomContainer)({
    paddingTop: '50px',
    paddingBottom: '170px',
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
  }),
};
