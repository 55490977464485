import React, { useCallback, useMemo } from 'react';
import { RichTextReadOnly } from 'mui-tiptap';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { ArticleDTO, ContentDTO } from '@userpath/types';
import { formatDate, getLocalized } from '@userpath/utils';
import { Library } from '@userpath/constants';
import { useTiptapExtensions } from '@userpath/components';
import { Lang } from '@userpath/localization';
import { Styled, StyledContent } from './styles';
import ArticleActions from './ArticleActions';

interface IArticleContentProps {
  article: ArticleDTO;
  content?: ContentDTO;
  disableInteractions?: boolean;
  showAdminButtons?: boolean;
}

const formatContent = (content: string | undefined) => {
  // if (content) {
  //   return content
  //     .replaceAll('color: rgb(0, 0, 0)', '')
  //     .replaceAll('font-size: 16px', '');
  // }
  return content;
};

const ArticleContent: React.FC<IArticleContentProps> = ({
  article,
  content,
  disableInteractions,
  showAdminButtons,
}) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const extensions = useTiptapExtensions();
  const isArabic = i18n.language == Lang.AR;
  const getValue = useCallback(
    (english?: string, arabic?: string) =>
      getLocalized(isArabic, english, arabic),
    [isArabic],
  );
  const title = useMemo(
    () => getValue(article.titleEnglish, article.titleArabic),
    [getValue, article.titleArabic, article.titleEnglish],
  );
  const writer = useMemo(
    () => getValue(article.writerEnglish, article.writerArabic),
    [getValue, article.writerArabic, article.writerEnglish],
  );
  const tags = useMemo(
    () =>
      article.tags?.map((tag) => ({
        title: getValue(tag.titleEnglish, tag.titleArabic),
        color: tag.color,
      })),
    [article.tags, getValue],
  );
  const hasUpdateDate = useMemo(
    () =>
      article.updatedDate != undefined &&
      article.createdDate != article.updatedDate,
    [article],
  );

  return (
    <Box display="flex" justifyContent="center">
      <StyledContent.ContentContainer>
        <Styled.Container gap="24px">
          {tags && tags.length > 0 && (
            <Styled.FlexContainer>
              {tags.map((tag, index) => (
                <Styled.Tag key={index} itemColor={tag.color}>
                  {tag.title}
                </Styled.Tag>
              ))}
            </Styled.FlexContainer>
          )}
          <StyledContent.ArticleTitle>{title}</StyledContent.ArticleTitle>
          <Styled.FlexContainer>
            <Box flex={{ xs: '0 0 100%', md: 'unset' }}>
              <Styled.DetailsKey>{t('articles.by')}</Styled.DetailsKey>{' '}
              <Styled.DetailsValue>{writer}</Styled.DetailsValue>
            </Box>
            <Styled.Divider sx={{ display: { xs: 'none', md: 'block' } }} />
            {article.readingTime != undefined && article.readingTime > 0 && (
              <React.Fragment>
                <Box>
                  <Styled.DetailsKey>
                    {t('articles.readingTime')}
                  </Styled.DetailsKey>{' '}
                  <Styled.DetailsValue>
                    {t('articles.minutes', { count: article.readingTime })}
                  </Styled.DetailsValue>
                </Box>
                <Styled.Divider />
              </React.Fragment>
            )}
            <Box>
              <Styled.DetailsKey>{t('articles.published')}</Styled.DetailsKey>{' '}
              <Styled.DetailsValue>
                {article.createdDate
                  ? formatDate(new Date(article.createdDate))
                  : '????'}
              </Styled.DetailsValue>
            </Box>
          </Styled.FlexContainer>
          <StyledContent.Actions>
            <Grid container>
              {article.updatedDate && hasUpdateDate && (
                <Grid
                  item
                  xs
                  flex={1}
                  alignContent="center"
                  display={{ xs: 'none', md: 'block' }}
                >
                  <Styled.DetailsKey>
                    {t('articles.lastUpdated')}
                  </Styled.DetailsKey>{' '}
                  <Styled.DetailsValue>
                    {formatDate(new Date(article.updatedDate))}
                  </Styled.DetailsValue>
                </Grid>
              )}
              <Grid item xs={12} md={hasUpdateDate ? 'auto' : 12}>
                <ArticleActions
                  article={article}
                  disableInteractions={disableInteractions}
                  showAdminButtons={showAdminButtons}
                />
              </Grid>
            </Grid>
          </StyledContent.Actions>

          {article.updatedDate && hasUpdateDate && (
            <Box display={{ xs: 'block', md: 'none' }}>
              <Styled.DetailsKey>{t('articles.lastUpdated')}</Styled.DetailsKey>{' '}
              <Styled.DetailsValue>
                {formatDate(new Date(article.updatedDate))}
              </Styled.DetailsValue>
            </Box>
          )}
          <StyledContent.Content>
            <RichTextReadOnly
              content={formatContent(content?.content)}
              extensions={extensions}
            />
          </StyledContent.Content>
        </Styled.Container>
      </StyledContent.ContentContainer>
    </Box>
  );
};

export default ArticleContent;
