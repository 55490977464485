import { Box, Dialog, Typography, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  Dialog: styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(3),
      overflow: 'hidden',
      boxSizing: 'content-box',
    },
  })),
  LatestArticlesTitle: styled(Typography)(({ theme }) => ({
    ...theme.typography.h3,
    fontWeight: 'bold',
    color: theme.palette.text.neutral,
  })),
  Container: styled(Box, {
    shouldForwardProp: shouldForwardProp(['isArabic']),
  })<{ isArabic?: boolean }>(({ theme, isArabic }) => ({
    direction: isArabic ? 'rtl' : 'ltr',
    padding: '36px',
    minHeight: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      padding: '48px 18px 18px 18px',
    },
  })),
};
