import React, { CSSProperties, useMemo } from 'react';
import { Property } from 'csstype';
import { useTranslation } from 'react-i18next';
import { CssBaseline, useMediaQuery, Breakpoint } from '@mui/material';
import { ThemeProvider, createTheme, ThemeOptions } from '@mui/material/styles';
import { useGlobalContext } from '@userpath/utils';
import { getTypographyConfig } from './options';

import './fonts/index.css';
import { breakpoints as defaultBreakpoints } from './options/breakpoints';
interface IThemeConfigProviderProps {
  forceBreakpoint?: Breakpoint;
}
export const ThemeConfigProvider: React.FC<
  React.PropsWithChildren<IThemeConfigProviderProps>
> = ({ children, forceBreakpoint }) => {
  const { i18n } = useTranslation();
  const direction = i18n.language === 'ar' ? 'rtl' : 'ltr';
  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor: string) =>
    augmentColor({ color: { main: mainColor } });

  const globalContext = useGlobalContext();

  const breakpoints = useMemo(() => {
    if (forceBreakpoint) {
      return {
        xs: forceBreakpoint == 'xs' ? 0 : 100000,
        sm: forceBreakpoint == 'sm' ? 0 : 100000,
        md: forceBreakpoint == 'md' ? 0 : 100000,
        lg: forceBreakpoint == 'lg' ? 0 : 100000,
        xl: forceBreakpoint == 'xl' ? 0 : 100000,
      };
    } else {
      return undefined;
    }
  }, [forceBreakpoint]);
  const isMobile = useMediaQuery(
    `@media (max-width: ${(breakpoints ?? defaultBreakpoints).md}px)`,
  );
  const colors = {
    neutral: '#fff',
    neutral10: '#E9E9EA',
    neutral20: '#D3D4D4',
    neutral30: '#BEBEBF',
    neutral40: '#A8A8A9',
    neutral50: '#929293',
    neutral60: '#7C7D7E',
    neutral70: '#666769',
    neutral80: '#515153',
    neutral90: '#3B3C3E',
    neutral100: '#252628',
  };
  const config: ThemeOptions = {
    directional: (ltrProps: DirectionalCSSProperties): CSSProperties => {
      if (direction == 'ltr') {
        return ltrProps;
      } else {
        return {
          left: ltrProps.right,
          right: ltrProps.left,
          borderLeft: ltrProps.borderRight,
          borderRight: ltrProps.borderLeft,
          borderLeftColor: ltrProps.borderRightColor,
          borderRightColor: ltrProps.borderLeftColor,
          borderLeftStyle: ltrProps.borderRightStyle,
          borderRightStyle: ltrProps.borderLeftStyle,
          borderLeftWidth: ltrProps.borderRightWidth,
          borderRightWidth: ltrProps.borderLeftWidth,
          marginLeft: ltrProps.marginRight,
          marginRight: ltrProps.marginLeft,
          paddingLeft: ltrProps.paddingRight,
          paddingRight: ltrProps.paddingLeft,
          borderTopLeftRadius: ltrProps.borderTopRightRadius,
          borderTopRightRadius: ltrProps.borderTopLeftRadius,
          borderBottomLeftRadius: ltrProps.borderBottomRightRadius,
          borderBottomRightRadius: ltrProps.borderBottomLeftRadius,
          scrollMarginLeft: ltrProps.scrollMarginRight,
          scrollMarginRight: ltrProps.scrollMarginLeft,
          scrollPaddingLeft: ltrProps.scrollPaddingRight,
          scrollPaddingRight: ltrProps.scrollPaddingLeft,
          scrollSnapMarginLeft: ltrProps.scrollSnapMarginRight,
          scrollSnapMarginRight: ltrProps.scrollSnapMarginLeft,
          MozBorderLeftColors: ltrProps.MozBorderRightColors,
          MozBorderRightColors: ltrProps.MozBorderLeftColors,
          WebkitBorderTopLeftRadius: ltrProps.WebkitBorderTopRightRadius,
          WebkitBorderTopRightRadius: ltrProps.WebkitBorderTopLeftRadius,
          WebkitBorderBottomLeftRadius: ltrProps.WebkitBorderBottomRightRadius,
          WebkitBorderBottomRightRadius: ltrProps.WebkitBorderBottomLeftRadius,
        };
      }
    },
    typography: getTypographyConfig(
      direction,
      isMobile,
      globalContext.fontSize,
    ),
    direction,
    breakpoints: {
      values: breakpoints ?? defaultBreakpoints,
    },
    // shape: {
    //   borderRadius: 4,
    // },
    spacing: 5,
    status: {
      danger: '#FF0000',
    },
    borders: {
      input: '#DBDCDD33',
      inputError: '#D51246',
      selectedInput: '#2D3982',
    },
    palette: {
      mode: 'light',
      secondaryLight: createColor('#FFFFFF'),
      neutral: createColor(colors.neutral),
      neutral10: createColor(colors.neutral10),
      neutral20: createColor(colors.neutral20),
      neutral30: createColor(colors.neutral30),
      neutral40: createColor(colors.neutral40),
      neutral50: createColor(colors.neutral50),
      neutral60: createColor(colors.neutral60),
      neutral70: createColor(colors.neutral70),
      neutral80: createColor(colors.neutral80),
      neutral90: createColor(colors.neutral90),
      neutral100: createColor(colors.neutral100),
      action: {
        active: '#AE02A3',
      },
      primary: {
        main: '#1801A1',
        main130: '#110171',
        main70: '#5D4DBD',
        main40: '#A399D9',
        main10: '#E8E6F6',
        secondary: '#AE02A3',
        secondary130: '#7A0172',
        secondary70: '#C64EBF',
        secondary40: '#DF9ADA',
        secondary10: '#F7E6F6',
      },
      secondary: {
        main: '#E5774D',
        secondary: '#FED573',
        main130: '#9280FF',
      },
      background: {
        default: '#FFFFFF',
        paper: '#F7F7F7',
        secondary: '#141128',
        header: '#0B0529',
        primary: '#0A0528',
        linear1Start: '#070220',
        linear1End: '#0A0528',
        input: '#FFFFFF',
        map: '#D9D9D9',
      },
      text: {
        danger: '#D51246',
        dangerLight: '#FFCCCC',
        inputLight: '#E9E9EA',
        inputDark: '#21292F',
        ...colors,
      },
    },
    components: {
      MuiSvgIcon: {
        variants: [
          { props: { color: 'neutral' }, style: { color: colors.neutral } },
          { props: { color: 'neutral10' }, style: { color: colors.neutral10 } },
          { props: { color: 'neutral20' }, style: { color: colors.neutral20 } },
          { props: { color: 'neutral30' }, style: { color: colors.neutral30 } },
          { props: { color: 'neutral40' }, style: { color: colors.neutral40 } },
          { props: { color: 'neutral50' }, style: { color: colors.neutral50 } },
          { props: { color: 'neutral60' }, style: { color: colors.neutral60 } },
          { props: { color: 'neutral70' }, style: { color: colors.neutral70 } },
          { props: { color: 'neutral80' }, style: { color: colors.neutral80 } },
          { props: { color: 'neutral90' }, style: { color: colors.neutral90 } },
          {
            props: { color: 'neutral100' },
            style: { color: colors.neutral100 },
          },
          {
            props: { fontSize: 'size18' },
            style: {
              fontSize: '18px',
            },
          },
          {
            props: { fontSize: 'size24' },
            style: {
              fontSize: '24px',
            },
          },
          {
            props: { fontSize: 'responsive24' },
            style: {
              fontSize: isMobile ? '18px' : '24px',
            },
          },
        ],
      },
    },
  };
  const theme = createTheme(config);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export interface DirectionalCSSProperties {
  left?: Property.Left<string | number>;
  right?: Property.Right<string | number>;
  borderLeft?: Property.BorderLeft<string | number>;
  borderRight?: Property.BorderRight<string | number>;
  borderLeftColor?: Property.BorderLeftColor;
  borderRightColor?: Property.BorderRightColor;
  borderLeftStyle?: Property.BorderLeftStyle;
  borderRightStyle?: Property.BorderRightStyle;
  borderLeftWidth?: Property.BorderLeftWidth<string | number>;
  borderRightWidth?: Property.BorderRightWidth<string | number>;
  marginLeft?: Property.MarginLeft<string | number>;
  marginRight?: Property.MarginRight<string | number>;
  paddingLeft?: Property.PaddingLeft<string | number>;
  paddingRight?: Property.PaddingRight<string | number>;
  borderTopLeftRadius?: Property.BorderTopLeftRadius<string | number>;
  borderTopRightRadius?: Property.BorderTopRightRadius<string | number>;
  borderBottomLeftRadius?: Property.BorderBottomLeftRadius<string | number>;
  borderBottomRightRadius?: Property.BorderBottomRightRadius<string | number>;
  scrollMarginLeft?: Property.ScrollMarginLeft<string | number>;
  scrollMarginRight?: Property.ScrollMarginRight<string | number>;
  scrollPaddingLeft?: Property.ScrollPaddingLeft<string | number>;
  scrollPaddingRight?: Property.ScrollPaddingRight<string | number>;
  scrollSnapMarginLeft?: Property.ScrollMarginLeft<string | number>;
  scrollSnapMarginRight?: Property.ScrollMarginRight<string | number>;
  MozBorderLeftColors?: Property.MozBorderLeftColors;
  MozBorderRightColors?: Property.MozBorderRightColors;
  WebkitBorderTopLeftRadius?: Property.BorderTopLeftRadius<string | number>;
  WebkitBorderTopRightRadius?: Property.BorderTopRightRadius<string | number>;
  WebkitBorderBottomLeftRadius?: Property.BorderBottomLeftRadius<
    string | number
  >;
  WebkitBorderBottomRightRadius?: Property.BorderBottomRightRadius<
    string | number
  >;
}
