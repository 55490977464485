import { Box, Divider, IconButton, alpha, styled } from '@mui/material';
import { ThumbUp, ThumbUpOutlined } from '@mui/icons-material';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  Container: styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  }),
  ArticleContainer: styled(Box, {
    shouldForwardProp: shouldForwardProp(['hasImage']),
  })<{ hasImage?: boolean }>(({ theme, hasImage = false }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '40px',
    gap: '24px',
    borderRadius: hasImage ? '0px 0px 8px 8px' : '8px',
    backgroundColor: 'white',
    height: hasImage ? 'calc(100% - 340px)' : '100%',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
    },
  })),
  ArticleImage: styled('img')(({ theme }) => ({
    borderRadius: '8px 8px 0px 0px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: '340px',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      height: '226px',
    },
  })),
  ArticleTitle: styled('span')(({ theme }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 20, lg: 40 }),
    color: theme.palette.text.neutral90,
    lineHeight: 1,
    fontWeight: 500,
  })),
  ArticleDetails: styled('p', {
    shouldForwardProp: shouldForwardProp(['lines']),
  })<{ lines?: string }>(({ theme, lines = '5' }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 14, lg: 16 }),
    color: theme.palette.text.neutral70,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines,
    margin: 0,
    flex: 1,
  })),
  DetailsKey: styled('span')(({ theme }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 14, lg: 16 }),
    color: theme.palette.text.neutral50,
  })),
  DetailsValue: styled('span')(({ theme }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 14, lg: 16 }),
    color: theme.palette.text.neutral50,
    fontWeight: 500,
  })),
  Divider: styled(Divider)(({ theme }) => ({
    borderColor: theme.palette.text.neutral50,
    height: '20px',
  })),
  FlexContainer: styled(Box)({
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
  }),
  Tag: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemColor']),
  })<{ itemColor?: string }>(({ theme, itemColor }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 14, lg: 16 }),
    fontWeight: 400,
    lineHeight: 1,
    padding: '10px 16px',
    borderRadius: '4px',
    background: alpha(itemColor ?? theme.palette.primary.main, 0.08),
    color: itemColor ?? theme.palette.primary.main,
  })),
};

export const StyledHome = {
  ArticleContainer: styled(Box, {
    shouldForwardProp: shouldForwardProp(['hasImage']),
  })<{ hasImage?: boolean }>(({ theme, hasImage = false }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '56px 48px',
    gap: '32px',
    ...(hasImage && {
      ...theme.directional({
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
      }),
    }),
    ...(!hasImage && {
      borderRadius: '20px',
    }),
    backgroundColor: '#262043',
    [theme.breakpoints.down('lg')]: {
      borderRadius: hasImage ? '0px 0px 20px 20px' : '20px',
      alignItems: 'center',
      textAlign: 'center',
    },
    height: '100%',
  })),
  ArticleImage: styled('img')(({ theme }) => ({
    ...theme.directional({
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
    }),
    width: '330px',
    height: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      height: '224px',
      borderRadius: '20px 20px 0px 0px',
    },
    [theme.breakpoints.down('md')]: {
      height: '158px',
    },
  })),
  ArticleTitle: styled('span')(({ theme }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 24, lg: 40 }),
    color: theme.palette.text.neutral,
    fontWeight: 500,
  })),
  ArticleDetails: styled('p', {
    shouldForwardProp: shouldForwardProp(['lines']),
  })<{ lines?: string }>(({ theme, lines = '5' }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 16, lg: 16 }),
    color: theme.palette.text.neutral,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    textAlign: 'start',
    lineHeight: '30px',
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines,
    margin: 0,
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  })),
};

export const StyledContent = {
  ArticleTitle: styled('span')(({ theme }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 32, lg: 56 }),
    fontWeight: 'bold',
    color: theme.palette.text.neutral90,
  })),
  ContentContainer: styled(Box)({
    maxWidth: '850px',
    alignSelf: 'center',
  }),
  Content: styled(Box)(({ theme }) => ({
    '& p': {
      lineHeight: 2,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.sizes.custom({ lg: 20, sm: 18 }),
      color: theme.palette.text.neutral90,
    },
  })),
  ActionsContainer: styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
    justifyContent: 'end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  })),
  Actions: styled(Box)(({ theme }) => ({
    margin: '40px 0px',
    padding: '26px 32px',
    borderWidth: '1px 0px',
    borderStyle: 'solid',
    borderColor: theme.palette.text.neutral30,
    [theme.breakpoints.down('md')]: {
      margin: '30px 0px',
      padding: '26px 24px',
    },
  })),
  ActionButton: styled(IconButton)({
    '&:hover': {
      '& path': {
        fill: 'url(#hoverGradient)',
      },
    },
  }),
  LikeButton: styled(Box, {
    shouldForwardProp: shouldForwardProp(['liked']),
  })<{ liked?: boolean }>(({ theme, liked }) => ({
    '@keyframes like': {
      '0%': {
        rotate: '0deg',
      },
      '30%, 50%': {
        rotate: '-20deg',
      },
      '80%': {
        rotate: '10deg',
      },
      '100%': {
        rotate: '0deg',
      },
    },
    width: '32px',
    height: '32px',
    position: 'relative',
    transition: theme.transitions.create('rotate'),
    ...(liked && {
      animation: 'like 1s ease-in-out',
      '& path': {
        fill: 'url(#hoverGradient)',
      },
    }),
  })),
  Likes: styled('span')(({ theme }) => ({
    fontSize: theme.typography.sizes.custom({ sm: 16, lg: 16 }),
    color: theme.palette.text.neutral50,
    fontWeight: 500,
  })),
  LikeActive: styled(ThumbUp)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
  LikeInactive: styled(ThumbUpOutlined)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  }),
};

Styled.Divider.defaultProps = {
  orientation: 'vertical',
};

StyledContent.ActionButton.defaultProps = {
  color: 'neutral40',
  size: 'small',
};
