import { createBrowserRouter } from 'react-router-dom';
import {
  HomePage,
  AboutPage,
  ServicesPage,
  ArticlesPage,
  CareersPage,
  ArticleDetailsPage,
  AdminPage,
  LoginPage,
  AdminArticlePage,
  NotFoundPage,
} from '../components/pages';
import { Layout } from '../components/root';

export const createRouter = () =>
  createBrowserRouter([
    {
      element: <Layout />,
      children: [
        {
          path: '/admin',
          Component: AdminPage,
          children: [
            { path: '/admin/login', Component: LoginPage },
            { path: '/admin/article/:id?', Component: AdminArticlePage },
          ],
        },
        {
          path: '/',
          Component: HomePage,
        },
        {
          path: '/about',
          Component: AboutPage,
        },
        {
          path: '/services',
          Component: ServicesPage,
        },
        {
          path: '/careers',
          Component: CareersPage,
        },
        {
          path: '/articles',
          Component: ArticlesPage,
        },
        {
          path: '/article/:id',
          Component: ArticleDetailsPage,
        },
        {
          path: '*',
          Component: NotFoundPage,
        },
      ],
    },
  ]);
