export enum Lang {
  EN = 'en',
  AR = 'ar',
}

export enum ServerLang {
  EN = 'en-US',
  AR = 'ar-SA',
}

export const SUPPORTED_LANGUAGES = [
  { code: Lang.EN, name: 'English', shortName: 'Eng' },
  { code: Lang.AR, name: 'عربي', shortName: 'عربي' },
];
