import { useEffect, useState } from 'react';
import { Auth, User, getAuth, onAuthStateChanged } from 'firebase/auth';
import { useFirebaseApp } from '@userpath/utils';

export const useFirebaseAuth = (): {
  auth: Auth;
  isLoggedIn: boolean | undefined;
  isAnonymousLoggedIn: boolean | undefined;
  user: User | null;
  uid: string | undefined;
} => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState<User | null>(auth.currentUser);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsReady(true);
      setUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  return {
    auth,
    isLoggedIn: isReady ? user != null && !user.isAnonymous : undefined,
    isAnonymousLoggedIn: isReady ? user != null : undefined,
    user: user,
    uid: isReady ? user?.uid : undefined,
  };
};
