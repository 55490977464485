import React from 'react';
import { useTranslation } from 'react-i18next';
import { signOut } from 'firebase/auth';
import {
  Contrast,
  Public,
  TextDecrease,
  TextIncrease,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { useGlobalContext } from '@userpath/utils';
import { Lang, SUPPORTED_LANGUAGES } from '@userpath/localization';
import { CustomContainer, useFirebaseAuth } from '@userpath/components';
import { Styled } from './styles';

const AccessibilityHeader: React.FC = () => {
  const { auth, isLoggedIn } = useFirebaseAuth();
  const globalContext = useGlobalContext();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === Lang.AR;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.secondary,
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      })}
    >
      <CustomContainer
        sx={{
          paddingTop: '8px',
          paddingBottom: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          {isLoggedIn && (
            <Box flex={1}>
              <Styled.Button onClick={() => signOut(auth)}>
                {t('header.signOut')}
              </Styled.Button>
            </Box>
          )}
          <Styled.IconButton
            onClick={() =>
              globalContext.updateColorBlind(!globalContext.isColorBlindActive)
            }
          >
            <Contrast fontSize="size24" />
          </Styled.IconButton>
          <Styled.Separator />
          <Styled.IconButton
            disabled={globalContext.fontSize == 0}
            onClick={() => globalContext.decreaseFontSize()}
          >
            <TextDecrease fontSize="size24" />
          </Styled.IconButton>
          <Styled.Separator />
          <Styled.IconButton
            disabled={globalContext.fontSize == 10}
            onClick={() => globalContext.increaseFontSize()}
          >
            <TextIncrease fontSize="size24" />
          </Styled.IconButton>
          <Styled.Separator />
          <Styled.Button
            onClick={() =>
              i18n.changeLanguage(i18n.language == Lang.EN ? Lang.AR : Lang.EN)
            }
          >
            <Public fontSize="size24" />
            {isArabic
              ? SUPPORTED_LANGUAGES[0].name
              : SUPPORTED_LANGUAGES[1].name}
          </Styled.Button>
        </Box>
      </CustomContainer>
    </Box>
  );
};

export default AccessibilityHeader;
