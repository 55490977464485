import React, { useCallback } from 'react';
import { alpha } from '@mui/material';
import {
  Canvas,
  ICanvasDrawProps,
  ICanvasPrepareProps,
} from '@userpath/components';
import { Point, drawLine, addControlPoints } from '@userpath/utils';

interface IPreparedValue {
  line1: Point[];
  line2: Point[];
  line3: Point[] | undefined;
}

interface ILinesProps {
  viewAllRef: React.RefObject<HTMLDivElement>;
  contentRef: React.RefObject<HTMLDivElement>;
  buttonsRef: React.RefObject<HTMLDivElement>;
  aboutRef: React.RefObject<HTMLDivElement>;
  aboutContainerRef: React.RefObject<HTMLDivElement>;
  videoRef: React.RefObject<HTMLVideoElement>;
  videoContainerRef: React.RefObject<HTMLDivElement>;
  articlesRef: React.RefObject<HTMLDivElement>;
}

const Lines: React.FC<ILinesProps> = ({
  viewAllRef,
  contentRef,
  buttonsRef,
  aboutRef,
  aboutContainerRef,
  videoRef,
  videoContainerRef,
  articlesRef,
}) => {
  const prepare = useCallback(
    (props: ICanvasPrepareProps): IPreparedValue => {
      if (
        !viewAllRef.current ||
        !contentRef.current ||
        !buttonsRef.current ||
        !aboutRef.current ||
        !aboutContainerRef.current ||
        !videoRef.current ||
        !videoContainerRef.current ||
        !articlesRef.current
      ) {
        return { line1: [], line2: [], line3: undefined };
      }
      const line1 = getFirstLine(
        props,
        contentRef.current.offsetLeft +
          (props.isRTL ? contentRef.current.offsetWidth : 0),
        contentRef.current.offsetTop,
        contentRef.current.offsetHeight + 50,
        buttonsRef.current.offsetTop + buttonsRef.current.offsetHeight + 24,
        aboutRef.current.offsetTop,
      );
      const line2 = getSecondLine(
        props,
        aboutRef.current.offsetTop,
        Math.max((props.width - 1920) / 2, 0),
        aboutContainerRef.current.offsetTop +
          videoContainerRef.current.offsetTop,
        videoContainerRef.current.offsetLeft,
        videoRef.current,
      );
      const line3 = getThirdLine(
        props,
        aboutRef.current.offsetTop + aboutContainerRef.current.offsetTop,
        articlesRef.current,
      );
      return {
        line1: line1,
        line2: line2,
        line3: line3,
      };
    },
    [
      viewAllRef,
      contentRef,
      buttonsRef,
      aboutRef,
      aboutContainerRef,
      videoRef,
      videoContainerRef,
      articlesRef,
    ],
  );
  const draw = useCallback(
    ({
      context: ctx,
      theme,
      preparedValue,
    }: ICanvasDrawProps<IPreparedValue>) => {
      if (preparedValue == undefined) return true;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.lineWidth = 8;
      //   ctx.lineCap = 'round';
      ctx.strokeStyle = theme.palette.text.neutral10;
      drawLine(ctx, preparedValue.line1);
      if (preparedValue.line3) {
        ctx.strokeStyle = alpha(
          theme.palette.primary.secondary10 ?? theme.palette.text.neutral10,
          0.17,
        );
        drawLine(ctx, preparedValue.line3);
      }

      const stops = [0, 0.4107, 0.7833, 1];
      const colors = [
        theme.palette.secondary.secondary,
        theme.palette.secondary.main,
        theme.palette.primary.secondary,
        theme.palette.primary.main,
      ];

      const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
      for (let i = 0; i < colors.length; i++) {
        gradient.addColorStop(stops[i], colors[i]);
      }
      ctx.strokeStyle = gradient;
      drawLine(ctx, preparedValue.line2);

      return false;
    },
    [],
  );
  return (
    <Canvas<IPreparedValue>
      style={{
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -2,
      }}
      prepare={prepare}
      draw={draw}
    />
  );
};

const getFirstLine = (
  props: ICanvasPrepareProps,
  contentStart: number,
  contentTop: number,
  contentHeight: number,
  buttonsTop: number,
  aboutTop: number,
): Point[] => {
  const { width, isMobile, isRTL } = props;
  if (contentStart == 0) return [];
  const p1 = contentStart + (isRTL ? -4 : 4);
  if (isMobile) {
    const st = 50;
    return addControlPoints([
      { x: Math.min(-15, p1 - 80), y: st },
      { x: p1, y: st, radius: 80 },
      { x: p1, y: buttonsTop, radius: 80 },
      { x: isRTL ? 24 : width - 24, y: buttonsTop, radius: 80 },
      { x: isRTL ? 24 : width - 24, y: aboutTop },
    ]);
  } else {
    const st = 100;
    return addControlPoints([
      { x: isRTL ? Math.max(width, p1 + 170) : Math.min(-15, p1 - 170), y: st },
      { x: p1, y: st, radius: 170 },
      { x: p1, y: contentTop + contentHeight + 50, radius: 170 },
      {
        x: isRTL ? 182 : width - 182,
        y: contentTop + contentHeight + 50,
        radius: 170,
      },
      { x: isRTL ? 182 : width - 182, y: aboutTop },
    ]);
  }
};

const getSecondLine = (
  props: ICanvasPrepareProps,
  aboutTop: number,
  aboutLeft: number,
  videoContainerTop: number,
  videoContainerLeft: number,
  videoRef: HTMLVideoElement,
): Point[] => {
  const { height, width, isMobile, isRTL } = props;
  if (height == 0) return [];
  const top = aboutTop + videoContainerTop + videoRef.offsetTop;
  const left = aboutLeft + videoRef.offsetLeft + videoContainerLeft;
  const videoWidth = videoRef.offsetWidth;
  const videoHeight = videoRef.offsetHeight;
  if (isMobile) {
    const radius = 135;
    return addControlPoints([
      { x: isRTL ? 24 : width - 24, y: aboutTop },
      { x: isRTL ? 24 : width - 24, y: top, radius: 80 },
      { x: videoWidth + left - (isRTL ? radius : 0), y: top },
      { x: isRTL ? videoWidth + left : left, y: top, radius: radius },
      {
        x: isRTL ? videoWidth + left : left,
        y: videoHeight + top,
        radius: radius,
      },
      {
        x: isRTL ? left : videoWidth + left,
        y: videoHeight + top,
        radius: radius,
      },
      { x: isRTL ? left : videoWidth + left, y: aboutTop - 150, radius: 80 },
      { x: isRTL ? width : 0, y: aboutTop - 150 },
    ]);
  } else {
    const radius = 312;
    return addControlPoints([
      { x: isRTL ? 182 : width - 182, y: aboutTop },
      { x: isRTL ? 182 : width - 182, y: top, radius: 170 },
      { x: isRTL ? left : videoWidth + left, y: top },
      { x: isRTL ? videoWidth + left : left, y: top, radius: radius },
      {
        x: isRTL ? videoWidth + left : left,
        y: videoHeight + top,
        radius: radius,
      },
      {
        x: isRTL ? left : videoWidth + left,
        y: videoHeight + top,
        radius: radius,
      },
      { x: isRTL ? left : videoWidth + left, y: aboutTop - 150, radius: 170 },
      { x: isRTL ? width : 0, y: aboutTop - 150 },
    ]);
  }
};

const getThirdLine = (
  props: ICanvasPrepareProps,
  aboutTop: number,
  articlesRef: HTMLDivElement,
): Point[] | undefined => {
  const { height, width, isMobile, isRTL } = props;
  if (height == 0) return [];
  const top = aboutTop + articlesRef.offsetTop + articlesRef.offsetHeight * 0.6;
  if (!isMobile) {
    return addControlPoints([
      { x: isRTL ? width : 0, y: top },
      { x: isRTL ? width - 300 : 300, y: top, radius: 80 },
      { x: isRTL ? width - 300 : 300, y: height - 100, radius: 80 },
      { x: isRTL ? 86 : width - 86, y: height - 100, radius: 80 },
      { x: isRTL ? 86 : width - 86, y: height, radius: 80 },
    ]);
  } else {
    return undefined;
  }
};

export default Lines;
