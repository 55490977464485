import { styled } from '@mui/material';
import { CustomContainer } from '@userpath/components';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  BGContainer: styled('div', {
    shouldForwardProp: shouldForwardProp(['path']),
  })<{ path: string }>(({ theme, path }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      zIndex: -3,
      background: theme.palette.background.primary,
      position: 'absolute',
      top: '300px',
      left: 0,
      width: '100%',
      height: 'max(calc(100% - 300px), 0px)',
      clipPath: `path("${path}")`,
    },
    [theme.breakpoints.down('md')]: {
      '&::before': {
        top: '150px',
        height: 'max(calc(100% - 150px), 0px)',
      },
    },
  })),
  Container: styled(CustomContainer)(({ theme }) => ({
    marginTop: '104px',
    paddingBottom: '170px',
    display: 'flex',
    flexDirection: 'column',
    gap: '190px',
    [theme.breakpoints.down('md')]: {
      marginTop: '50px',
      gap: '210px',
    },
  })),
};
