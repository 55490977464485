import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { ArticleDTO } from '@userpath/types';
import { Lang } from '@userpath/localization';
import { Library } from '@userpath/constants';
import { getLocalized } from '@userpath/utils';
import { StyledHome } from './styles';

interface IHomeArticleCardProps {
  article: ArticleDTO;
  disableInteractions?: boolean;
}

const HomeArticleCard: React.FC<IHomeArticleCardProps> = ({
  article,
  disableInteractions,
}) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const isArabic = i18n.language == Lang.AR;
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const Icon = useMemo(() => (isArabic ? ArrowBack : ArrowForward), [isArabic]);
  const image = useMemo(
    () =>
      getLocalized(isMobile, article.horizontalImage, article.verticalImage),
    [article.horizontalImage, article.verticalImage, isMobile],
  );
  const getValue = useCallback(
    (english?: string, arabic?: string) =>
      getLocalized(isArabic, english, arabic),
    [isArabic],
  );
  const title = useMemo(
    () => getValue(article.titleEnglish, article.titleArabic),
    [getValue, article.titleArabic, article.titleEnglish],
  );
  const description = useMemo(
    () => getValue(article.descriptionEnglish, article.descriptionArabic),
    [getValue, article.descriptionArabic, article.descriptionEnglish],
  );
  return (
    <Grid
      container
      height="100%"
      direction={{ xs: 'column-reverse', lg: 'row' }}
    >
      <Grid item xs flex={1}>
        <StyledHome.ArticleContainer hasImage={image != undefined}>
          <StyledHome.ArticleTitle>{title}</StyledHome.ArticleTitle>
          <StyledHome.ArticleDetails lines={image ? '5' : '10'}>
            {description}
          </StyledHome.ArticleDetails>
          <Button
            variant="outlined"
            color="secondaryLight"
            sx={{
              borderRadius: '4px',
              '& .MuiButton-endIcon': {
                marginRight: isArabic ? '8px' : '-4px',
                marginLeft: isArabic ? '-4px' : '8px',
              },
            }}
            endIcon={<Icon />}
            onClick={
              disableInteractions
                ? undefined
                : () => {
                    navigate(`/article/${article.id}`);
                  }
            }
          >
            {t('home.readMore')}
          </Button>
        </StyledHome.ArticleContainer>
      </Grid>
      <Grid item display={image ? 'inherit' : 'none'}>
        <StyledHome.ArticleImage src={image} />
      </Grid>
    </Grid>
  );
};

export default HomeArticleCard;
