import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { CallCalling } from 'iconsax-react';
import { CSSTransition } from 'react-transition-group';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { ContactDialog, useElementSize } from '@userpath/components';
import { HeaderDrawer } from '../header-drawer';
import { Styled } from './styles';
import { HomeHeader, Lines } from './components';

const headerItems = [
  { title: 'header.home', path: '/' },
  { title: 'header.about', path: '/about' },
  { title: 'header.services', path: '/services' },
  { title: 'header.articles', path: '/articles' },
  { title: 'header.careers', path: '/careers' },
];

const Header: FC = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname == '/';
  const [mobileOpen, setMobileOpen] = useState(false);
  const [setRef, size] = useElementSize();
  const path = useMemo(() => {
    const v = size.width * (size.width < 650 ? 0.09 : 0.047);
    const st = size.height - v;
    const extra = size.height + v;
    const curve = `0 ${st} Q ${size.width * 0.5} ${extra} ${size.width} ${st}`;
    return `M 0 0 L ${curve} L ${size.width} ${st} L ${size.width} 0 Z`;
  }, [size.width, size.height]);
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Styled.ClipContainer ref={setRef} path={path}>
        <Styled.AppBar>
          <Styled.AppBarContent maxWidth="xl">
            <Styled.Logo onClick={() => navigate('/')} />
            {headerItems.map((item, i) => (
              <Styled.AppBarButton
                key={i}
                isActive={location.pathname == item.path}
                onClick={() => navigate(item.path)}
              >
                {t(item.title)}
              </Styled.AppBarButton>
            ))}
            <Box sx={{ flexGrow: 1 }} />
            <Styled.ContactButton onClick={handleClickOpen}>
              <Stack alignItems="center" direction="row" gap={2}>
                <CallCalling variant="Bulk" style={{ minWidth: '24px' }} />
                <Styled.ContactText>{t('header.contact')}</Styled.ContactText>
              </Stack>
            </Styled.ContactButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <Menu />
            </IconButton>
          </Styled.AppBarContent>
        </Styled.AppBar>
        <Styled.Container>
          {/* <Styled.Curve
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 392 38"
          preserveAspectRatio="none"
        >
          <Styled.CurvePath d="M392 38V0C327.771 25.3133 261.965 37.9799 196.156 38H392ZM195.843 38H0V0C64.2286 25.3133 130.035 37.9799 195.843 38Z" />
        </Styled.Curve> */}
          <Styled.LayerEffect />
          <CSSTransition
            in={isHomePage}
            timeout={300}
            unmountOnExit
            classNames={{
              enter: 'fade-enter',
              enterActive: 'fade-enter-active',
              exit: 'fade-exit',
              exitActive: 'fade-exit-active',
            }}
          >
            <Styled.TransitionContent>
              <Styled.LayerEffect>
                <Styled.Circle />
                <Lines />
              </Styled.LayerEffect>
              <HomeHeader />
            </Styled.TransitionContent>
          </CSSTransition>
        </Styled.Container>
        <nav>
          <Styled.Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <HeaderDrawer
              toggleDrawer={handleDrawerToggle}
              items={headerItems}
            />
          </Styled.Drawer>
        </nav>
        <ContactDialog isOpen={open} handleClose={handleClose} />
      </Styled.ClipContainer>
      {isHomePage && (
        <Styled.ContactFAB onClick={handleClickOpen}>
          <CallCalling variant="Bulk" color="white" />
        </Styled.ContactFAB>
      )}
    </>
  );
};

export default Header;
