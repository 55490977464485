import { useCallback, useReducer } from 'react';
import { Action, apiReducer, initialAPIState } from '@userpath/utils';
import { useArticles } from '@userpath/services';
import { AppError, FullArticleDTO } from '@userpath/types';

export const useArticleLoader = () => {
  const articlesServices = useArticles();
  const [{ data, loading, error }, dispatchArticles] = useReducer(
    apiReducer<FullArticleDTO, AppError>(),
    initialAPIState,
  );
  const loadArticle = useCallback(
    async (id: string) => {
      dispatchArticles({ type: Action.loading });
      try {
        const article = await articlesServices.getArticle(id);
        if (article == undefined) {
          dispatchArticles({
            type: Action.error,
            error: new AppError('errors.notFound'),
          });
          return;
        }
        const hasEnglishContent =
          article.hasEnglishContent == true || article.hasArabicContent != true;
        const hasArabicContent =
          article.hasArabicContent == true || article.hasEnglishContent != true;
        const englishContent = hasEnglishContent
          ? await articlesServices.getArticleContent(id, 'en')
          : undefined;
        const arabicContent = hasArabicContent
          ? await articlesServices.getArticleContent(id, 'ar')
          : undefined;
        // const likes = await articlesServices.getArticleLikes(id);
        const result: FullArticleDTO = {
          ...article,
          contentEnglish: englishContent,
          contentArabic: arabicContent,
          // likes: likes,
        };
        dispatchArticles({
          type: Action.success,
          payload: result,
        });
        return result;
      } catch (error) {
        dispatchArticles({
          type: Action.error,
          error: AppError.fromError(error),
        });
      }
    },
    [articlesServices],
  );

  return {
    data,
    loading,
    error,
    loadArticle,
  };
};
