import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Library } from '@userpath/constants';

import {
  CanvasLines,
  CustomContainer,
  GradientTypography,
  useElementInView,
} from '@userpath/components';
import { Styled } from './styles';
import { InfoSection } from './components';

const AboutPage: React.FC = () => {
  const { t } = useTranslation(Library.userpath);
  const { inView, assignRef } = useElementInView({
    disconnectOnceVisible: true,
  });
  return (
    <React.Fragment>
      <CanvasLines
        flipPointsOnRTL={false}
        getPoints={({ width, height, isMobile }) => {
          const st = isMobile ? height - 200 : Math.min(height - 200, 104);
          const p1 = isMobile ? width - 40 : width - 70;
          const extraX = isMobile ? 100 : 148;
          const extraY = isMobile ? 70 : 100;
          return [
            { x: width, y: st },
            { x: p1, y: st, radius: 70 },
            { x: p1, y: height + 100 },
            { x: p1 - extraX, y: height + 100 },
            { x: p1 - extraX, y: height - extraY, radius: 70 },
            { x: width, y: height - extraY },
          ];
        }}
      >
        <CustomContainer
          sx={{
            paddingTop: { xs: '50px', md: '104px' },
            paddingBottom: '80px',
          }}
        >
          <Stack textAlign="center" alignItems="center">
            <GradientTypography variant="h1" gradient="primary" animated>
              {t('about.title')}
            </GradientTypography>
            <Typography
              variant="h5"
              sx={(theme) => ({
                margin: { md: '0px 64px', lg: '0px 124px', xl: '0px 194px' },
                marginTop: '32px',
                lineHeight: 1.5,
                maxWidth: '1024px',
                color: theme.palette.text.neutral80,
              })}
            >
              <Trans
                ns={Library.userpath}
                i18nKey="about.details"
                components={{ 1: <br /> }}
              />
            </Typography>
          </Stack>
        </CustomContainer>
      </CanvasLines>
      <CanvasLines
        flipPointsOnRTL={false}
        animationDuration={3000}
        paddingBottom={{ xs: '36px', md: '70px' }}
        sx={(theme) => ({
          '&::before': {
            content: '""',
            zIndex: -4,
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            background: theme.palette.background.paper,
          },
        })}
        lineColor={({ theme, context: ctx, isMobile }) => {
          const gradient = ctx.createLinearGradient(
            isMobile ? 0 : ctx.canvas.width * 0.25 + 42,
            0,
            isMobile ? ctx.canvas.width - 170 : ctx.canvas.width - 218,
            0,
          );
          const stops = [0.0, 0.41, 0.64, 1.0];
          const colors = [
            theme.palette.secondary.secondary,
            theme.palette.secondary.main,
            theme.palette.primary.secondary,
            theme.palette.primary.main,
          ];
          for (let i = 0; i < colors.length; i++) {
            gradient.addColorStop(stops[i], colors[i]);
          }
          return gradient;
        }}
        getPoints={({ width, breakpoints }) => {
          const isMobile = !breakpoints.isMD;
          const p1 = isMobile ? width - 40 : width - 70;
          const extraX = isMobile ? 100 : 148;
          if (isMobile) {
            return [
              { x: p1 - extraX, y: 0 },
              { x: p1 - extraX, y: 100, radius: 70 },
              { x: 0, y: 100 },
            ];
          } else {
            const top = 800 * 0.495;
            return [
              { x: p1 - extraX, y: 0 },
              { x: p1 - extraX, y: top, radius: 130 },
              { x: width * 0.25 + 42, y: top },
            ];
          }
        }}
      >
        <Styled.MapContainer ref={assignRef}>
          {inView && (
            <React.Fragment>
              <Styled.Located>{t('about.located')}</Styled.Located>
              <Styled.Location>{t('about.location')}</Styled.Location>
            </React.Fragment>
          )}
          <Styled.MapHeader />
          <Styled.Map />
        </Styled.MapContainer>
      </CanvasLines>
      <InfoSection />
    </React.Fragment>
  );
};

export default AboutPage;
