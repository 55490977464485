import React, { forwardRef, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ArrowLeft, ArrowRight } from 'iconsax-react';
import {
  PartialWithFieldValue,
  serverTimestamp,
} from 'firebase/firestore/lite';
import { type ResponsiveStyleValue } from '@mui/system';
import {
  Button,
  Container,
  ContainerProps,
  Grid,
  GridSpacing,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  GradientTypography,
  yupResolver,
  InputField,
  useElementSize,
  PhoneInput,
  toggleLoader,
  useFirebaseAuth,
} from '@userpath/components';
import { Lang } from '@userpath/localization';
import { useContactForms } from '@userpath/services';
import { AppError, ContactFormDTO } from '@userpath/types';
import { useToastContext } from '@userpath/utils';
import { Styled } from './styles';
import {
  IContactFormInputs,
  validationSchema,
  defaultValues,
} from './constants';

type ContactFormVariant = 'dialog' | 'services';

interface IContactFormProps {
  variant?: ContactFormVariant;
  withBackground?: boolean;
  closeButton?: () => void;
  formRef?: React.Ref<HTMLDivElement>;
  rowSpacing?: ResponsiveStyleValue<GridSpacing>;
}

const ContactForm = forwardRef<
  HTMLDivElement,
  IContactFormProps & Omit<ContainerProps, 'ref'>
>(
  (
    {
      variant = 'dialog',
      withBackground = false,
      closeButton,
      formRef,
      rowSpacing,
      ...props
    },
    ref,
  ) => {
    const resolver = yupResolver(validationSchema);
    const { addToast } = useToastContext();
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === Lang.AR;
    const { handleSubmit, control, reset, setValue } =
      useForm<IContactFormInputs>({
        resolver,
        defaultValues: defaultValues,
      });
    const [setRef, size] = useElementSize();
    const forms = useContactForms();
    const { uid } = useFirebaseAuth();

    const onSubmit = useCallback(
      async (data: IContactFormInputs) => {
        toggleLoader(true);
        try {
          const form: PartialWithFieldValue<ContactFormDTO> = {
            company: data.company,
            email: data.email,
            message: data.message,
            name: data.name,
            phone: data.phone,
            createdDate: serverTimestamp(),
            uid: uid,
          };
          await forms.saveContactForm(forms.generateId(), form);
          addToast({
            message: t('contact.sentSuccessfully'),
          });
          reset();
          closeButton?.();
        } catch (error) {
          addToast({
            message: AppError.fromError(error).translate(t),
            type: 'error',
          });
        } finally {
          toggleLoader(false);
        }
      },
      [addToast, closeButton, forms, reset, t, uid],
    );
    return (
      <Container
        maxWidth="xl"
        ref={withBackground ? setRef : undefined}
        {...props}
      >
        <Styled.Container
          ref={ref}
          width={size.width}
          height={size.height}
          withBackground={withBackground}
          sx={{
            ...(withBackground && {
              paddingLeft: { md: '48px' },
              paddingRight: { md: '48px' },
            }),
          }}
        >
          {withBackground && (
            <React.Fragment>
              <Styled.Circle1 width={size.width} height={size.height} />
              <Styled.Circle2 width={size.width} height={size.height} />
              <Styled.Circle3 width={size.width} height={size.height} />
            </React.Fragment>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              columnSpacing={{ md: '48px', lg: '124px' }}
              rowSpacing={rowSpacing ?? { xs: '56px' }}
              alignItems="flex-start"
            >
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {variant == 'dialog' && (
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="body2">
                      {t('contact.letsTalk')}
                    </Typography>
                    {closeButton && (
                      <IconButton
                        edge="start"
                        color="secondaryLight"
                        onClick={closeButton}
                        aria-label="close"
                        size="small"
                        sx={{ display: { xs: 'block', md: 'none' } }}
                      >
                        <Close />
                      </IconButton>
                    )}
                  </Stack>
                )}
                {variant == 'services' && (
                  <Typography variant="body2">
                    {t('contact.notSure')}
                  </Typography>
                )}
                <Typography variant="h5" sx={{ marginTop: 4 }}>
                  <Trans
                    i18nKey="contact.letsTalkInfo"
                    components={{
                      1: (
                        <GradientTypography
                          variant="h5"
                          component="span"
                          gradient="variant2"
                          sx={(theme) => ({
                            backgroundSize: '600px',
                            [theme.breakpoints.down('md')]: {
                              backgroundSize: '400px',
                            },
                          })}
                        />
                      ),
                      2: <a />,
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} ref={formRef}>
                <Grid container spacing="24px">
                  <Grid item xs={12} sm={6}>
                    <InputField<IContactFormInputs>
                      name="name"
                      label={t('contact.name')}
                      placeholder={t('contact.writeHere')}
                      control={control}
                      variantColor={
                        withBackground ? 'red_background' : 'dark_background'
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField<IContactFormInputs>
                      name="email"
                      label={t('contact.email')}
                      type="email"
                      inputMode="email"
                      placeholder={t('contact.writeHere')}
                      control={control}
                      variantColor={
                        withBackground ? 'red_background' : 'dark_background'
                      }
                      autoComplete="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* TODO switch to real phone input */}
                    <PhoneInput<IContactFormInputs>
                      name="phone_formatted"
                      label={t('contact.phone')}
                      placeholder={t('contact.writeHere')}
                      control={control}
                      variantColor={
                        withBackground ? 'red_background' : 'dark_background'
                      }
                      labelProps={{ optional: true }}
                      fullWidth
                      onPhoneChanged={(phone) => {
                        setValue('phone', phone);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField<IContactFormInputs>
                      name="company"
                      label={t('contact.company')}
                      placeholder={t('contact.writeHere')}
                      control={control}
                      variantColor={
                        withBackground ? 'red_background' : 'dark_background'
                      }
                      labelProps={{ optional: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField<IContactFormInputs>
                      name="message"
                      label={t('contact.message')}
                      placeholder={t('contact.messageInfo')}
                      control={control}
                      variantColor={
                        withBackground ? 'red_background' : 'dark_background'
                      }
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      color="secondaryLight"
                      variant="contained"
                      endIcon={isArabic ? <ArrowLeft /> : <ArrowRight />}
                      sx={{
                        '& .MuiButton-endIcon': {
                          marginRight: isArabic ? '8px' : '-4px',
                          marginLeft: isArabic ? '-4px' : '8px',
                        },
                      }}
                    >
                      {t('contact.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Styled.Container>
      </Container>
    );
  },
);

export default ContactForm;
