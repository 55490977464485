import { Typography, styled } from '@mui/material';
import { CustomContainer } from '@userpath/components';

export const Styled = {
  Container: styled(CustomContainer)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '40px',
    paddingTop: '152px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '72px',
      gap: '16px',
    },
  })),
  Title: styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.neutral90,
  })),
  Details: styled(Typography)(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.neutral70,
    textAlign: 'center',
  })),
};
