import React from 'react';
import { alpha } from '@mui/material';
import {
  Canvas,
  ICanvasDrawProps,
  ICanvasPrepareProps,
} from '@userpath/components';
import {
  Point,
  drawLine,
  addControlPoints,
  Interval,
  computeLineIntervals,
  normalizedTimestamp,
  drawAnimatedLine,
  repeatingTimestamp,
  lerpGradient,
  flipPoints,
} from '@userpath/utils';

interface IPreparedValue {
  line1: Point[];
  line2: Point[];
  line2Intervals: Interval[];
}

const prepare = ({
  width,
  height,
  isMobile,
  isRTL,
}: ICanvasPrepareProps): IPreparedValue => {
  let line1: Point[];
  let line2: Point[];
  if (isMobile) {
    const w = width * 0.8;
    const st = 256;
    line1 = addControlPoints([
      { x: 0, y: st },
      { x: w, y: st, radius: 70 },
      { x: w, y: height },
    ]);
    line2 = addControlPoints([
      { x: Math.max(w + 194, width), y: 36 },
      { x: w + 50, y: 36, radius: 88 },
      { x: w + 50, y: st + 210, radius: 88 },
      { x: 0, y: st + 210 },
    ]);
  } else {
    const w = width * 0.5;
    const st = 382;
    line1 = addControlPoints([
      { x: 0, y: st },
      { x: w, y: st, radius: 170 },
      { x: w, y: height },
    ]);
    line2 = addControlPoints([
      { x: Math.max(w + 194, width), y: 82 },
      {
        x: Math.min(w + 170, width * 0.6),
        y: 82,
        radius: 170,
      },
      {
        x: Math.min(w + 170, width * 0.6),
        y: st + 210,
        radius: 170,
      },
      { x: 0, y: st + 210 },
    ]);
  }
  return {
    line1: isRTL ? flipPoints(line1, width) : line1,
    line2: isRTL ? flipPoints(line2, width) : line2,
    line2Intervals: computeLineIntervals(line2),
  };
};

const draw = ({
  context: ctx,
  theme,
  preparedValue,
  timestamp,
}: ICanvasDrawProps<IPreparedValue>) => {
  if (preparedValue == undefined) return true;
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.lineWidth = 8;
  ctx.lineCap = 'round';
  ctx.strokeStyle = alpha(theme.palette.text.neutral10, 0.08);
  drawLine(ctx, preparedValue.line1);

  const line2T = normalizedTimestamp(500, 3000, timestamp);
  if (line2T == 0) return true;
  const stops = [0, 0.4107, 0.7833, 1];
  const colors = [
    theme.palette.secondary.secondary,
    theme.palette.secondary.main,
    theme.palette.primary.secondary,
    theme.palette.primary.main,
  ];

  if (line2T < 1) {
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    for (let i = 0; i < colors.length; i++) {
      gradient.addColorStop(stops[i], colors[i]);
    }
    ctx.strokeStyle = gradient;
    drawAnimatedLine(
      ctx,
      preparedValue.line2,
      preparedValue.line2Intervals,
      line2T,
    );
  } else {
    const t = repeatingTimestamp(timestamp, 4000, 3000);
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    const newColors = lerpGradient(colors, t);
    for (let i = 0; i < newColors.length; i++) {
      gradient.addColorStop(stops[i], newColors[i]);
    }
    ctx.strokeStyle = gradient;
    drawLine(ctx, preparedValue.line2);
  }

  return true;
};

const Lines: React.FC = () => {
  return (
    <Canvas<IPreparedValue>
      withAnimation
      style={{
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
      prepare={prepare}
      draw={draw}
    />
  );
};

export default Lines;
