import { Box, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

interface IContainerProps {
  withBackground?: boolean;
  width: number;
  height: number;
}
const Circle = styled('div', {
  shouldForwardProp: shouldForwardProp(['width', 'height']),
})<{
  width: number;
  height: number;
}>(({ theme, width, height }) => ({
  zIndex: -1,
  position: 'absolute',
  borderRadius: '50%',
  [theme.breakpoints.up('md')]: {
    transform: 'rotate(90deg)',
    width: `${height + 100}px`,
    height: `${height + 100}px`,
    top: '-50px',
    bottom: '-50px',
  },
  [theme.breakpoints.down('md')]: {
    width: `${width - 100}px`,
    height: `${width - 100}px`,
    left: '50px',
    right: '50px',
  },
  opacity: 'var(--opacity, 0)',
  transition: theme.transitions.create(['opacity', 'left', 'top'], {
    duration: '0.6s',
    easing: 'cubic-bezier(0.175, 0.885, 0.32, 1.4)',
  }),
  background: `linear-gradient(73.8deg,
    ${theme.palette.secondary.secondary} -40.38%,
    ${theme.palette.secondary.main} 23.35%,
    ${theme.palette.primary.secondary} 59.1%,
    ${theme.palette.primary.main} 115.05%)`,
}));

export const Styled = {
  Container: styled(Box, {
    shouldForwardProp: shouldForwardProp(['withBackground', 'width', 'height']),
  })<IContainerProps>(({ withBackground = false, theme, width, height }) => ({
    color: 'white',
    ...(withBackground && {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '32px',
      padding: '40px 24px',
      '--opacity': 0.3,
      '--circle1': `-${height * 0.6}px`,
      '--circle2': `${width - (height + 100)}px`,
      '--circle3': `-${height + 60}px`,
      [theme.breakpoints.down('md')]: {
        '--circle1': `${height - 60}px`,
        '--circle2': `-${width - 140}px`,
        '--circle3': `${height - 20}px`,
      },
      '&::after': {
        content: '""',
        borderRadius: '32px',
        zIndex: -2,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        opacity: 0.7,
        transition: theme.transitions.create('opacity'),
        background: `linear-gradient(166.28deg,
        ${theme.palette.secondary.secondary} -68.75%,
        ${theme.palette.secondary.main} 5.8%,
        ${theme.palette.primary.secondary} 47.61%,
        ${theme.palette.primary.main} 113.07%)`,
      },
      '&:hover::after, &:focus-within::after': {
        opacity: 1,
      },
      '&:hover, &:focus-within': {
        '--opacity': 1,
        '--circle1': `-100px`,
        '--circle2': '100px',
        '--circle3': `-${height - 40}px`,
        [theme.breakpoints.down('md')]: {
          '--opacity': 0.4,
          '--circle1': `${height - 160}px`,
          '--circle2': `${height / 2}px`,
          '--circle3': `${height - 120}px`,
        },
      },
    }),
  })),
  Dialog: styled(Box)(({ theme }) => ({
    padding: '104px',
    minHeight: '100vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.linear1Start,
    scrollbarColor: `${theme.palette.text.neutral}  #0000`,
    [theme.breakpoints.down('md')]: {
      padding: '40px',
    },
  })),
  Circle1: styled(Circle)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      left: 'var(--circle1)',
    },
    [theme.breakpoints.down('md')]: {
      top: 'var(--circle1)',
    },
  })),
  Circle2: styled(Circle)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      left: 'var(--circle2)',
    },
    [theme.breakpoints.down('md')]: {
      top: 'var(--circle2)',
    },
  })),
  Circle3: styled(Circle)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      left: 'var(--circle3)',
    },
    [theme.breakpoints.down('md')]: {
      top: 'var(--circle3)',
    },
  })),
};
