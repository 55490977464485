import * as Yup from 'yup';
import { FullArticleDTO } from '@userpath/types';

const eitherOf = (other: string) =>
  Yup.string().when(other, {
    is: '',
    then: (schema) => schema.required('errors.fieldRequired'),
    otherwise: (schema) => schema,
  });

export interface IArticleInputs {
  id?: string;
  title_english?: string;
  title_arabic?: string;
  description_english?: string;
  description_arabic?: string;
  writer_english?: string;
  writer_arabic?: string;
  content_english?: string;
  content_arabic?: string;
  reading_time?: number;
  image_desktop?: File | string;
  image_mobile?: File | string;
  tags?: ITagInput[];
}

export interface ITagInput {
  title_english?: string;
  title_arabic?: string;
  color?: string;
}
export const defaultValues: IArticleInputs = {
  title_english: '',
  title_arabic: '',
  description_english: '',
  description_arabic: '',
  writer_english: '',
  writer_arabic: '',
  content_english: '',
  content_arabic: '',
  reading_time: 0,
  tags: [],
};

export const fromServer = (article: FullArticleDTO): IArticleInputs => {
  return {
    id: article.id,
    title_english: article.titleEnglish,
    title_arabic: article.titleArabic,
    image_desktop: article.verticalImage,
    image_mobile: article.horizontalImage,
    reading_time: article.readingTime,
    writer_english: article.writerEnglish,
    writer_arabic: article.writerArabic,
    description_english: article.descriptionEnglish,
    description_arabic: article.descriptionArabic,
    content_english: article.contentEnglish?.content,
    content_arabic: article.contentArabic?.content,
    tags: article.tags?.map((tag) => ({
      title_english: tag.titleEnglish,
      title_arabic: tag.titleArabic,
      color: tag.color,
    })),
  };
};

export const validationSchema: Yup.ObjectSchema<IArticleInputs> =
  Yup.object().shape(
    {
      id: Yup.string(),
      title_english: eitherOf('title_arabic'),
      title_arabic: eitherOf('title_english'),
      description_english: eitherOf('description_arabic'),
      description_arabic: eitherOf('description_english'),
      writer_english: eitherOf('writer_arabic'),
      writer_arabic: eitherOf('writer_english'),
      content_english: eitherOf('content_arabic'),
      content_arabic: eitherOf('content_english'),
      reading_time: Yup.number().optional(),
      image_desktop: Yup.mixed<File>().required('errors.fieldRequired'),
      image_mobile: Yup.mixed<File>().required('errors.fieldRequired'),
      tags: Yup.array(
        Yup.object().shape(
          {
            title_english: eitherOf('title_arabic'),
            title_arabic: eitherOf('title_english'),
          },
          [['title_arabic', 'title_english']],
        ),
      ),
    },
    [
      ['title_arabic', 'title_english'],
      ['description_arabic', 'description_english'],
      ['writer_arabic', 'writer_english'],
      ['content_arabic', 'content_english'],
    ],
  );
