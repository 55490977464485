import * as Yup from 'yup';

export interface ILoginInputs {
  email: string;
  password: string;
}

export const defaultValues = {
  email: '',
  password: '',
};

export const validationSchema: Yup.ObjectSchema<ILoginInputs> =
  Yup.object().shape({
    email: Yup.string()
      .email('errors.invalidEmail')
      .required('errors.fieldRequired'),
    password: Yup.string().required('errors.fieldRequired'),
  });
