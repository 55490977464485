import React, { useMemo } from 'react';
import { Box, BoxProps, Theme } from '@mui/material';
import {
  Interval,
  Point,
  addControlPoints,
  computeLineIntervals,
  drawAnimatedLine,
  drawLine,
  flipPoints,
  normalizedTimestamp,
} from '@userpath/utils';
import {
  BreakpointsResult,
  Canvas,
  ICanvasDrawProps,
  ICanvasPrepareProps,
} from '@userpath/components';

interface IPreparedValue {
  line: Point[];
  lineIntervals: Interval[];
}

interface ICanvasLinesProps {
  active?: boolean;
  flipPointsOnRTL?: boolean;
  animationDuration?: number;
  lineColor?:
    | string
    | ((props: {
        theme: Theme;
        context: CanvasRenderingContext2D;
        isMobile: boolean;
      }) => string | CanvasGradient);
  getPoints: (props: {
    width: number;
    height: number;
    isMobile: boolean;
    breakpoints: BreakpointsResult;
    isRTL: boolean;
  }) => Point[];
}

const CanvasLines: React.FC<BoxProps & ICanvasLinesProps> = ({
  children,
  active = true,
  flipPointsOnRTL = true,
  animationDuration = 0,
  lineColor,
  getPoints,
  ...props
}) => {
  const prepare = useMemo(
    () =>
      ({
        width,
        height,
        breakpoints,
        isMobile,
        isRTL,
      }: ICanvasPrepareProps): IPreparedValue => {
        const points = addControlPoints(
          getPoints({ width, height, isMobile, breakpoints, isRTL }),
        );
        return {
          line: isRTL && flipPointsOnRTL ? flipPoints(points, width) : points,
          lineIntervals:
            animationDuration > 0 ? computeLineIntervals(points) : [],
        };
      },
    [getPoints, flipPointsOnRTL, animationDuration],
  );
  const draw = useMemo(
    () =>
      ({
        context: ctx,
        theme,
        isMobile,
        isRTL,
        breakpoints,
        timestamp,
        preparedValue,
      }: ICanvasDrawProps<IPreparedValue>) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        if (!active) return false;
        ctx.lineWidth = 8;
        ctx.lineCap = 'round';
        if (preparedValue == undefined) {
          preparedValue = prepare({
            width: ctx.canvas.width,
            height: ctx.canvas.height,
            breakpoints,
            isMobile,
            isRTL,
          });
        }
        if (lineColor != undefined) {
          if (typeof lineColor === 'string') {
            ctx.strokeStyle = lineColor;
          } else {
            ctx.strokeStyle = lineColor({ theme, context: ctx, isMobile });
          }
        } else {
          ctx.strokeStyle = theme.palette.text.neutral10;
        }
        if (animationDuration > 0) {
          const t = normalizedTimestamp(500, animationDuration, timestamp);
          if (t == 0) return true;
          drawAnimatedLine(
            ctx,
            preparedValue.line,
            preparedValue.lineIntervals,
            t,
          );
          return t < 1;
        } else {
          drawLine(ctx, preparedValue.line);
        }

        return false;
      },
    [active, animationDuration, lineColor, prepare],
  );
  return (
    <Box position="relative" {...props}>
      <Canvas<IPreparedValue>
        style={{
          position: 'absolute',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
        }}
        withAnimation={animationDuration > 0}
        prepare={prepare}
        draw={draw}
      />
      {children}
    </Box>
  );
};

export default CanvasLines;
