import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';

export interface BreakpointsResult {
  currentBreakpoint: Breakpoint;
  isXS: boolean;
  isSM: boolean;
  isMD: boolean;
  isLG: boolean;
  isXL: boolean;
}
/**
 * useBreakpoint
 *
 * @returns
 */
export const useBreakpoints = (): BreakpointsResult => {
  const theme = useTheme();
  const mq_xl = useMediaQuery(theme.breakpoints.only('xl'));
  const mq_lg = useMediaQuery(theme.breakpoints.only('lg'));
  const mq_md = useMediaQuery(theme.breakpoints.only('md'));
  const mq_sm = useMediaQuery(theme.breakpoints.only('sm'));
  const mq_xs = useMediaQuery(theme.breakpoints.only('xs'));

  const getBreakPointName = (): Breakpoint => {
    if (mq_xl) {
      return 'xl';
    } else if (mq_lg) {
      return 'lg';
    } else if (mq_md) {
      return 'md';
    } else if (mq_sm) {
      return 'sm';
    } else {
      return 'xs';
    }
  };

  return {
    currentBreakpoint: getBreakPointName(),
    isXS: mq_xs || mq_sm || mq_md || mq_lg || mq_xl,
    isSM: mq_sm || mq_md || mq_lg || mq_xl,
    isMD: mq_md || mq_lg || mq_xl,
    isLG: mq_lg || mq_xl,
    isXL: mq_xl,
  };
};
