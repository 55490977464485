import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from '@mui/icons-material';
import { CustomContainer, useBreakpoints } from '@userpath/components';
import { Library } from '@userpath/constants';
import { Lang } from '@userpath/localization';
import Navigation from '../Navigation';
import ServiceItem from './ServiceItem';
import ScrollableList from './ScrollableList';
import { ServiceIndex, ServicesConstants } from './';

interface IServicesProps {
  viewAllRef?: React.MutableRefObject<HTMLDivElement | null>;
  contentRef?: React.Ref<HTMLDivElement>;
  buttonsRef?: React.Ref<HTMLDivElement>;
}

const Services = forwardRef<HTMLDivElement, IServicesProps>(
  ({ viewAllRef, contentRef, buttonsRef }, ref) => {
    const { t, i18n } = useTranslation(Library.userpath);
    const navigate = useNavigate();
    const currentPage = useRef<number>(0);
    const listRef = useRef<HTMLDivElement | null>();
    const viewAllDesktopRef = useRef<HTMLDivElement>(null);
    const viewAlMobileRef = useRef<HTMLDivElement>(null);
    const [isAtStart, setIsAtStart] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const breakpoints = useBreakpoints();
    const totalPages = breakpoints.isMD ? 2 : 3;
    const isArabic = i18n.language === Lang.AR;

    const updatePage = useCallback(
      (page: number) => {
        currentPage.current = page;
        setIsAtStart(page === 0);
        setIsAtEnd(page >= totalPages - 1);

        if (listRef.current) {
          listRef.current.style.setProperty('--list-page', page.toString());
        }
      },
      [totalPages],
    );
    const handleScrollUp = useCallback(() => {
      updatePage(Math.max(currentPage.current - 1, 0));
    }, [updatePage]);
    const handleScrollDown = useCallback(() => {
      updatePage(Math.min(currentPage.current + 1, totalPages - 1));
    }, [updatePage, totalPages]);

    useEffect(() => {
      if (currentPage.current > totalPages) {
        updatePage(totalPages);
      } else {
        setIsAtStart(currentPage.current === 0);
        setIsAtEnd(currentPage.current >= totalPages - 1);
      }
    }, [updatePage, totalPages]);

    useEffect(() => {
      const ref = breakpoints.isMD ? viewAllDesktopRef : viewAlMobileRef;
      if (viewAllRef) viewAllRef.current = ref.current;
    }, [breakpoints.isMD, viewAllRef]);

    return (
      <CustomContainer
        sx={{
          paddingTop: { xs: '138px', md: '248px' },
          paddingBottom: { xs: '100px', md: '248px' },
        }}
      >
        <Grid
          container
          ref={ref}
          alignItems="center"
          justifyContent="center"
          gap="24px"
        >
          <Grid item xs={12} md="auto">
            <Typography variant="h3" textAlign="center">
              {t('home.services')}
            </Typography>
          </Grid>
          <Grid item xs={12} md flex={1} ref={contentRef}>
            <ScrollableList ref={(r) => (listRef.current = r)}>
              {(Object.keys(ServicesConstants.Icons) as ServiceIndex[]).map(
                (item, i) => (
                  <ServiceItem key={i} index={item} />
                ),
              )}
            </ScrollableList>
            <Box
              ref={viewAllDesktopRef}
              sx={(theme) => ({
                marginTop: '48px',
                ...theme.directional({
                  paddingLeft: '120px',
                }),
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              })}
            >
              <Button
                onClick={() => navigate('/services')}
                sx={{
                  '& .MuiButton-endIcon': {
                    marginRight: isArabic ? '8px' : '-4px',
                    marginLeft: isArabic ? '-4px' : '8px',
                  },
                }}
                endIcon={
                  isArabic ? (
                    <ArrowCircleLeftOutlined />
                  ) : (
                    <ArrowCircleRightOutlined />
                  )
                }
              >
                {t('home.viewAllServices')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md="auto">
            <Navigation
              ref={buttonsRef}
              light={false}
              justifyContent="center"
              direction="column"
              backEnabled={!isAtStart}
              nextEnabled={!isAtEnd}
              onBackPressed={handleScrollUp}
              onNextPressed={handleScrollDown}
              marginTop="0px !important"
            />
          </Grid>
        </Grid>
        <Box
          onClick={() => navigate('/services')}
          ref={viewAlMobileRef}
          sx={(theme) => ({
            marginTop: '48px',
            display: 'none',
            textAlign: 'center',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            },
          })}
        >
          <Button>{t('home.viewAllServices')}</Button>
        </Box>
      </CustomContainer>
    );
  },
);

export default Services;
