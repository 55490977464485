import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { Library } from '@userpath/constants';
import { GradientTypography, useElementSize } from '@userpath/components';
import { pointsToPath } from '@userpath/utils';
import { Lang } from '@userpath/localization';
import landing from '@userpath/userpath/assets/videos/landing.mp4';
import Navigation from '../Navigation';
import Indicator from './Indicator';
import ScrollableList from './ScrollableList';
import { Styled } from './styles';

const totalPages = 5;
const minSwipeDistance = 50;
const autoPlayDuration = 7500;

const useAutoNextPage = (nextPage: () => void) => {
  const timerRef = useRef<NodeJS.Timeout | undefined>();

  const resetTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    timerRef.current = setInterval(nextPage, autoPlayDuration);
  }, [nextPage]);

  useEffect(() => {
    resetTimer();

    return () => {
      clearInterval(timerRef.current);
    };
  }, [nextPage, resetTimer]);

  return resetTimer;
};

interface IAboutProps {
  videoContainerRef?: React.RefObject<HTMLDivElement>;
  videoRef?: React.RefObject<HTMLVideoElement>;
}

const About: React.FC<IAboutProps> = ({ videoContainerRef, videoRef }) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const isArabic = i18n.language == Lang.AR;
  const touchStart = useRef<number | undefined>();
  const touchEnd = useRef<number | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [setRef, size] = useElementSize<HTMLVideoElement>();
  const path = useMemo(() => {
    if (size.width == 0 || size.height == 0) return '';
    const radius = size.width < 372 ? 135 : 312;
    return pointsToPath([
      { x: isArabic ? size.width - radius : radius, y: 0 },
      { x: isArabic ? size.width : 0, y: 0, radius: radius },
      { x: isArabic ? size.width : 0, y: size.height, radius: radius },
      { x: isArabic ? 0 : size.width, y: size.height, radius: radius },
      { x: isArabic ? 0 : size.width, y: 0 },
    ]);
  }, [size.width, size.height, isArabic]);
  useEffect(() => {
    if (videoRef) setRef(videoRef.current);
  }, [setRef, videoRef]);

  const navigate = useCallback((to: 'next' | 'back') => {
    if (to == 'next') {
      setCurrentPage((old) => (old + 1) % totalPages);
    } else {
      setCurrentPage((old) => (old - 1 + totalPages) % totalPages);
    }
  }, []);
  const resetTimer = useAutoNextPage(() => navigate('next'));
  const previousPage = useCallback(() => {
    navigate('back');
    resetTimer();
  }, [navigate, resetTimer]);
  const nextPage = useCallback(() => {
    navigate('next');
    resetTimer();
  }, [navigate, resetTimer]);

  useEffect(() => {
    const downHandler = ({ code }: KeyboardEvent) => {
      if (code == 'ArrowLeft') {
        nextPage();
      } else if (code == 'ArrowRight') {
        previousPage();
      }
    };

    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [nextPage, previousPage]);

  const onTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    touchEnd.current = undefined;
    touchStart.current = e.targetTouches[0].clientX;
  }, []);

  const onTouchMove = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) =>
      (touchEnd.current = e.targetTouches[0].clientX),
    [],
  );

  const onTouchEnd = useCallback(() => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      navigate(isArabic ? 'back' : 'next');
      resetTimer();
    } else if (isRightSwipe) {
      navigate(isArabic ? 'next' : 'back');
      resetTimer();
    }
  }, [isArabic, navigate, resetTimer]);

  return (
    <Grid
      container
      gap={{ xs: '24px', md: '32px', lg: '84px' }}
      alignItems={{ md: 'center' }}
    >
      <Grid container item xs={12} justifyContent="center" md="auto">
        <Styled.VideoContainer path={path} ref={videoContainerRef}>
          <Styled.Video
            path={path}
            autoPlay
            playsInline
            muted
            loop
            ref={videoRef}
          >
            <source src={landing} type="video/mp4" />
          </Styled.Video>
        </Styled.VideoContainer>
      </Grid>
      <Grid item xs>
        <Grid
          container
          justifyContent="space-between"
          paddingBottom={{ xs: '48px', md: '0px', lg: '68px' }}
          paddingTop={{ md: '68px', lg: '0px' }}
        >
          <Grid item flex={1} xs="auto">
            <GradientTypography variant="h4">
              {t('home.pointsTitle')}
            </GradientTypography>
          </Grid>
          <Grid item>
            <Navigation
              display={{ xs: 'none', md: 'flex' }}
              backEnabled={currentPage > 0}
              nextEnabled={currentPage < totalPages - 1}
              onBackPressed={previousPage}
              onNextPressed={nextPage}
            />
          </Grid>
        </Grid>
        <Box
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <ScrollableList page={currentPage}>
            {Array.from(Array(5)).map((_, i) => (
              <Styled.Point index={i} key={i}>
                <Styled.Title>{t(`home.point${i + 1}.title`)}</Styled.Title>
                <Styled.Details>
                  {t(`home.point${i + 1}.details`)}
                </Styled.Details>
              </Styled.Point>
            ))}
          </ScrollableList>
        </Box>
        <Indicator page={currentPage} totalPages={totalPages} />
        <Navigation
          display={{ xs: 'flex', md: 'none' }}
          sx={{ marginTop: { xs: '46px' } }}
          backEnabled={currentPage > 0}
          nextEnabled={currentPage < totalPages - 1}
          onBackPressed={previousPage}
          onNextPressed={nextPage}
        />
      </Grid>
    </Grid>
  );
};

export default About;
