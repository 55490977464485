import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { Library } from '@userpath/constants';
import {
  ContactForm,
  CustomContainer,
  GradientTypography,
  useElementSize,
} from '@userpath/components';
import { Lines, Services } from './components';
import { Styled } from './styles';

const ServicesPage: React.FC = () => {
  const { t } = useTranslation(Library.userpath);
  const [setRef, size] = useElementSize();
  const headerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<HTMLDivElement>(null);
  const path = useMemo(() => {
    const top = 0;
    return `M 0 ${top} Q ${size.width * 0.5} ${top + 100} ${size.width} ${top} L ${size.width} ${size.height} L 0 ${size.height} Z`;
  }, [size.width, size.height]);
  useEffect(() => {
    setRef(contentRef.current);
  }, [setRef, contentRef]);
  return (
    <Box sx={{ position: 'relative' }}>
      <Lines
        headerRef={headerRef}
        contentRef={contentRef}
        contactRef={contactRef}
        formRef={formRef}
      />
      <CustomContainer
        ref={headerRef}
        sx={{ paddingTop: { xs: '50px', md: '104px' }, paddingBottom: '80px' }}
      >
        <Stack textAlign="center" alignItems="center">
          <GradientTypography variant="h1" gradient="primary" animated>
            {t('services.title')}
          </GradientTypography>
          <Typography
            variant="h5"
            sx={(theme) => ({
              margin: { md: '0px 64px', lg: '0px 124px', xl: '0px 194px' },
              marginTop: '32px',
              lineHeight: 1.5,
              maxWidth: '1024px',
              color: theme.palette.text.neutral80,
            })}
          >
            {t('services.details')}
          </Typography>
        </Stack>
      </CustomContainer>
      <Styled.BGContainer path={path} ref={contentRef}>
        <Styled.Container>
          <Services />
          <ContactForm
            sx={{ padding: '0px 18px' }}
            rowSpacing={{ xs: '88px', md: '0px' }}
            variant="services"
            ref={contactRef}
            formRef={formRef}
          />
        </Styled.Container>
      </Styled.BGContainer>
    </Box>
  );
};

export default ServicesPage;
