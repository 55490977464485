const padLeft = (value: number) => {
  if (value < 10) return `0${value}`;
  else return value.toFixed(0);
};

export const formatDate = (date: Date) => {
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1; // Months start at 0!
  const dd = date.getDate();
  return `${padLeft(dd)}/${padLeft(mm)}/${yyyy}`;
};
