import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridProps } from '@mui/material';
import { Library } from '@userpath/constants';
import { SVGFunctionComponent } from '@userpath/types';
import { ReactComponent as Book } from '@userpath/userpath/assets/icons/book.svg';
import { ReactComponent as Code } from '@userpath/userpath/assets/icons/code.svg';
import { ReactComponent as Coin } from '@userpath/userpath/assets/icons/coin.svg';
import { ReactComponent as ConvertCard } from '@userpath/userpath/assets/icons/convert-card.svg';
import { ReactComponent as Data } from '@userpath/userpath/assets/icons/data.svg';
import { ReactComponent as Logout } from '@userpath/userpath/assets/icons/logout.svg';
import { ReactComponent as MonitorMobile } from '@userpath/userpath/assets/icons/monitor-mobile.svg';
import { ReactComponent as Verify } from '@userpath/userpath/assets/icons/verify.svg';

import { CareerItem } from '.';

const careers: {
  title: string;
  icon: SVGFunctionComponent;
  details?: string;
}[] = [
  { title: 'careerType1', icon: MonitorMobile, details: 'careerDetails1' },
  { title: 'careerType2', icon: Code, details: 'careerDetails2' },
  { title: 'careerType3', icon: ConvertCard },
  { title: 'careerType4', icon: Data },
  { title: 'careerType5', icon: Verify },
  { title: 'careerType6', icon: Coin },
  { title: 'careerType7', icon: Book },
  { title: 'careerType8', icon: Logout },
];

const CareerItems: React.FC<GridProps> = (props) => {
  const { t } = useTranslation(Library.userpath);

  return (
    <Grid
      container
      justifyContent="flex-start"
      columnSpacing="24px"
      rowSpacing={{ xs: '68px', md: '24px' }}
      gridAutoColumns="repeat(5, 1fr)"
      {...props}
    >
      {careers.map((career, i) => (
        <Grid key={i} sm={4} xs={12} md={4} lg={3} item>
          <CareerItem
            icon={career.icon}
            title={t(`careers.${career.title}`)}
            details={
              career.details ? t(`careers.${career.details}`) : undefined
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default CareerItems;
