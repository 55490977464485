import React, { useEffect, useRef, useState } from 'react';
import { Styled } from './styles';

interface IScrollableListProps {
  page: number;
}

const ScrollableList: React.FC<
  React.PropsWithChildren<IScrollableListProps>
> = ({ children, page }) => {
  const containerRef = useRef<HTMLDivElement>();
  const [maxHeight, setMaxHeight] = useState(100);

  useEffect(() => {
    const containerElement = containerRef.current;
    if (!containerElement) return;
    const handleResize = () => {
      const itemElements = containerElement.querySelectorAll('div');
      let maxItemHeight = 0;
      itemElements.forEach((item) => {
        const itemHeight = item.offsetHeight;
        if (itemHeight > maxItemHeight) {
          maxItemHeight = itemHeight;
        }
      });
      setMaxHeight(maxItemHeight);
    };

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(containerElement);
    handleResize();
    return () => {
      resizeObserver.disconnect();
    };
  }, [children]);

  return (
    <Styled.PointsContainer itemHeight={maxHeight}>
      <Styled.PointsList itemHeight={maxHeight} ref={containerRef} page={page}>
        {children}
      </Styled.PointsList>
    </Styled.PointsContainer>
  );
};

export default ScrollableList;
