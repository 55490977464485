import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  useTheme,
} from '@mui/material';
import {
  DeleteOutline,
  EditOutlined,
  PrintOutlined,
  ShareOutlined,
} from '@mui/icons-material';
import { AppError, ArticleDTO } from '@userpath/types';
import { Lang } from '@userpath/localization';
import { getLocalized, useToastContext } from '@userpath/utils';
import { Library } from '@userpath/constants';
import { useArticles } from '@userpath/services';
import { toggleLoader } from '../loader';
import { useArticleLike } from '../../hooks';
import { StyledContent } from './styles';

interface IArticleActionsProps {
  article: ArticleDTO;
  disableInteractions?: boolean;
  showAdminButtons?: boolean;
}

const ArticleActions: React.FC<IArticleActionsProps> = ({
  article,
  disableInteractions,
  showAdminButtons,
}) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const { addToast } = useToastContext();
  const [showDelete, setShowDelete] = useState(false);
  const [liked, setLiked] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const articlesServices = useArticles();
  const likeService = useArticleLike(article.id);
  const isArabic = i18n.language == Lang.AR;
  const getValue = useCallback(
    (english?: string, arabic?: string) =>
      getLocalized(isArabic, english, arabic),
    [isArabic],
  );
  const title = useMemo(
    () => getValue(article.titleEnglish, article.titleArabic),
    [getValue, article.titleArabic, article.titleEnglish],
  );
  const totalLikes = useMemo(() => {
    const likes = article.likes ?? 0;
    if (likeService.changed) {
      return likeService.liked ? likes + 1 : likes - 1;
    }
    return likes;
  }, [article.likes, likeService.changed, likeService.liked]);
  const closeDialog = useCallback(() => {
    setShowDelete(false);
  }, []);
  const onLikeClick = useCallback(() => {
    if (disableInteractions) {
      setLiked((old) => !old);
    } else {
      setLiked((old) => !old);
      likeService.toggleArticleLike();
    }
  }, [disableInteractions, likeService]);
  const deleteArticle = useCallback(async () => {
    closeDialog();
    toggleLoader(true);
    try {
      await articlesServices.deleteArticle(article.id);
      toggleLoader(false);
      navigate('/articles');
    } catch (error) {
      addToast({
        message: AppError.fromError(error).translate(t),
        type: 'error',
      });
    } finally {
      toggleLoader(false);
    }
  }, [addToast, article.id, articlesServices, closeDialog, navigate, t]);
  useEffect(() => {
    if (likeService.liked) setLiked(likeService.liked);
  }, [likeService.liked]);

  return (
    <React.Fragment>
      <StyledContent.ActionsContainer>
        <Stack
          alignItems="center"
          direction={isArabic ? 'row-reverse' : 'row'}
          gap="2px"
        >
          <StyledContent.ActionButton
            onClick={onLikeClick}
            disabled={
              likeService.loading == true || likeService.error != undefined
            }
          >
            <StyledContent.LikeButton liked={liked}>
              <StyledContent.LikeInactive
                sx={{ display: liked ? 'none' : 'block' }}
              />
              <StyledContent.LikeActive
                sx={{ display: liked ? 'block' : 'none' }}
              />
            </StyledContent.LikeButton>
          </StyledContent.ActionButton>

          {totalLikes > 0 && (
            <StyledContent.Likes>{totalLikes}</StyledContent.Likes>
          )}
        </Stack>
        {navigator.share && (
          <StyledContent.ActionButton
            onClick={() => {
              navigator.share({
                title,
                url: window.location.href,
              });
            }}
          >
            <ShareOutlined />
          </StyledContent.ActionButton>
        )}
        <StyledContent.ActionButton onClick={print}>
          <PrintOutlined />
        </StyledContent.ActionButton>
        {showAdminButtons && (
          <React.Fragment>
            <Divider orientation="vertical" sx={{ height: '42px' }} />
            <StyledContent.ActionButton
              onClick={() => navigate(`/admin/article/${article.id}`)}
            >
              <EditOutlined />
            </StyledContent.ActionButton>
            <StyledContent.ActionButton onClick={() => setShowDelete(true)}>
              <DeleteOutline />
            </StyledContent.ActionButton>
          </React.Fragment>
        )}
      </StyledContent.ActionsContainer>
      {createPortal(
        <svg width={0} height={0}>
          <linearGradient
            id="hoverGradient"
            x1="-5.5"
            y1="21.2685"
            x2="46.4127"
            y2="-11.2805"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={theme.palette.secondary.secondary} />
            <stop offset="0.41" stopColor={theme.palette.secondary.main} />
            <stop offset="0.64" stopColor={theme.palette.primary.secondary} />
            <stop offset="1" stopColor={theme.palette.primary.main} />
          </linearGradient>
        </svg>,
        document.body,
      )}
      {createPortal(
        <Dialog
          open={showDelete}
          onClose={closeDialog}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">
            {t('admin.article.deleteArticle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
              {t('admin.article.deleteArticleInfo')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>{t('admin.article.cancel')}</Button>
            <Button color="error" onClick={deleteArticle}>
              {t('admin.article.delete')}
            </Button>
          </DialogActions>
        </Dialog>,
        document.body,
      )}
    </React.Fragment>
  );
};

export default ArticleActions;
