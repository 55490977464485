export const userpath = {
  home: {
    services: 'Services',
    viewAllServices: 'View all services',
    articles: 'Latest Articles',
    readMore: 'Read more',
    contactInfo:
      "Not sure what's needed for your specific business? We'll walk you through our services and provide you with an honest consultation.",
    service1: {
      title: 'UX Research & Testing (UX)',
      details:
        'Gain that competitive advantage by deeply understanding your target users experience, pain-points, expectations, and needs. Then, validate current or new design concepts through testing and proceed with confidence.',
    },
    service2: {
      title: 'User Interface Design (UI)',
      details:
        "Complement the user experience with great visuals. Whether it's web or mobile, we create beautiful designs that follow principles of interaction design to ensure compliance with best user experience practices.",
    },
    service3: {
      title: 'Customer Experience (CX)',
      details:
        'Zoom out and view the bigger picture. We assess and understand the current experience across every point of interaction with your company to help you ensure a consistently satisfactory experience.',
    },
    service4: {
      title: 'UX Strategy',
      details:
        "Align your business goals with your users objectives. Whether it's digital transformation, product Innovation, concept development, we help you create a roadmap that incorporates the user experience into your practice.",
    },
    service5: {
      title: 'Workshops',
      details:
        'Our certified and experienced practitioners run a variety of workshops and focus groups to learn, understand, ideate and create. Depending on your business needs, our expert facilitators have you covered.',
    },
    service6: {
      title: 'UX Assessment',
      details:
        'We benchmark the experience to help you monitor progress, track changes, and compare to competitors. We conduct in-depth usability evaluations on existing design and provide recommendations based on best practices.',
    },
    service7: {
      title: 'Content Design (UX Writing)',
      details:
        'Content plays a key role in directing the users to their objectives and creating an easy and joyful journey. Words matter, and can give life and excitement to any product if chosen correctly.',
    },
    service8: {
      title: 'Human Factors (Ergonomics)',
      details:
        'Test and enhance your physical and industrial products. UX is not just for websites and apps. If your product, system, or process involves human interaction, it needs a good user experience.',
    },
    pointsTitle: 'Why UserPath?',
    point1: {
      title: 'Value',
      details:
        'Our core motivation for founding UserPath is to create meaningful work. We provide actionable insights, and we focus on solutions and opportunities that create real value for you and your customers.',
    },
    point2: {
      title: 'Research',
      details:
        'Our experience in identifying the right problems to be solved, our ability to cultivate a strong understanding of users and their needs, and finding, creating and validating the best solutions is what sets us apart in the market.',
    },
    point3: {
      title: 'Experienced staff',
      details:
        'Our team has a combined experience working in a variety of industries within the country which include government, banking, travel, health, e-commerce, human resources, food delivery, cybersecurity, telecommunication, and more.',
    },
    point4: {
      title: 'Language and Culture',
      details:
        'Fluency in both languages; Arabic and English + Deep cultural understanding.',
    },
    point5: {
      title: 'Certified team members',
      details: 'Certified UX team members overlook and work on all projects.',
    },
  },
  careers: {
    partnership: 'Partnership',
    partnershipDetails:
      'If you are looking for a collaboration, consultation, need help with a project, want to support with your expertise, have valuable leads, or any general business proposition, let us grow together!',
    emailUs: 'Email us at',
    careers: 'Careers',
    workUs: 'Work with us as a freelancer!',
    careerDetails1: '(Research, design, other)',
    careerDetails2: '(Back & Front)',
    careerType1: 'UX/UI',
    careerType2: 'Development',
    careerType3: 'CX and service design',
    careerType4: 'Business Analyst',
    careerType5: 'Quality Assurance',
    careerType6: 'Sales & Pricing',
    careerType7: 'Workshop Facilitator',
    careerType8: 'Other related Specialties',
    workDetails:
      'If you are an expert in any of the above domains and are interested in project-based work, please share the below requirements at',
    workRequirement1: 'CV or Resume',
    workRequirement2: 'Phone & Email',
    workRequirement3: 'Nationality',
    workRequirement4: 'Where you live',
    workRequirement5: 'Portfolio or sample work',
    lookForward: 'We look forward to you being part of UserPath family.',
  },
  about: {
    title: 'Who Are We?',
    details:
      'Located in Riyadh, Saudi Arabia, we provide UX research services to a wide range of industries. We help companies reach their goals by helping them achieve a greater understanding of their users. Our company name, UserPath, reflects our philosophy that users lead the way in shaping great experiences.',
    item1Title: 'Our Purpose',
    item1Details:
      'To help people achieve their goals and make their lives easier by simplifying what is complex and reinventing what is stagnant.',
    item2Title: 'Our Vision',
    item2Details:
      'Our vision is to be a catalyst for innovation and sustainable growth in the kingdom of Saudi Arabia and beyond. We aim to change the world for the better by touching the lives of 50 million individuals by 2030.',
    item3Title: 'Our Mission',
    item3Details:
      'Through research, strategy and innovative design, we strive to make a difference by engaging in high-impact projects that benefit and empower users, as well as distinguish our clients in the market.',
    item4Title: 'Our Promise',
    item4Details:
      "We believe in quality, honesty, and data-driven insights. We treat our clients' business as if it is our own. We will share with you the research results which will untangle your way forward, and explore the opportunities to grow. Our approach is personal and our results are evidence-based.",
    located: 'Located In',
    location: 'Riyadh, Saudi Arabia',
    navigate: 'Press Right+Left to explore',
  },
  articles: {
    title: 'Articles',
    details:
      'Welcome to our article page, where knowledge meets curiosity and questions meet answers! Here, we strive to bring you captivating and insightful experience-related content that ignites your mind with interesting facts and opinions.',
    by: 'By',
    readingTime: 'Reading Time:',
    published: 'Published:',
    lastUpdated: 'Last Updated:',
    goBack: 'Go Back',
    minutes_one: '{{count}} minute',
    minutes_other: '{{count}} minutes',
    empty: 'There is no articles yet',
  },
  services: {
    title: 'Services',
    details:
      'To service offering is personal, we look into your particular business requirements and help both you and your users reach your desired goals by providing a variety of UX research services.',
    service1: {
      title: 'UX Research & Testing',
      details:
        'This is the basis of any remarkable experience. Our team includes consultants trained and experienced in both psychology and Human Computer Interaction which will gain you that competitive advantage by deeply understanding your target users experience.<1/>Every market is unique, and it’s important to study the right kind of users. Looking to serve in the Saudi or Arabian market in general? Our targeted user centric research will give you that competitive edge you need. We provide:',
      items: [
        'User testing.',
        'Recruitment of target users.',
        'Persona development.',
        'User and stakeholder interviews.',
        'Survey development and distribution.',
        'Competitor analysis.',
        'Contextual inquiries.',
        'Card sorting and tree testing.',
        'Synthesis of existing analytics data.',
        'User journey mapping.',
      ],
    },
    service2: {
      title: 'User Interface Design (UI)',
      details:
        'Complement the user experience for web or mobile with great visuals. We create beautiful interfaces that follow the principles of interaction design to ensure compliance with best user experience practices.<1/>Whether you want to bring your ideas to life or simply enhance an existing design. Our talented designers will work with you from concept development, and wireframing to the creation of a user interface that is both visually pleasing and effective.',
    },
    service3: {
      title: 'Customer Experience (CX).',
      details:
        'Assess the experience across every touchpoint, digital and non-digital, to know where to best focus your efforts and resources for optimal improvement, customer satisfaction, and loyalty.<1/>Our service currently focuses on deeply understanding the end-to-end journey which is achieved via a combination of the following methods:',
      items: [
        'Customer journey mapping',
        'Analysis of existing customer data',
        'Benchmarking and KPI measures',
        'Service design blueprinting',
      ],
    },
    service4: {
      title: 'UX Strategy',
      details:
        'Align business goals with user objectives. Whether it\'s digital transformation, product Innovation, concept development or otherwise; we help you create a process that incorporates the user experience into your practice. Gaining clarity on how to serve both the user and business alike (not excluding one from the other) will increase innovation, collaboration, and lead to higher project success.<1/>Learn more: <2 href="/article/KPZzyb7plM0zS60CiRSu">(UX & Business article)</2>. We can help you:',
      items: [
        'Discover what the actual problems to be solved are.',
        'Gain a deeper understanding of your end users core desires.',
        'Create a roadmap to guide your decision making process in order to insure an ongoing satisfactory experience for your users',
        'Develop an action plan to guarantee the business objectives are adding value to the users you aim to serve through ongoing validation.',
      ],
    },
    service5: {
      title: 'Workshops',
      details:
        'If you need support in this area, whether in-person or remotely, we conduct a variety of professional workshops and focus groups as needed to help you learn, understand, ideate and create. Every workshop is personalized depending on what will best serve your requirements and objectives.',
    },
    service6: {
      title: 'UX Assessment',
      details:
        'Evaluate the experience, monitor progress, compare solutions, track the effect of changes, and compare your standing to that of competitors. Know where you stand and understand if you need to make changes or if the current experience is in good standings.<1/>We conduct in-depth usability evaluations on existing solutions and provide recommendations based on best practices. This is performed through different means depending on the nature of your service, some of which include:',
      items: [
        'Heuristic evaluations (expert reviews)',
        'Usability Benchmarking',
        "Standardized Usability Metrics and KPI's",
        'Analytics framework development',
        'A/B testing framework development',
      ],
    },
    service7: {
      title: 'Content Design (UX Writing).',
      details:
        'Content plays a key role in directing the users to their objectives and creating an easy and joyful journey. Words matter, and can give life and excitement to any product if chosen correctly. UX Writing Research tells you whether your message to your customer has been misunderstood or delivered correctly. We provide:',
      items: [
        'UX Writing.',
        'Content-related testing.',
        'Content Guidelines.',
        'Tone of voice analysis & development.',
        'Glossary.',
      ],
    },
    service8: {
      title: 'Human Factors (Ergonomics)',
      details:
        'UX is not just for websites and apps (see what is UX? article), if your product or design is physical or industrial and involves human interaction then it needs a good user experience. This could be for a self-checkout machine, kiosk, toy, car, TV, remote control, oven, or even a robot!<1/>Studying the ergonomics will allow you to reduce errors, minimize cognitive load, improve efficiency and effectiveness; which will in turn enhance the overall experience. We happen to have a unique blend of expertise in psychology, human computer interaction and user experience. We can help you do this!',
    },
  },
  login: {
    signIn: 'Sign in',
    email: 'Email',
    password: 'Password',
    enterEmail: 'Enter your email',
    enterPassword: 'Enter your Password',
  },
  admin: {
    article: {
      newArticle: 'New Article',
      editArticle: 'Edit Article',
      english: 'English',
      arabic: 'Arabic',
      delete: 'Delete',
      deleteArticle: 'Delete Article',
      deleteArticleInfo: 'Are you sure want to delete this article?',
      cancel: 'Cancel',
      remove: 'Remove',
      saveArticle: 'Save Article',
      addArticle: 'Add Article',
      discard: 'Discard',
      discardConfirmation: 'Are you sure you want to discard your edits?',
      title: 'Title',
      description: 'Description',
      writer: 'Writer',
      imageDesktop: 'Desktop Image (Vertical)',
      imageMobile: 'Mobile Image (Horizontal)',
      tags: 'Tags',
      tagName: 'Tag Name',
      tagColor: 'Tag Color',
      addTag: 'Add Tag',
      readingTime: 'Reading Time (Minutes)',
      content: 'Content',
      input: 'Input a value...',
      enterContent: "Enter the article's content...",
      fillDetails: 'Please fill the details before previewing...',
      previewHome: 'Preview Home Page Card',
      previewArticle: 'Preview Articles Page Card',
      previewContent: 'Preview Content',
      mobile: 'Mobile',
      desktop: 'Desktop',
    },
  },
};
