import { Button, styled } from '@mui/material';

type AppButtonVariant = 'contact';

interface IAppButtonProps {
  buttonVariant?: AppButtonVariant;
}

export const AppButton = styled(Button)<IAppButtonProps>(
  ({ buttonVariant = 'contact', theme }) => ({
    color: 'white',
    padding: '8px 16px',
    ...(buttonVariant == 'contact' && {
      background: `linear-gradient(84.15deg,
          ${theme.palette.secondary.secondary} -90.5%,
          ${theme.palette.secondary.main} 3.03%,
          ${theme.palette.primary.secondary} 55.5%,
          ${theme.palette.primary.main} 137.63%)`,
    }),
  }),
);
