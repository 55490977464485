import React, { useCallback } from 'react';
import {
  Canvas,
  ICanvasDrawProps,
  ICanvasPrepareProps,
} from '@userpath/components';
import { Point, drawLine, addControlPoints, flipPoints } from '@userpath/utils';

interface IPreparedValue {
  line1: Point[];
  line2: Point[];
}

interface ILinesProps {
  headerRef: React.RefObject<HTMLDivElement>;
  contentRef: React.RefObject<HTMLDivElement>;
  contactRef: React.RefObject<HTMLDivElement>;
  formRef: React.RefObject<HTMLDivElement>;
}

const Lines: React.FC<ILinesProps> = ({
  headerRef,
  contentRef,
  contactRef,
  formRef,
}) => {
  const prepare = useCallback(
    (props: ICanvasPrepareProps): IPreparedValue => {
      if (
        !headerRef.current ||
        !contentRef.current ||
        !contactRef.current ||
        !formRef.current
      ) {
        return { line1: [], line2: [] };
      }
      const { width, isRTL } = props;
      const line1 = getFirstLine(
        props,
        headerRef.current.offsetHeight,
        contentRef.current.offsetTop + formRef.current.offsetTop - 85,
      );
      const line2 = getSecondLine(
        props,
        contentRef.current.offsetTop,
        contactRef.current.offsetTop,
        formRef.current.offsetTop - contactRef.current.offsetTop,
      );
      return {
        line1: isRTL ? flipPoints(line1, width) : line1,
        line2: isRTL ? flipPoints(line2, width) : line2,
      };
    },
    [headerRef, contentRef, contactRef, formRef],
  );
  const draw = useCallback(
    ({
      context: ctx,
      theme,
      preparedValue,
    }: ICanvasDrawProps<IPreparedValue>) => {
      if (preparedValue == undefined) return true;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      ctx.lineWidth = 8;
      ctx.lineCap = 'round';
      ctx.strokeStyle = theme.palette.text.neutral10;

      drawLine(ctx, preparedValue.line1);

      const stops = [0, 0.4107, 0.7833, 1];
      const colors = [
        theme.palette.secondary.secondary,
        theme.palette.secondary.main,
        theme.palette.primary.secondary,
        theme.palette.primary.main,
      ];

      const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
      for (let i = 0; i < colors.length; i++) {
        gradient.addColorStop(stops[i], colors[i]);
      }
      ctx.strokeStyle = gradient;
      drawLine(ctx, preparedValue.line2);

      return false;
    },
    [],
  );
  return (
    <Canvas<IPreparedValue>
      style={{
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -2,
      }}
      prepare={prepare}
      draw={draw}
    />
  );
};

const getFirstLine = (
  props: ICanvasPrepareProps,
  headerHeight: number,
  top: number,
): Point[] => {
  const { width, isMobile } = props;
  if (headerHeight == 0) return [];
  if (isMobile) {
    const st = headerHeight - 120;
    const p1 = 50;
    const extraX = 100;
    return addControlPoints([
      { x: width - Math.min(-15, p1 - 70), y: headerHeight },
      { x: width - p1 - extraX, y: headerHeight, radius: 72 },
      { x: width - p1 - extraX, y: headerHeight + 200 },
      { x: width - p1, y: headerHeight + 200, radius: 30 },
      { x: width - p1, y: st, radius: 72 },
      { x: width - Math.min(-15, p1 - 70), y: st },
    ]);
  } else {
    const st = headerHeight - 300;
    const p1 = 160;
    const extraX = 290;
    const extraY = 20;
    return addControlPoints([
      { x: width - p1 - extraX, y: headerHeight + 300 },
      { x: width - p1, y: headerHeight + 300, radius: 140 },
      { x: width - p1, y: st, radius: 140 },
      { x: width - Math.min(-15, p1 - 140), y: st },
      { x: width - Math.min(-15, p1 - 140), y: headerHeight - extraY },
      { x: width - p1 - extraX, y: headerHeight - extraY, radius: 170 },
      { x: width - p1 - extraX, y: top, radius: 150 },
      { x: 0, y: top },
    ]);
  }
};

const getSecondLine = (
  props: ICanvasPrepareProps,
  top: number,
  contactTop: number,
  formTop: number,
): Point[] => {
  const { height, width, isMobile } = props;
  if (height == 0) return [];
  if (isMobile) {
    const start = top + contactTop - 120;
    const end = top + contactTop + formTop + 44;
    return addControlPoints([
      { x: -62, y: start },
      { x: 24, y: start, radius: 80 },
      { x: 24, y: end, radius: 80 },
      { x: width, y: end },
    ]);
  } else {
    return addControlPoints([
      { x: 0, y: top + 400 },
      { x: width / 2, y: top + 400, radius: 150 },
      { x: width / 2, y: height - 120, radius: 150 },
      { x: width, y: height - 120 },
    ]);
  }
};

export default Lines;
