import { Box, Typography, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  Content: styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '40px',
    [theme.breakpoints.down('md')]: {
      gap: '16px',
    },
  })),
  Title: styled(Typography, {
    shouldForwardProp: shouldForwardProp(['variantColor']),
  })<{ variantColor?: 'light' | 'dark' }>(
    ({ theme, variantColor = 'dark' }) => ({
      ...theme.typography.h4,
      fontWeight: 'bold',
      color:
        variantColor == 'dark'
          ? theme.palette.text.neutral90
          : theme.palette.text.neutral10,
    }),
  ),
  Details: styled(Typography, {
    shouldForwardProp: shouldForwardProp(['variantColor']),
  })<{ variantColor?: 'light' | 'dark' }>(
    ({ theme, variantColor = 'dark' }) => ({
      ...theme.typography.h5,
      color:
        variantColor == 'dark'
          ? theme.palette.text.neutral70
          : theme.palette.text.neutral40,
    }),
  ),
};
