export const userpath = {
  home: {
    services: 'الخدمات',
    viewAllServices: 'عرض جميع الخدمات',
    articles: 'أحدث المقالات',
    readMore: 'تعرّف أكثر',
    contactInfo:
      'لست متأكداً ما هو المناسب لمشروعك؟ تواصل معنا لنفهم طبيعة عملك وسنقدم لك رأينا بكل أمانة. تواصل معنا',
    service1: {
      title: 'أبحاث واختبارات تجربة المستخدم (UX)',
      details:
        'تقدم لك هذه الخدمة الثقة بمنتجك والميزة التنافسية من خلال الفهم العميق لتجربة المستخدمين والتعرف على نقاط الضعف والقوة والتوقعات والاحتياجيات، والتحقق من مدى جودة التصاميم الحالية أو الجديدة عن طريق اختبارات المستخدم.',
    },
    service2: {
      title: 'تصميم واجهة المستخدم (UI)',
      details:
        'نضيف على تجربة المستخدم جماليات بصرية رائعة، سواء كان ذلك عبر الموقع الإلكتروني أو تطبيق الجوال، حيث أننا نحرص على أن يكون التصميم ملتزماً بمبادئ التصميم التفاعلي لضمان الامتثال لأفضل ممارسات تجربة المستخدم.',
    },
    service3: {
      title: 'تجربة العميل (CX)',
      details:
        'هنا ننظر إلى الصورة الأكبر، ونهدف إلى فهم وتقييم جميع نقاط التفاعل التي يمر بها العميل، سواءً كانت رقمية أو غير رقمية.',
    },
    service4: {
      title: 'تجربة المستخدم والأعمال',
      details:
        'بناء استاتيجية تهدف إلى مواءمة أهداف العمل مع أهداف المستخدم، سواء كان ذلك في التحول الرقمي أو ابتكار المنتجات أو تطوير المفاهيم؛ نساعدك على إنشاء خريطة الطريق لتطوير تجربة المستخدم في مشروعك.',
    },
    service5: {
      title: 'ورش عمل',
      details:
        'يدير ممارسونا المعتمدون وذوي الخبرة مجموعة متنوعة من ورش العمل ومجموعات التركيز (Focus groups) للتعلم والفهم والتفكير والإبداع بناءً على احتياجات عملك.',
    },
    service6: {
      title: 'تقييم تجربة المستخدم (UX Assessment)',
      details:
        'نقيّم التجربة لمساعدتك في متابعة التطور وملاحظة التغييرات في السوق والمنافسين، ثم نقدم لك التوصيات المبنية على دراسات عميقة لقابلية الاستخدام على التصميم الحالي لمنتجك.',
    },
    service7: {
      title: 'تصميم المحتوى (كتابة تجربة المستخدم).',
      details:
        'يلعب المحتوى دورًا رئيسيًا في توجيه المستخدمين نحو أهدافهم وبناء رحلة سهلة وممتعة. الكلمات مهمة، ويمكن أن تعطي الحياة والحماس لأي منتج إذا تم اختيارها بشكل صحيح.',
    },
    service8: {
      title: 'المنتجات المادية والصناعية ',
      details:
        'تجربة المستخدم ليست مخصصة لمواقع الإنترنت والتطبيقات فقط بل كل ما يتفاعل معه الإنسان (Ergonomics)، فإذا كان منتجك أو نظامك أوعمليتك تتضمن تفاعلًا بشريًا، فإنها تحتاج إلى تجربة مستخدم رائعة.',
    },
    pointsTitle: 'لماذا مسار المستخدم؟ ',
    point1: {
      title: 'القيمة',
      details:
        'الدافع الأساسي لوجود مسار المستخدم هو إنشاء عمل هادف، ونحن هنا نقدم رؤى قابلة للتنفيذ، ونركز على الحلول والفرص التي تخلق قيمة حقيقية لك ولعملائك.',
    },
    point2: {
      title: 'البحث',
      details:
        'تكمن خبرتنا في تحديد المشكلات التي يتوجب حلها، وما يميزنا في السوق هو قدرتنا على فهم المستخدمين واحتياجاتهم وبناء أفضل الحلول والتحقق من صحتها.',
    },
    point3: {
      title: 'خبرة الموظفين',
      details:
        'يتمتع فريقنا بخبرة في مجموعة متنوعة من القطاعات، والتي تشمل الحكومية والخدمات المصرفية والسفر والسياحة والصحة والتجارة الإلكترونية والموارد البشرية وقطاع التوصيل والأمن السيبراني والاتصالات وغيرها الكثير.',
    },
    point4: {
      title: 'اللغة والثقافة',
      details:
        'الطلاقة والإتقان في اللغتين العربية والإنجليزية، بالإضافة إلى فهم عميق للثقافة السعودية والخليجية.',
    },
    point5: {
      title: 'فريق معتمد',
      details:
        'يملك أعضاء فريق مسار المستخدم شهادات معتمدة وبدورهم يعملون ويشرفون على جميع المشاريع.',
    },
  },
  careers: {
    partnership: 'الشراكة',
    partnershipDetails:
      'إذا كنت تبحث عن تعاون أو استشارة أو تحتاج إلى مساعدة في مشروع ما، أو ترغب في الدعم بخبرتك أو لديك عملاء محتملين ذوي قيمة أو أي عرض عمل عام، تواصل معنا لننمو معًا!',
    emailUs: 'راسلنا عبر البريد الإلكتروني على',
    careers: 'الوظائف',
    workUs: 'اعمل معنا كموظف مستقل!',
    careerDetails1: '(البحث والتصميم وغيرها)',
    careerDetails2: '(Back-end & Front-end)',
    careerType1: 'تجربة المستخدم/واجهة المستخدم',
    careerType2: 'تطوير البرمجيات',
    careerType3: 'تجربة العملاء وتصميم الخدمة',
    careerType4: 'محلل الأعمال',
    careerType5: 'ضمان الجودة',
    careerType6: 'المبيعات والتسعير',
    careerType7: 'مشرف ورشة عمل',
    careerType8: 'تخصصات أخرى مشابهة',
    workDetails:
      'إذا كنت خبيراً في أي من المجالات المذكورة أعلاه ومهتماً بالعمل القائم على المشاريع، فيرجى مشاركة المتطلبات أدناه على',
    workRequirement1: 'السيرة الذاتية',
    workRequirement2: 'رقم الجوال والبريد الإلكتروني',
    workRequirement3: 'الجنسية',
    workRequirement4: 'مدينة الإقامة',
    workRequirement5: 'ملف لأعمالك السابقة',
    lookForward: 'نتطلّع إلى أن تكون جزءاً من عائلة مسار المستخدم!',
  },
  about: {
    title: 'من نحن؟',
    details:
      'شركة تقدّم خدمات لأبحاث تجربة المستخدم لمجموعة متنوعة من القطاعات، نساعد الشركات على تحقيق أهدافها من خلال مساعدتها على تحقيق فهم أكبر للقاع والمستخدمين، مقرّها الرياض - السعودية.<1/> يعكس اسم شركتنا "مسار المستخدم" فلسفتنا المتمثلة في أن المستخدم هو من يقود الطريق في تشكيل التجربة الرائعة، حيث أن نهج الشركة يتضمن التجارب الواقعية مع عملاء حقيقين والتي بدورها تساهم في رفع كفاءة تصميم رحلة المستخدم.<1/>تواصل معنا لبناء تجربة رائعة لعملائك لأن هذا هو اختصاصنا.',
    item1Title: 'هدفنا',
    item1Details:
      'نسعى إلى تمكين الناس من تحقيق أهدافهم وتسهيل الحياة من خلال تبسيط كل ماهو معقد، وإعادة الحياة لكل ماهو متجاهل.',
    item2Title: 'رؤيتنا',
    item2Details:
      'أن نكون حافزاً للابتكار والنمو المستدام في المملكة العربية السعودية وخارجها، رؤيتنا تتمحور حول تغيير العالم إلى الأفضل من خلال التأثير على حياة 50 مليون فرد بحلول عام 2030.',
    item3Title: 'مهمتنا',
    item3Details:
      'نسعى جاهدين لصنع الفارق من خلال البحث في تجربة المستخدم والاستراتيجية والتصميم المبتكر في مشاريع تؤثر بشكل كبير وتمكّن المستخدمين من تحقيق أهدافهم، بالإضافة إلى تمييز ، فضلاً عن تمييز عملائنا في السوق."',
    item4Title: 'وعدنا',
    item4Details:
      'نتعهد بالجودة والأمانة والآراء المستندة على بيانات فعليّة، نتعامل مع مشروعك كما لو كان مشروعنا ونشارك معك نتائج الأبحاث التي ستفتح لك أبواب النجاح واكتشاف فرص النمو.',
    located: 'متواجدين في',
    location: 'الرياض، المملكة العربية السعودية',
    navigate: 'اضغط يمين + يسار للتصفح',
  },
  articles: {
    title: 'المقالات',
    details:
      'أهلاً بك في صفحة المقالات، حيث تلتقي المعرفة بالفضول والأسئلة بالأجوبة! نسعى جاهدين لنقدم لك محتوى رائع وعميق عن مواضيع تجربة المستخدم أو ما يدور حوله لإضافة معلومات قيمة وحقائق آراء مثيرة للاهتمام.',
    by: 'بواسطة',
    readingTime: 'وقت القراءة:',
    published: 'تاريخ النشر:',
    lastUpdated: 'آخر تاريخ للتعديل:',
    goBack: 'العودة للخلف',
    minutes_zero: '{{count}} دقيقة',
    minutes_one: 'دقيقة واحدة',
    minutes_few: '{{count}} دقائق',
    minutes_many: '{{count}} دقيقة',
    minutes_other: '{{count}} دقيقة',
    empty: 'لا يوجد مقالات بعد',
  },
  services: {
    title: 'الخدمات',
    details:
      'تتمحور منهجية عملنا حول التركيز على مشروعك والاهتمام بمتطلبات عملك كما لو أنه كان مشروعنا الشخصي، وذلك لنتمكن من تحقيق أهدافك وكذلك أهداف والمستخدمين من خلال تقديم مجموعة متنوعة من خدمات أبحاث تجربة المستخدم.',
    service1: {
      title: 'أبحاث واختبارات تجربة المستخدم (UX)',
      details:
        'كل سوق فريد من نوعه، ومن المهم دراسة النوع المناسب من المستخدمين لأن هذا هو أساس أي تجربة رائعة، بالإضافة إلى أن فريقنا يتميز بمستشارين وخبراء في علم النفس والتفاعل بين الإنسان والحاسب مما يمكّننا من تقديم الميزة التنافسية لك من خلال الفهم العميق لتجربة المستخدمين المستهدفين.<1/>هل عملك يستهدف السوق السعودي أو العربي بشكل عام؟ سنتمكن من تقديم الميزة التنافسية لمشروعك والتي تحتاجها من خلال أبحاثنا المستهدفة التي تركز على المستخدم عن طريق:',
      items: [
        'اختبار المستخدم.',
        'توظيف المستخدمين المستهدفين.',
        'تطوير أنماط المستخدم (Persona).',
        'مقابلات المستخدمين وأصحاب المصلحة.',
        'إنشاء وتوزيع الاستبيانات.',
        'تحليل المنافسين.',
        'الاستفسارات السياقية (Contextual inquiries).',
        'مدى إمكانية إيجاد البيانات.',
        'تحليل وتلخيص البيانات المتاحة.',
        'رسم خرائط رحلة المستخدم.',
      ],
    },
    service2: {
      title: 'تصميم واجهة المستخدم (UI)',
      details:
        'نضيف على تجربة المستخدم جماليات بصرية رائعة، سواء كان ذلك عبر الموقع الإلكتروني أو تطبيق الجوال، حيث أننا نحرص على أن يكون التصميم ملتزماً بمبادئ التصميم التفاعلي لضمان الامتثال لأفضل ممارسات تجربة المستخدم.<1/>سواء كنت ترغب في تطوير وتطبيق أفكارك الجديدة أو ببساطة تحسين التصميم الحالي، سيعمل المصممون الموهوبون معك بدءًا من تطوير المفهوم والتصميم المبدئي انتهاءً بإنشاء واجهة مستخدم جذابة وفعالة.',
    },
    service3: {
      title: 'تجربة العميل (CX)',
      details:
        'نقيّم لك تجربة مشروعك أو عملك عبر كل نقطة اتصال، رقمية وغير رقمية، لمعرفة أفضل مكان لتركيز جهودك ومواردك لتحقيق التحسين الأمثل ورضا العملاء وولائهم.<1/>تركز خدمتنا حاليًا على الفهم العميق للرحلة الشاملة والتي يتم تحقيقها عبر مجموعة من الطرق التالية:',
      items: [
        'رسم خريطة رحلة العميل',
        'تحليل بيانات العملاء الحاليين',
        'قياس الأداء ومقاييس مؤشرات الأداء الرئيسية',
        'بناء مخطط تصميم الخدمة',
      ],
    },
    service4: {
      title: 'تجربة المستخدم والأعمال',
      details:
        'بناء استاتيجية تهدف إلى مواءمة أهداف العمل مع أهداف المستخدم، سواء كان ذلك في التحول الرقمي أو ابتكار المنتجات أو تطوير المفاهيم؛ نساعدك على إنشاء خريطة الطريق لتطوير تجربة المستخدم في مشروعك.<1/>يعدّ الوضوح حول كيفية خدمة المستخدم والشركات على حد سواء (دون استبعاد أحدهما عن الآخر) مفتاحاً أساسياً لزيادة الابتكار والتعاون ويؤدي إلى نجاح المشروع. تعرف على المزيد: <2 href="/article/KPZzyb7plM0zS60CiRSu">مقالة UX والأعمال</2>.',
      items: [
        'اكتشاف المشاكل الفعلية التي يجب حلها.',
        'الحصول على فهم أعمق لرغبات المستخدمين الأساسية.',
        'إنشاء خريطة طريق لتوجيه عملية اتخاذ قراراتك من أجل ضمان تجربة مرضية ومستمرة لمستخدميك',
        'تطوير خطة عمل لضمان تحقيق أهداف العمل وأنها تضيف قيمة حقيقية للمستخدمين من خلال التحقق المستمر من فعالية الإجراءات."',
      ],
    },
    service5: {
      title: 'ورش العمل',
      details:
        'إذا كنت بحاجة إلى دعم في هذا المجال، سواء حضورياً أو عن بعد، فنحن نقدم لك مجموعة متنوعة من ورش العمل المهنية ومجموعات التركيز (Focus groups) يشرف عليها مدربين ممارسين وخبراء حسب الحاجة لمساعدتك على التعلم والفهم والتفكير والإبداع. يتم تخصيص كل ورشة عمل اعتمادًا على ما يخدم متطلباتك وأهدافك على أفضل وجه.',
    },
    service6: {
      title: 'تقييم تجربة المستخدم (UX Assessment)',
      details:
        'قم بتقييم التجربة، ومراقبة التطور، ومقارنة الحلول، وتتبع تأثير التغييرات، ومقارنة مكانتك مع وضع المنافسين، تعرف على وضعك الحالي ومعرفة ما إذا كنت بحاجة إلى إجراء تغييرات أو ما إذا كانت التجربة الحالية في وضع جيد.<1/>نقدّم لك تقييمات عميقة لقابلية الاستخدام للحلول الحالية ونقدم توصيات بناءً على أفضل الممارسات، ويتم ذلك من خلال وسائل مختلفة حسب طبيعة الخدمة التي تقدمها، ومنها ما يلي:',
      items: [
        'التقييمات الإرشادية (مراجعات الخبراء)',
        'سهولة الاستخدام',
        'مقاييس قابلية الاستخدام الموحدة ومؤشرات الأداء الرئيسية',
        'تطوير منهجية التحليل',
        'تطوير منهجية اختبار A/B',
      ],
    },
    service7: {
      title: 'تصميم المحتوى (كتابة تجربة المستخدم).',
      details:
        'يلعب المحتوى دوراً رئيسياً في توجيه المستخدمين نحو أهدافهم وبناء رحلة سهلة وممتعة. الكلمات مهمة، ويمكن أن تعطي الحياة والحماس لأي منتج إذا تم اختيارها بشكل صحيح.<1/>تخبرك أبحاث كتابة تجربة المستخدم عن معلومات قيمة وما إذا كانت رسالتك إلى عميلك قد وصلت بشكل صحيح أو لم تصل. نقدم لك:',
      items: [
        'كتابة تجربة المستخدم.',
        'الاختبارات المتعلقة بالمحتوى النصي.',
        'دليل وقواعد كتابة المحتوى.',
        'تحليل وتطوير نبرة الصوت.',
        'قائمة المصطلحات.',
      ],
    },
    service8: {
      title: 'المنتجات المادية والصناعية (Ergonomics)',
      details:
        'تجربة المستخدم ليست مخصصة لمواقع الإنترنت والتطبيقات فقط بل تشمل كل ما يتفاعل معه الإنسان، فإذا كان منتجك أو نظامك أو عمليتك تتضمن تفاعلًا بشريًا، فإنها تحتاج إلى تجربة مستخدم رائعة.<1/>قد يكون هذا المنتج جهاز الدفع الذاتي أو الخدمة الذاتية أو لعبة أو سيارة أو تلفزيون أو جهاز تحكم عن بعد أو فرن أو حتى روبوت!<1/>ستسمح لك دراسة المنتجات المادية والصناعية (Ergonomics) بتقليل الأخطاء والعبء المعرفي وتحسين الكفاءة والفعالية مما يحسن تجربة العميل بشكل عام، ويقوم بهذه النوعية من الأبحاث فريق عمل يتميز بخبرة واسعة ومتنوعة في علم النفس والتفاعل البشري مع الكمبيوتر وتجربة المستخدم.',
    },
  },
  login: {
    signIn: 'تسجيل الدخول',
    email: 'البريد الإلكتروني',
    password: 'كلمة السر',
    enterEmail: 'أدخل بريدك الإلكتروني',
    enterPassword: 'أدخل كلمة السر',
  },
  admin: {
    article: {
      newArticle: 'إضافة مقال',
      editArticle: 'تعديل المقال',
      english: 'الإنجليزية',
      arabic: 'العربية',
      delete: 'حذف',
      deleteArticle: 'حذف المقال',
      deleteArticleInfo: 'هل أنت متأكد من رغبتك بحذف المقال؟',
      cancel: 'إلغاء',
      remove: 'إزالة',
      saveArticle: 'حفظ المقالة',
      addArticle: 'إضافة المقالة',
      discard: 'تجاهل',
      discardConfirmation: 'هل أنت متأكد من تجاهل التعديلات؟',
      title: 'العنوان',
      description: 'الوصف',
      writer: 'الكاتب',
      imageDesktop: 'صورة سطح المكتب (عمودي)',
      imageMobile: 'صورة الهاتف (افقي)',
      tags: 'العلامات',
      tagName: 'اسم العلامة',
      tagColor: 'لون العلامة',
      addTag: 'إضافة علامة',
      readingTime: 'وقت القراءة (دقيقة)',
      content: 'المحتوى',
      input: 'أدخل قيمة...',
      enterContent: 'أدخل محتوى المقالة...',
      fillDetails: 'برجاء تعبئة التفاصيل قبل المعاينة...',
      previewHome: 'معاينة بطاقة الصفحة الرئيسية',
      previewArticle: 'معاينة بطاقة صفحة المقالات',
      previewContent: 'معاينة المحتوى',
      mobile: 'هاتف',
      desktop: 'سطح المكتب',
    },
  },
};
