import { Box, styled } from '@mui/material';

export const Styled = {
  Container: styled(Box)(({ theme }) => ({
    padding: '36px',
    display: 'flex',
    gap: '18px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      padding: '18px',
    },
  })),
};
