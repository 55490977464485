/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useRef,
  useCallback,
  useMemo,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import { countries, CountryData } from './countries';

const slots = new Set('.');

export const isPhoneValid = (phone: string) => {
  // Remove leading '+' sign if present
  phone = phone.replace(/^\+/, '');

  // Find the matching phone code
  const phoneCode = countries.find((code) => phone.startsWith(code.code));

  if (!phoneCode) {
    return false; // Phone code not found
  }

  // Validate phone number format
  const phoneOnly = phone.replace(new RegExp(`^${phoneCode.code}`), ''); // Remove phone code
  const formattedPhone = cleanInput(
    phoneOnly,
    phoneCode.format.replaceAll(/\d|\s|\+/g, ''),
  )
    .join('')
    .trim();

  return formattedPhone == phoneOnly;
};

export const displayFormat = (value: string) => {
  /** Returns the formatted value that can be displayed as an actual input value */
  return value.replace(/[.\s\D]+$/, '').replace(/(\(\d+)$/, '$1)');
};

export const cleanInput = (input: any, pattern: string) => {
  input = input.match(/\d/g) || [];
  return Array.from(pattern, (c) =>
    input[0] === c || slots.has(c) ? input.shift() || c : c,
  );
};

export const getNumber = (rawValue: any, pattern: string) => {
  return displayFormat(
    cleanInput(rawValue, pattern.replaceAll(/\s/g, '')).join('').trim(),
  );
};

export const getFormattedNumber = (rawValue: any, pattern: string) => {
  return displayFormat(
    cleanInput(rawValue, pattern.replaceAll(/\d|\+/g, '')).join('').trim(),
  );
};

export const useMask = (country: CountryData) => {
  const pattern = country.format;
  const backRef = useRef<boolean>(false);

  const clean = useCallback(
    (input: any) => {
      return cleanInput(input, pattern.replaceAll(/\d/g, '.'));
    },
    [pattern],
  );

  const first = useMemo(() => {
    return [...pattern].findIndex((c) => slots.has(c));
  }, [pattern]);

  const prev = useMemo(
    (j = 0) => {
      return Array.from(pattern.replaceAll(/\d/g, '.'), (c, i) => {
        return slots.has(c) ? (j = i + 1) : j;
      });
    },
    [pattern],
  );

  const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    backRef.current = event.key === 'Backspace';
  }, []);

  const onInput = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const [i, j] = [target.selectionStart, target.selectionEnd].map(
        (i: any) => {
          i = clean(target.value.slice(0, i)).findIndex((c) => slots.has(c));
          return i < 0
            ? prev[prev.length - 1]
            : backRef.current
              ? prev[i - 1] || first
              : i;
        },
      );
      target.value = getFormattedNumber(target.value, pattern);
      target.setSelectionRange(i, j);
      backRef.current = false;
    },
    [clean, first, pattern, prev],
  );

  return {
    onInput,
    onKeyDown,
  };
};
