import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxProps } from '@mui/material';
import {
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
} from '@mui/icons-material';
import { Lang } from '@userpath/localization';
import { Styled } from './styles';

interface INavigationProps {
  onNextPressed?: () => void;
  onBackPressed?: () => void;
  nextEnabled?: boolean;
  backEnabled?: boolean;
  light?: boolean;
  direction?: 'row' | 'column';
}

const Navigation = React.forwardRef<
  HTMLDivElement,
  INavigationProps & Omit<BoxProps, 'ref'>
>(
  (
    {
      onNextPressed,
      onBackPressed,
      nextEnabled = true,
      backEnabled = true,
      light = true,
      direction = 'row',
      ...props
    },
    ref,
  ) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language == Lang.AR;
    const Back =
      direction == 'row' ? (isArabic ? ArrowForward : ArrowBack) : ArrowUpward;
    const Next =
      direction == 'row'
        ? isArabic
          ? ArrowBack
          : ArrowForward
        : ArrowDownward;
    return (
      <Styled.NavigationContainer
        ref={ref}
        flexDirection={direction}
        {...props}
      >
        <Styled.IconButton
          onClick={onBackPressed}
          disabled={!backEnabled}
          light={light}
        >
          <Back fontSize="size24" />
        </Styled.IconButton>
        <Styled.IconButton
          onClick={onNextPressed}
          disabled={!nextEnabled}
          light={light}
        >
          <Next fontSize="size24" />
        </Styled.IconButton>
      </Styled.NavigationContainer>
    );
  },
);

export default Navigation;
