import { styled } from '@mui/material';
import { ReactComponent as LogoWhite } from '@userpath/components/assets/svg/logo_white.svg';

export const Styled = {
  Logo: styled(LogoWhite)(({ theme }) => ({
    '@keyframes loaderAnimation': {
      '0%': {
        opacity: 0.4,
      },
      '100%': {
        opacity: 1,
      },
    },
    animation: 'loaderAnimation 0.7s ease-in-out infinite alternate',
    maxWidth: '80vw',
    width: '280px',
    transition: theme.transitions.create('opacity'),
  })),
};
