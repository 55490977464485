import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import {
  Box,
  FormControl,
  InputBaseProps,
  InputLabelProps,
} from '@mui/material';
import { IInputLabelProps } from './InputLabel';
import { AppInputLabel, Styled } from '.';

interface IInputFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = never,
> {
  name: Path<TFieldValues>;
  type?: string;
  control: Control<TFieldValues, TContext>;
  label?: string;
  labelProps?: IInputLabelProps & InputLabelProps;
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
  variantColor?: 'dark_background' | 'light_background' | 'red_background';
}

const InputField = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = never,
>({
  name,
  control,
  label,
  labelProps,
  defaultValue,
  variantColor,
  ...props
}: React.PropsWithChildren<
  IInputFieldProps<TFieldValues, TContext> &
    Omit<InputBaseProps, 'defaultValue' | 'name'>
>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box>
      {label && (
        <AppInputLabel
          id={`${name}-label`}
          htmlFor={name}
          variantColor={variantColor}
          {...labelProps}
        >
          {label}
        </AppInputLabel>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl variant="filled" fullWidth={props.fullWidth}>
            <Styled.Input
              {...props}
              name={name}
              id={name}
              variantColor={variantColor}
              onChange={onChange}
              value={value}
              error={!!error}
              aria-labelledby={`${name}-label`}
              aria-describedby={`${name}-helper`}
              size="small"
              sx={{
                backgroundColor: '#EEEEEE',
              }}
            />
            <Styled.HelperText
              id={`${name}-helper`}
              variantColor={variantColor}
              error={!!error}
            >
              {error?.message && t(error?.message)}
            </Styled.HelperText>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default InputField;
