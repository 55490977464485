import ReactGA from 'react-ga4';
import { createRoot } from 'react-dom/client';
import { Root } from './components/root';
import './index.css';

ReactGA.initialize('G-XD5N8DJFKN');

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<Root />);
