import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InputLabelProps, Typography } from '@mui/material';
import { Styled } from './styles';

export interface IInputLabelProps {
  variantColor?: 'dark_background' | 'light_background' | 'red_background';
  optional?: boolean;
}

const InputLabel: React.FC<
  React.PropsWithChildren<IInputLabelProps & InputLabelProps>
> = ({ children, optional = false, variantColor, ...props }) => {
  const { t } = useTranslation();
  return (
    <Styled.InputLabel variantColor={variantColor} {...props}>
      {children}
      {optional && '  '}
      {optional && (
        <Typography
          variant="subtitle2"
          component="span"
          sx={(theme) => ({
            color: theme.palette.text.neutral40,
          })}
        >
          {t('contact.optional')}
        </Typography>
      )}
    </Styled.InputLabel>
  );
};

export default InputLabel;
