import { Box, IconButton, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  IconButton: styled(IconButton, {
    shouldForwardProp: shouldForwardProp(['light']),
  })<{ light?: boolean }>(({ theme, light = false }) => ({
    color: light ? theme.palette.text.neutral : theme.palette.primary.main70,
    border: `2px solid`,
    '&.Mui-disabled': {
      color: light
        ? theme.palette.text.neutral50
        : theme.palette.text.neutral20,
    },
  })),
  NavigationContainer: styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      marginTop: '32px',
    },
  })),
};
