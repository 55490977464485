import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  UseFormGetValues,
  UseFormClearErrors,
  FieldPath,
  get,
} from 'react-hook-form';
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import { ImageInput, InputField } from '@userpath/components';
import { getImage, useToastContext } from '@userpath/utils';
import { Library } from '@userpath/constants';
import { ArticleDTO } from '@userpath/types';
import { IArticleInputs } from '../constants';
import { ContentInput } from './ContentInput';
import TagsInput from './TagsInput';
import { PreviewArticles, PreviewContent, PreviewHome } from './Preview';

interface IArticleInputsProps {
  shown: boolean;
  index: number;
  control: Control<IArticleInputs>;
  getValues: UseFormGetValues<IArticleInputs>;
  clearErrors: UseFormClearErrors<IArticleInputs>;
  disableFields?: boolean;
}

const getOtherValue = (
  values: IArticleInputs,
  key: FieldPath<IArticleInputs>,
): string | undefined => {
  const value = get<IArticleInputs>(values, key);
  return typeof value === 'string' && value.trim().length > 0
    ? value
    : undefined;
};

const ArticleInputs: React.FC<IArticleInputsProps> = ({
  shown,
  index,
  control,
  getValues,
  clearErrors,
  disableFields = false,
}) => {
  const { t } = useTranslation(Library.userpath);
  const { addToast } = useToastContext();
  const [showHomePreview, setShowHomePreview] = useState(false);
  const [showArticlesPreview, setShowArticlesPreview] = useState(false);
  const [showContentPreview, setShowContentPreview] = useState(false);
  const lang = index == 0 ? 'english' : 'arabic';
  const otherLang = index == 0 ? 'arabic' : 'english';
  const values = getValues();
  const validateArticle = useCallback(
    (fullValidation = true) => {
      const values = getValues();
      const isNotEmpty = (value: string | File | undefined) =>
        value != undefined &&
        (typeof value !== 'string' || value.trim().length > 0);
      let isValid = false;
      const hasTitle =
        isNotEmpty(values.title_english) || isNotEmpty(values.title_arabic);
      if (fullValidation) {
        const hasImage =
          isNotEmpty(values.image_desktop) && isNotEmpty(values.image_mobile);
        const hasWriter =
          isNotEmpty(values.writer_english) || isNotEmpty(values.writer_arabic);
        const hasDescription =
          isNotEmpty(values.description_english) ||
          isNotEmpty(values.description_arabic);
        isValid = hasTitle && hasImage && hasWriter && hasDescription;
      } else {
        const hasContent =
          isNotEmpty(values.content_english) ||
          isNotEmpty(values.content_arabic);
        isValid = hasTitle && hasContent;
      }
      if (!isValid) {
        addToast({ message: t('admin.article.fillDetails'), type: 'error' });
      }
      return isValid;
    },
    [addToast, getValues, t],
  );
  const previewHome = useCallback(() => {
    if (validateArticle()) setShowHomePreview(true);
  }, [validateArticle]);
  const previewArticles = useCallback(() => {
    if (validateArticle()) setShowArticlesPreview(true);
  }, [validateArticle]);
  const previewContent = useCallback(() => {
    if (validateArticle(false)) setShowContentPreview(true);
  }, [validateArticle]);

  const article: ArticleDTO = useMemo(
    () => ({
      id: '0',
      titleEnglish: values.title_english,
      titleArabic: values.title_arabic,
      descriptionEnglish: values.description_english,
      descriptionArabic: values.description_arabic,
      writerEnglish: values.writer_english,
      writerArabic: values.writer_arabic,
      verticalImage: getImage(values.image_desktop),
      horizontalImage: getImage(values.image_mobile),
      createdDate: new Date().getTime() - 259200000, // 3 days
      updatedDate: new Date().getTime(),
      readingTime: values.reading_time
        ? Number(values.reading_time)
        : undefined,
      tags: values.tags?.map((tag) => ({
        titleEnglish: tag.title_english,
        titleArabic: tag.title_arabic,
        color: tag.color,
      })),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(values)],
  );

  return (
    <React.Fragment>
      <div
        role="tabpanel"
        hidden={!shown}
        id={`article-tabpanel-${index}`}
        aria-labelledby={`article-tab-${index}`}
        style={{ margin: '18px', flex: 1 }}
      >
        {shown && (
          <Box>
            <Grid container spacing="18px">
              <Grid item xs={12} md={6}>
                <InputField<IArticleInputs>
                  control={control}
                  variantColor="light_background"
                  name={`title_${lang}`}
                  label={t('admin.article.title')}
                  type="text"
                  inputMode="text"
                  readOnly={disableFields}
                  fullWidth
                  placeholder={
                    getOtherValue(values, `title_${otherLang}`) ??
                    t('admin.article.input')
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <ImageInput<IArticleInputs>
                  control={control}
                  variantColor="light_background"
                  name="image_desktop"
                  label={t('admin.article.imageDesktop')}
                  readOnly={disableFields}
                  clearErrors={clearErrors}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageInput<IArticleInputs>
                  control={control}
                  variantColor="light_background"
                  name="image_mobile"
                  label={t('admin.article.imageMobile')}
                  readOnly={disableFields}
                  clearErrors={clearErrors}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <InputField<IArticleInputs>
                  control={control}
                  variantColor="light_background"
                  name={`description_${lang}`}
                  label={t('admin.article.description')}
                  type="text"
                  inputMode="text"
                  readOnly={disableFields}
                  rows={5}
                  multiline
                  fullWidth
                  placeholder={
                    getOtherValue(values, `description_${otherLang}`) ??
                    t('admin.article.input')
                  }
                />
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing="18px">
              <Grid item xs={12} md={6}>
                <InputField<IArticleInputs>
                  control={control}
                  variantColor="light_background"
                  name={`writer_${lang}`}
                  label={t('admin.article.writer')}
                  type="text"
                  inputMode="text"
                  autoComplete="name"
                  readOnly={disableFields}
                  fullWidth
                  placeholder={
                    getOtherValue(values, `writer_${otherLang}`) ??
                    t('admin.article.input')
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputField<IArticleInputs>
                  control={control}
                  variantColor="light_background"
                  name="reading_time"
                  label={t('admin.article.readingTime')}
                  type="number"
                  inputMode="numeric"
                  readOnly={disableFields}
                  fullWidth
                  labelProps={{ optional: true }}
                  placeholder={t('admin.article.input')}
                />
              </Grid>
            </Grid>
            <hr />
            <TagsInput
              control={control}
              index={index}
              disableFields={disableFields}
              getOtherValue={(key: FieldPath<IArticleInputs>) =>
                getOtherValue(values, key)
              }
            />
            <hr />
            <ContentInput
              key={`content_${lang}`}
              control={control}
              index={index}
              disableFields={disableFields}
              getOtherValue={(key: FieldPath<IArticleInputs>) =>
                getOtherValue(values, key)
              }
            />
            <hr />
            <Box textAlign="center" marginTop="24px">
              <ButtonGroup variant="contained" color="info">
                <Button onClick={previewHome}>
                  {t('admin.article.previewHome')}
                </Button>
                <Button onClick={previewArticles}>
                  {t('admin.article.previewArticle')}
                </Button>
                <Button onClick={previewContent}>
                  {t('admin.article.previewContent')}
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        )}
      </div>
      <PreviewArticles
        article={article}
        open={showArticlesPreview}
        onClose={() => setShowArticlesPreview(false)}
      />
      <PreviewContent
        article={article}
        contentEnglish={{ id: '0', content: values.content_english }}
        contentArabic={{ id: '0', content: values.content_arabic }}
        open={showContentPreview}
        onClose={() => setShowContentPreview(false)}
      />
      <PreviewHome
        article={article}
        open={showHomePreview}
        onClose={() => setShowHomePreview(false)}
      />
    </React.Fragment>
  );
};

export default ArticleInputs;
