import { useCallback } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import {
  FirebaseStorage,
  StorageReference,
  uploadBytes,
  ref,
  getDownloadURL,
} from 'firebase/storage';
import {
  PartialWithFieldValue,
  serverTimestamp,
} from 'firebase/firestore/lite';
import { useArticles } from '@userpath/services';
import { AppError, ArticleDTO } from '@userpath/types';
import { useStorage } from '@userpath/components';
import { getExtension } from '@userpath/utils';
import { IArticleInputs } from './constants';

const processHtmlImages = async (
  storage: FirebaseStorage,
  path: string,
  html?: string,
): Promise<string | undefined> => {
  if (!html || html.trim().length == 0 || html.trim() == '<p></p>') return;
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const images = doc.querySelectorAll('img');

  for (const image of images) {
    const src = image.getAttribute('src');
    if (src && src.startsWith('blob:')) {
      const url = await uploadImage(
        storage,
        src,
        `${path}/${crypto.randomUUID()}`,
      );
      image.setAttribute('src', url);
    }
  }
  return doc.body.innerHTML;
};

const fetchImage = async (link: string) => {
  try {
    return await (await fetch(link)).blob();
  } catch (error) {
    throw new Error('blob_to_image_failed');
  }
};

const uploadImage = async (
  storage: FirebaseStorage,
  image: string | File,
  path: string,
  addExtension = true,
) => {
  if (typeof image !== 'string' || image.startsWith('blob:')) {
    const blob = typeof image !== 'string' ? image : await fetchImage(image);
    let fileRef: StorageReference;
    if (addExtension) {
      const ext = getExtension(blob.type);
      if (ext == undefined) {
        throw new Error('invalid_mime_type');
      }
      fileRef = ref(storage, `${path}.${ext}`);
    } else {
      fileRef = ref(storage, path);
    }
    const file = await uploadBytes(fileRef, blob, {
      contentType: blob.type,
    });
    return await getDownloadURL(file.ref);
  } else {
    return image;
  }
};

export const useArticleSaver = (
  isNew: boolean,
  setValue: UseFormSetValue<IArticleInputs>,
) => {
  const storage = useStorage();
  const articlesServices = useArticles();
  const saveArticle = useCallback(
    async (article: IArticleInputs) => {
      // if(data != undefined && data.id == id) return
      if (isNew && article.id == undefined) {
        const newId = articlesServices.generateId();
        article.id = newId;
        setValue('id', newId);
      }
      if (article.id == undefined) {
        throw new Error('no_id');
      }
      try {
        if (article.image_desktop) {
          const desktopImage = await uploadImage(
            storage,
            article.image_desktop,
            `articles/${article.id}/desktop`,
          );
          if (article.image_desktop != desktopImage) {
            article.image_desktop = desktopImage;
            setValue('image_desktop', desktopImage);
          }
        }
        if (article.image_mobile) {
          const mobileImage = await uploadImage(
            storage,
            article.image_mobile,
            `articles/${article.id}/mobile`,
          );
          if (article.image_mobile != mobileImage) {
            article.image_mobile = mobileImage;
            setValue('image_mobile', mobileImage);
          }
        }
        const contentEnglish = await processHtmlImages(
          storage,
          `articles/${article.id}/images/`,
          article.content_english,
        );
        if (article.content_english != contentEnglish) {
          setValue('content_english', contentEnglish);
        }
        const contentArabic = await processHtmlImages(
          storage,
          `articles/${article.id}/images/`,
          article.content_arabic,
        );
        if (article.content_arabic != contentArabic) {
          setValue('content_arabic', contentArabic);
        }
        const articleDTO: PartialWithFieldValue<ArticleDTO> = {
          ...(isNew && {
            createdDate: serverTimestamp(),
            updatedDate: serverTimestamp(),
          }),
          ...(!isNew && {
            updatedDate: serverTimestamp(),
          }),
          verticalImage: article.image_desktop,
          horizontalImage: article.image_mobile,
          titleEnglish: article.title_english,
          titleArabic: article.title_arabic,
          writerEnglish: article.writer_english,
          writerArabic: article.writer_arabic,
          descriptionEnglish: article.description_english,
          descriptionArabic: article.description_arabic,
          readingTime: Number(article.reading_time),
          tags: article.tags?.map((tag) => ({
            color: tag.color,
            titleEnglish: tag.title_english,
            titleArabic: tag.title_arabic,
          })),
          hasEnglishContent: contentEnglish != undefined,
          hasArabicContent: contentArabic != undefined,
        };
        await articlesServices.updateArticle(article.id, articleDTO);
        if (contentEnglish != undefined) {
          await articlesServices.updateArticleContent(article.id, 'en', {
            content: contentEnglish,
          });
        }
        if (contentArabic) {
          await articlesServices.updateArticleContent(article.id, 'ar', {
            content: contentArabic,
          });
        }
        return article.id;
      } catch (error) {
        throw AppError.fromError(error);
      }
    },
    [articlesServices, isNew, setValue, storage],
  );

  return saveArticle;
};
