import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useFirebaseAuth } from '@userpath/components';

const AdminPage: React.FC = () => {
  const { isLoggedIn } = useFirebaseAuth();
  const location = useLocation();
  const isLoginPage = location.pathname.startsWith('/admin/login');
  if (isLoggedIn == undefined) {
    return (
      <Box
        sx={{
          margin: '64px 0px',
          padding: '18px',
          textAlign: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!isLoggedIn && !isLoginPage) {
    return <Navigate to="/admin/login" replace />;
  } else if (isLoggedIn && (isLoginPage || location.pathname == '/admin')) {
    return <Navigate to="/admin/articles" replace />;
  }

  return <Outlet />;
};

export default AdminPage;
