// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import { Typography, alpha, styled } from '@mui/material';

export const Styled = {
  Title: styled(Typography)(({ theme }) => ({
    ...theme.typography.h3,
    fontWeight: 'bold',
    color: theme.palette.text.neutral,
  })),
  Swiper: styled(Swiper)(({ theme }) => ({
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      marginLeft: '-24px !important',
      marginRight: '-24px !important',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '-104px !important',
      marginRight: '-104px !important',
      '&::after, &::before': {
        zIndex: 4,
        content: '""',
        position: 'absolute',
        top: 0,
        width: 'calc(100% * 0.24)',
        height: '100%',
        pointerEvents: 'none',
      },
      '&::before': {
        left: 0,
        background: `linear-gradient(90deg, 
          ${alpha(theme.palette.background.primary, 1)} 0%, 
          ${alpha(theme.palette.background.primary, 0.98)} 27.42%, 
          ${alpha(theme.palette.background.primary, 0.81)} 61.9%, 
          ${alpha(theme.palette.background.primary, 0.0)} 100%);`,
      },
      '&::after': {
        right: 0,
        background: `linear-gradient(90deg, 
          ${alpha(theme.palette.background.primary, 0.0)} 0%, 
          ${alpha(theme.palette.background.primary, 0.81)} 27.42%, 
          ${alpha(theme.palette.background.primary, 0.98)} 61.9%, 
          ${alpha(theme.palette.background.primary, 1)} 100%);`,
      },
    },
  })),
  SwiperSlide: styled(SwiperSlide)({
    height: 'auto',
    boxSizing: 'border-box',
  }),
};
