import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Flipped } from 'react-flip-toolkit';
import { Box, Link } from '@mui/material';
import { Library } from '@userpath/constants';
import { ServiceIndex, ServiceIcon } from '../../../common';
import { Styled } from './styles';

interface IServiceContentProps {
  index: ServiceIndex;
}

const ServiceContent: React.FC<IServiceContentProps> = ({ index }) => {
  const { t } = useTranslation(Library.userpath);
  return (
    <Flipped flipId="content" spring="wobbly">
      <Box display="flex" gap="16px" flexDirection="column">
        <Flipped flipId={`content_icon_${index}`} stagger="content">
          <Box>
            <ServiceIcon index={index} size={48} />
          </Box>
        </Flipped>
        <Flipped flipId={`content_title_${index}`} stagger="content">
          <Styled.Title>{t(`services.${index}.title`)}</Styled.Title>
        </Flipped>
        <Flipped flipId={`content_content_${index}`} stagger="content">
          <Styled.Details>
            <Trans
              ns={Library.userpath}
              i18nKey={`services.${index}.details`}
              components={{
                1: <br />,
                2: <Link />,
              }}
            />
          </Styled.Details>
        </Flipped>
      </Box>
    </Flipped>
  );
};

export default ServiceContent;
