import { MuiColorInput } from 'mui-color-input';
import {
  Box,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  OutlinedInput,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

interface IStyledInputProps {
  variantColor?: 'dark_background' | 'light_background' | 'red_background';
}

export const Styled = {
  InputLabel: styled(InputLabel, {
    shouldForwardProp: shouldForwardProp(['variantColor']),
  })<IStyledInputProps>(({ variantColor = 'light_background', theme }) => ({
    ...theme.typography.caption,
    marginBottom: '4px',
    color:
      variantColor == 'light_background'
        ? theme.palette.text.inputDark
        : theme.palette.text.neutral,
  })),
  HelperText: styled(FormHelperText, {
    shouldForwardProp: shouldForwardProp(['variantColor']),
  })<IStyledInputProps>(({ variantColor = 'light_background', theme }) => ({
    margin: '3px 0px 0px 0px',
    color: theme.palette.text.inputLight,
    '&.Mui-error': {
      color:
        variantColor != 'red_background'
          ? theme.palette.text.danger
          : theme.palette.text.dangerLight,
    },
  })),
  Input: styled(InputBase, {
    shouldForwardProp: shouldForwardProp(['variantColor']),
  })<IStyledInputProps>(
    ({ variantColor = 'light_background', theme, error }) => ({
      backgroundColor: 'unset !important',

      '& .MuiInputBase-input': {
        scrollbarColor: `${
          variantColor != 'light_background'
            ? theme.palette.text.inputLight
            : theme.palette.text.inputDark
        } #0000`,

        '&::placeholder': {
          color:
            variantColor != 'light_background'
              ? theme.palette.text.neutral
              : theme.palette.text.neutral80,
        },
        color:
          variantColor != 'light_background'
            ? theme.palette.text.inputLight
            : theme.palette.text.inputDark,
        borderRadius: 4,
        backgroundColor:
          variantColor != 'light_background'
            ? alpha(theme.palette.background.input, 0.08)
            : theme.palette.background.default,
        border: '1px solid',
        borderColor: error
          ? theme.borders.inputError
          : variantColor == 'light_background'
            ? theme.palette.text.neutral
            : theme.borders.input,
        fontSize: theme.typography.sizes.h8,
        padding: '8px',
        transition: theme.transitions.create([
          'border-color',
          'background-color',
          'box-shadow',
        ]),
        fontFamily: theme.typography.fontFamily,
        '&:hover': {
          borderColor: theme.borders.selectedInput,
        },
        '&:focus': {
          borderColor: theme.palette.primary.main,
        },
      },
    }),
  ),
  ImageInput: styled(Box, {
    shouldForwardProp: shouldForwardProp([
      'variantColor',
      'error',
      'isDrag',
      'isInvalidDrag',
    ]),
  })<
    IStyledInputProps & {
      error?: boolean;
      isDrag?: boolean;
      isInvalidDrag?: boolean;
    }
  >(
    ({
      variantColor = 'light_background',
      theme,
      error,
      isDrag,
      isInvalidDrag,
    }) => ({
      cursor: 'pointer',
      width: '100%',
      height: '250px',
      borderRadius: 4,
      position: 'relative',
      fontSize: theme.typography.sizes.h8,
      backgroundColor:
        variantColor != 'light_background'
          ? alpha(theme.palette.background.input, 0.08)
          : theme.palette.background.default,
      border: '1px solid',
      borderColor: error
        ? theme.borders.inputError
        : variantColor == 'light_background'
          ? theme.palette.text.neutral
          : theme.borders.input,
      color:
        variantColor != 'light_background'
          ? theme.palette.text.inputLight
          : theme.palette.text.inputDark,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      ...((isDrag || isInvalidDrag) && {
        borderColor: isInvalidDrag
          ? theme.borders.inputError
          : theme.borders.selectedInput,
      }),
      '&:hover': {
        borderColor: theme.borders.selectedInput,
      },
      '&:focus': {
        borderColor: theme.palette.primary.main,
      },
    }),
  ),
  Content: styled(Box, {
    shouldForwardProp: shouldForwardProp(['clickable']),
  })<{ clickable?: boolean }>(({ clickable }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: '8px',
    ...(clickable && {
      cursor: 'pointer',
    }),
  })),
  DropboxText: styled(Typography, {
    shouldForwardProp: shouldForwardProp(['error']),
  })<{ error?: boolean }>(({ theme, error }) => ({
    ...theme.typography.h8,
    color: error ? theme.palette.error.main : theme.palette.text.neutral80,
    textAlign: 'center',
  })),
  Image: styled('img')({
    borderRadius: 4,
    objectFit: 'cover',
    objectPosition: 'center',
    width: '100%',
    height: '100%',
  }),
  RemoveIcon: styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: '8px',
    ...theme.directional({
      right: '8px',
    }),
  })),
  ColorInput: styled(MuiColorInput, {
    shouldForwardProp: shouldForwardProp(['variantColor']),
  })<IStyledInputProps>(
    ({ variantColor = 'light_background', theme, error }) => ({
      backgroundColor:
        variantColor != 'light_background'
          ? alpha(theme.palette.background.input, 0.08)
          : theme.palette.background.default,
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderRadius: 4,
        borderColor: error
          ? theme.borders.inputError
          : variantColor == 'light_background'
            ? theme.palette.text.neutral
            : theme.borders.input,
        transition: theme.transitions.create(['border-color']),
        '&:hover': {
          borderColor: theme.borders.selectedInput,
        },
      },
      '& .Mui-focused > .MuiOutlinedInput-notchedOutline': {
        border: '1px solid !important',
        borderColor: `${theme.palette.primary.main} !important`,
      },
      '& .MuiColorInput-TextField': {
        padding: '8px',
        fontSize: theme.typography.sizes.h8,
        fontFamily: theme.typography.fontFamily,
        color:
          variantColor != 'light_background'
            ? theme.palette.text.inputLight
            : theme.palette.text.inputDark,
        '&::placeholder': {
          color:
            variantColor != 'light_background'
              ? theme.palette.text.neutral
              : theme.palette.text.neutral80,
        },
      },
    }),
  ),
  PhoneInput: styled(OutlinedInput, {
    shouldForwardProp: shouldForwardProp(['variantColor']),
  })<IStyledInputProps>(
    ({ variantColor = 'light_background', theme, error }) => ({
      backgroundColor:
        variantColor != 'light_background'
          ? `${alpha(theme.palette.background.input, 0.08)} !important`
          : `${theme.palette.background.default} !important`,
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderRadius: 4,
        borderColor: error
          ? theme.borders.inputError
          : variantColor == 'light_background'
            ? theme.palette.text.neutral
            : theme.borders.input,
        transition: theme.transitions.create(['border-color']),
        '&:hover': {
          borderColor: theme.borders.selectedInput,
        },
      },
      '&.Mui-focused > .MuiOutlinedInput-notchedOutline': {
        border: '1px solid !important',
        borderColor: `${theme.palette.primary.main} !important`,
      },
      '& .MuiInputBase-input': {
        padding: '8.75px',
        fontSize: theme.typography.sizes.h8,
        fontFamily: theme.typography.fontFamily,
        color:
          variantColor != 'light_background'
            ? theme.palette.text.inputLight
            : theme.palette.text.inputDark,
        '&::placeholder': {
          color:
            variantColor != 'light_background'
              ? theme.palette.text.neutral
              : theme.palette.text.neutral80,
        },
      },
    }),
  ),
};
