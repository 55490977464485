import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  getAuth,
  AuthErrorCodes,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import {
  Box,
  Typography,
  Button,
  Paper,
  CircularProgress,
} from '@mui/material';
import { useFirebaseApp } from '@userpath/utils';
import { Library } from '@userpath/constants';
import { CustomContainer, InputField, yupResolver } from '@userpath/components';
import { validationSchema, defaultValues, ILoginInputs } from './constants';

const LoginPage: React.FC = () => {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const navigate = useNavigate();
  const resolver = yupResolver(validationSchema);
  const { t } = useTranslation(Library.userpath);
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control } = useForm<ILoginInputs>({
    resolver,
    defaultValues: defaultValues,
  });
  const onSubmit = async (data: ILoginInputs) => {
    try {
      setIsLoading(true);
      const user = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );
      navigate('/admin/articles');
      console.log(user);
    } catch (error) {
      console.log('Error', error);
      if (typeof error === 'object' && error !== null && 'code' in error) {
        switch (error.code) {
          case AuthErrorCodes.INVALID_LOGIN_CREDENTIALS:
            control.setError('email', { message: 'errors.invalidCredentials' });
            break;
          case AuthErrorCodes.INVALID_PASSWORD:
            control.setError('password', { message: 'errors.invalidPassword' });
            break;
          default:
            control.setError('email', {
              message: t('errors.loginFailed', { code: error.code }),
            });
            break;
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomContainer maxWidth="md">
        <Paper
          elevation={4}
          sx={(theme) => ({
            margin: '64px 0px',
            padding: '18px',
            borderRadius: '12px',
            bgcolor: theme.palette.background.paper,
          })}
        >
          <Typography component="h1" variant="h3" textAlign="center">
            {t('login.signIn')}
          </Typography>
          <Box sx={{ mt: '24px' }}>
            <InputField<ILoginInputs>
              name="email"
              label={t('login.email')}
              type="email"
              inputMode="email"
              placeholder={t('login.enterEmail')}
              control={control}
              variantColor="light_background"
              autoComplete="email"
              readOnly={isLoading}
              autoFocus
              fullWidth
            />
            <InputField<ILoginInputs>
              name="password"
              label={t('login.password')}
              type="password"
              placeholder={t('login.enterPassword')}
              control={control}
              variantColor="light_background"
              autoComplete="password"
              readOnly={isLoading}
              labelProps={{
                sx: { marginTop: '18px' },
              }}
              fullWidth
            />
            <Box sx={{ textAlign: 'center', mt: '18px', mb: '12px' }}>
              {isLoading && <CircularProgress />}
              {!isLoading && (
                <Button type="submit" variant="contained" sx={{ width: '50%' }}>
                  {t('login.signIn')}
                </Button>
              )}
            </Box>
          </Box>
        </Paper>
      </CustomContainer>
    </form>
  );
};

export default LoginPage;
