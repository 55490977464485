export interface CountryData {
  iso2: string;
  en: string;
  ar: string;
  code: string;
  format: string;
}

// cSpell: disable
export const countries: CountryData[] = [
  {
    iso2: 'SA',
    en: 'Saudi Arabia',
    ar: 'السعودية',
    code: '966',
    format: '+966 .. ... ....',
  },
  {
    iso2: 'AF',
    en: 'Afghanistan',
    ar: 'أفغانستان',
    code: '93',
    format: '+93 ... ... ....',
  },
  {
    iso2: 'AL',
    en: 'Albania',
    ar: 'ألبانيا',
    code: '355',
    format: '+355 .. ... ....',
  },
  {
    iso2: 'DZ',
    en: 'Algeria',
    ar: 'الجزائر',
    code: '213',
    format: '+213 . ... ....',
  },
  {
    iso2: 'AS',
    en: 'American Samoa',
    ar: 'ساموا الأمريكية',
    code: '1684',
    format: '+1 (684) ... ....',
  },
  {
    iso2: 'AD',
    en: 'Andorra',
    ar: 'أندورا',
    code: '376',
    format: '+376 ... ... ....',
  },
  {
    iso2: 'AO',
    en: 'Angola',
    ar: 'أنغولا',
    code: '244',
    format: '+244 .... ... ....',
  },
  {
    iso2: 'AI',
    en: 'Anguilla',
    ar: 'أنغويلا',
    code: '1264',
    format: '+1 (264) ... ....',
  },
  {
    iso2: 'AG',
    en: 'Antigua and Barbuda',
    ar: 'أنتيغوا وباربودا',
    code: '1268',
    format: '+1 (268) ... ....',
  },
  {
    iso2: 'AR',
    en: 'Argentina',
    ar: 'الأرجنتين',
    code: '54',
    format: '+54 . ... ....',
  },
  {
    iso2: 'AM',
    en: 'Armenia',
    ar: 'أرمينيا',
    code: '374',
    format: '+374 ... ... ....',
  },
  {
    iso2: 'AW',
    en: 'Aruba',
    ar: 'أروبا',
    code: '297',
    format: '+297 .. ... ....',
  },
  {
    iso2: 'AU',
    en: 'Australia',
    ar: 'أستراليا',
    code: '61',
    format: '+61 ... ... ....',
  },
  {
    iso2: 'AT',
    en: 'Austria',
    ar: 'النمسا',
    code: '43',
    format: '+43 ... ... ....',
  },
  {
    iso2: 'AZ',
    en: 'Azerbaijan',
    ar: 'أذربيجان',
    code: '994',
    format: '+994 ... ... ....',
  },
  {
    iso2: 'BS',
    en: 'Bahamas',
    ar: 'باهاماس',
    code: '1242',
    format: '+1 (242) ... ....',
  },
  {
    iso2: 'BH',
    en: 'Bahrain',
    ar: 'البحرين',
    code: '973',
    format: '+973 ... ... ....',
  },
  {
    iso2: 'BD',
    en: 'Bangladesh',
    ar: 'بنغلاديش',
    code: '880',
    format: '+880 ... ... ....',
  },
  {
    iso2: 'BB',
    en: 'Barbados',
    ar: 'باربادوس',
    code: '1246',
    format: '+1 (246) ... ....',
  },
  {
    iso2: 'BY',
    en: 'Belarus',
    ar: 'بيلاروس',
    code: '375',
    format: '+375 ... ... ....',
  },
  {
    iso2: 'BE',
    en: 'Belgium',
    ar: 'بلجيكا',
    code: '32',
    format: '+32 ... ... ....',
  },
  {
    iso2: 'BZ',
    en: 'Belize',
    ar: 'بليز',
    code: '501',
    format: '+501 ... ....',
  },
  {
    iso2: 'BJ',
    en: 'Benin',
    ar: 'بنين',
    code: '229',
    format: '+229 ... ... ....',
  },
  {
    iso2: 'BM',
    en: 'Bermuda',
    ar: 'برمودا',
    code: '1441',
    format: '+1 (441) ... ....',
  },
  {
    iso2: 'BT',
    en: 'Bhutan',
    ar: 'بوتان',
    code: '975',
    format: '+975 .. ... ....',
  },
  {
    iso2: 'BA',
    en: 'Bosnia and Herzegovina',
    ar: 'البوسنة والهرسك',
    code: '387',
    format: '+387 ... ... ....',
  },
  {
    iso2: 'BW',
    en: 'Botswana',
    ar: 'بوتسوانا',
    code: '267',
    format: '+267 ... ....',
  },
  {
    iso2: 'BR',
    en: 'Brazil',
    ar: 'البرازيل',
    code: '55',
    format: '+55 (..) ....-....',
  },
  {
    iso2: 'IO',
    en: 'British Indian Ocean Territory',
    ar: 'إقليم المحيط الهندي البريطاني',
    code: '246',
    format: '+246 ... ....',
  },
  {
    iso2: 'BG',
    en: 'Bulgaria',
    ar: 'بلغاريا',
    code: '359',
    format: '+359 . ... ....',
  },
  {
    iso2: 'BF',
    en: 'Burkina Faso',
    ar: 'بوركينا فاسو',
    code: '226',
    format: '+226 . ... ....',
  },
  {
    iso2: 'BI',
    en: 'Burundi',
    ar: 'بوروندي',
    code: '257',
    format: '+257 ... ....',
  },
  {
    iso2: 'KH',
    en: 'Cambodia',
    ar: 'كمبوديا',
    code: '855',
    format: '+855 .. ... ....',
  },
  {
    iso2: 'CM',
    en: 'Cameroon',
    ar: 'الكاميرون',
    code: '237',
    format: '+237 . ... ....',
  },
  {
    iso2: 'US',
    en: 'United States of America',
    ar: 'الولايات المتحدة',
    code: '1',
    format: '+1 (...) ... ....',
  },
  {
    iso2: 'CA',
    en: 'Canada',
    ar: 'كندا',
    code: '1',
    format: '+1 (...) ... ....',
  },
  {
    iso2: 'CV',
    en: 'Cabo Verde',
    ar: 'الرأس الأخضر',
    code: '238',
    format: '+238 ... ....',
  },
  {
    iso2: 'KY',
    en: 'Cayman Islands',
    ar: 'جزر كايمان',
    code: ' 345',
    format: '+  (345) ... ....',
  },
  {
    iso2: 'CF',
    en: 'Central African Republic',
    ar: 'جمهورية أفريقيا الوسطى',
    code: '236',
    format: '+236 . ... ....',
  },
  {
    iso2: 'TD',
    en: 'Chad',
    ar: 'تشاد',
    code: '235',
    format: '+235 . ... ....',
  },
  {
    iso2: 'CL',
    en: 'Chile',
    ar: 'تشيلي',
    code: '56',
    format: '+56 .. ... ....',
  },
  {
    iso2: 'CN',
    en: 'China',
    ar: 'الصين',
    code: '86',
    format: '+86 ... .... ....',
  },
  {
    iso2: 'CX',
    en: 'Christmas Island',
    ar: 'جزيرة عيد الميلاد',
    code: '61',
    format: '+61 . .... ....',
  },
  {
    iso2: 'CO',
    en: 'Colombia',
    ar: 'كولومبيا',
    code: '57',
    format: '+57 ... ... ....',
  },
  {
    iso2: 'KM',
    en: 'Comoros',
    ar: 'جزر القمر',
    code: '269',
    format: '+269 ... ....',
  },
  {
    iso2: 'CG',
    en: 'Congo (Republic of the)',
    ar: 'جمهورية الكونغو',
    code: '242',
    format: '+242 .. ... ....',
  },
  {
    iso2: 'CK',
    en: 'Cook Islands',
    ar: 'جزر كوك',
    code: '682',
    format: '+682 68 2..',
  },
  {
    iso2: 'CR',
    en: 'Costa Rica',
    ar: 'كوستاريكا',
    code: '506',
    format: '+506 . ... ....',
  },
  {
    iso2: 'HR',
    en: 'Croatia',
    ar: 'كرواتيا',
    code: '385',
    format: '+385 .. ... ....',
  },
  {
    iso2: 'CU',
    en: 'Cuba',
    ar: 'كوبا',
    code: '53',
    format: '+53 ... ... ....',
  },
  {
    iso2: 'CY',
    en: 'Cyprus',
    ar: 'قبرص',
    code: '357',
    format: '+357 . ... ....',
  },
  {
    iso2: 'CZ',
    en: 'Czech Republic',
    ar: 'جمهورية التشيك',
    code: '420',
    format: '+420 .. ... ....',
  },
  {
    iso2: 'DK',
    en: 'Denmark',
    ar: 'الدنمارك',
    code: '45',
    format: '+45 . ... ....',
  },
  {
    iso2: 'DJ',
    en: 'Djibouti',
    ar: 'جيبوتي',
    code: '253',
    format: '+253 . ... ....',
  },
  {
    iso2: 'DM',
    en: 'Dominica',
    ar: 'دومينيكا',
    code: '1767',
    format: '+1767 ... ....',
  },
  {
    iso2: 'DO',
    en: 'Dominican Republic',
    ar: 'جمهورية الدومينيكان',
    code: '1809',
    format: '+1809 ... ....',
  },
  {
    iso2: 'EC',
    en: 'Ecuador',
    ar: 'الإكوادور',
    code: '593',
    format: '+593 .. ... ....',
  },
  {
    iso2: 'EG',
    en: 'Egypt',
    ar: 'مصر',
    code: '20',
    format: '+20 ... ... ....',
  },
  {
    iso2: 'SV',
    en: 'El Salvador',
    ar: 'السلفادور',
    code: '503',
    format: '+503 .. .... ....',
  },
  {
    iso2: 'GQ',
    en: 'Equatorial Guinea',
    ar: 'غينيا الاستوائية',
    code: '240',
    format: '+240 .. ... ....',
  },
  {
    iso2: 'ER',
    en: 'Eritrea',
    ar: 'إريتريا',
    code: '291',
    format: '+291 ... ....',
  },
  {
    iso2: 'EE',
    en: 'Estonia',
    ar: 'إستونيا',
    code: '372',
    format: '+372 ... ...',
  },
  {
    iso2: 'ET',
    en: 'Ethiopia',
    ar: 'إثيوبيا',
    code: '251',
    format: '+251 .. ... ....',
  },
  {
    iso2: 'FO',
    en: 'Faroe Islands',
    ar: 'جزر فارو',
    code: '298',
    format: '+298 ......',
  },
  {
    iso2: 'FJ',
    en: 'Fiji',
    ar: 'فيجي',
    code: '679',
    format: '+679 ... ....',
  },
  {
    iso2: 'FI',
    en: 'Finland',
    ar: 'فنلندا',
    code: '358',
    format: '+358 .. ... ....',
  },
  {
    iso2: 'FR',
    en: 'France',
    ar: 'فرنسا',
    code: '33',
    format: '+33 . .. .. .. ..',
  },
  {
    iso2: 'GF',
    en: 'French Guiana',
    ar: 'غويانا الفرنسية',
    code: '594',
    format: '+594 ... .. .. ..',
  },
  {
    iso2: 'PF',
    en: 'French Polynesia',
    ar: 'بولينزيا الفرنسية',
    code: '689',
    format: '+689 ......',
  },
  {
    iso2: 'GA',
    en: 'Gabon',
    ar: 'الغابون',
    code: '241',
    format: '+241 ......',
  },
  {
    iso2: 'GM',
    en: 'Gambia',
    ar: 'غامبيا',
    code: '220',
    format: '+220 ... ....',
  },
  {
    iso2: 'GE',
    en: 'Georgia',
    ar: 'جورجيا',
    code: '995',
    format: '+995 ... ......',
  },
  {
    iso2: 'DE',
    en: 'Germany',
    ar: 'ألمانيا',
    code: '49',
    format: '+49 .. .........',
  },
  {
    iso2: 'GH',
    en: 'Ghana',
    ar: 'غانا',
    code: '233',
    format: '+233 .. ... ....',
  },
  {
    iso2: 'GI',
    en: 'Gibraltar',
    ar: 'جبل طارق',
    code: '350',
    format: '+350 .....',
  },
  {
    iso2: 'GR',
    en: 'Greece',
    ar: 'اليونان',
    code: '30',
    format: '+30 . ... ....',
  },
  {
    iso2: 'GL',
    en: 'Greenland',
    ar: 'جرينلاند',
    code: '299',
    format: '+299 ......',
  },
  {
    iso2: 'GD',
    en: 'Grenada',
    ar: 'غرينادا',
    code: '1473',
    format: '+1 (473) ... ....',
  },
  {
    iso2: 'GP',
    en: 'Guadeloupe',
    ar: 'غوادلوب',
    code: '590',
    format: '+590 ... .. .. ..',
  },
  {
    iso2: 'GU',
    en: 'Guam',
    ar: 'غوام',
    code: '1671',
    format: '+1 (671) ... ....',
  },
  {
    iso2: 'GT',
    en: 'Guatemala',
    ar: 'غواتيمالا',
    code: '502',
    format: '+502 ... ....',
  },
  {
    iso2: 'GN',
    en: 'Guinea',
    ar: 'غينيا',
    code: '224',
    format: '+224 ... ......',
  },
  {
    iso2: 'GW',
    en: 'Guinea-Bissau',
    ar: 'غينيا بيساو',
    code: '245',
    format: '+245 ... ....',
  },
  {
    iso2: 'GY',
    en: 'Guyana',
    ar: 'غيانا',
    code: '592',
    format: '+592 ... ....',
  },
  {
    iso2: 'HT',
    en: 'Haiti',
    ar: 'هايتي',
    code: '509',
    format: '+509 ... ....',
  },
  {
    iso2: 'HN',
    en: 'Honduras',
    ar: 'هندوراس',
    code: '504',
    format: '+504 ... ....',
  },
  {
    iso2: 'HU',
    en: 'Hungary',
    ar: 'المجر',
    code: '36',
    format: '+36 . ... ....',
  },
  {
    iso2: 'HU',
    en: 'Hungary',
    ar: 'المجر',
    code: '06',
    format: '+06 . ... ....',
  },
  {
    iso2: 'IS',
    en: 'Iceland',
    ar: 'آيسلندا',
    code: '354',
    format: '+354 ... ....',
  },
  {
    iso2: 'IN',
    en: 'India',
    ar: 'الهند',
    code: '91',
    format: '+91 ... ... ....',
  },
  {
    iso2: 'ID',
    en: 'Indonesia',
    ar: 'إندونيسيا',
    code: '62',
    format: '+62 .. .... ....',
  },
  {
    iso2: 'IQ',
    en: 'Iraq',
    ar: 'العراق',
    code: '964',
    format: '+964 .. ... ....',
  },
  {
    iso2: 'IE',
    en: 'Ireland',
    ar: 'أيرلندا',
    code: '353',
    format: '+353 .. ... ....',
  },
  {
    iso2: 'IL',
    en: 'Israel',
    ar: 'إسرائيل',
    code: '972',
    format: '+972 .. ... ....',
  },
  {
    iso2: 'IT',
    en: 'Italy',
    ar: 'إيطاليا',
    code: '39',
    format: '+39 .. ... ....',
  },
  {
    iso2: 'JM',
    en: 'Jamaica',
    ar: 'جامايكا',
    code: '1876',
    format: '+1 (876) ... ....',
  },
  {
    iso2: 'JP',
    en: 'Japan',
    ar: 'اليابان',
    code: '81',
    format: '+81 .. ... ....',
  },
  {
    iso2: 'JO',
    en: 'Jordan',
    ar: 'الأردن',
    code: '962',
    format: '+962 . ... ....',
  },
  {
    iso2: 'KZ',
    en: 'Kazakhstan',
    ar: 'كازاخستان',
    code: '77',
    format: '+7 (7..) ... ....',
  },
  {
    iso2: 'KE',
    en: 'Kenya',
    ar: 'كينيا',
    code: '254',
    format: '+254 ... ......',
  },
  {
    iso2: 'KI',
    en: 'Kiribati',
    ar: 'كيريباتي',
    code: '686',
    format: '+686 .....',
  },
  {
    iso2: 'KW',
    en: 'Kuwait',
    ar: 'الكويت',
    code: '965',
    format: '+965 .... ....',
  },
  {
    iso2: 'KG',
    en: 'Kyrgyzstan',
    ar: 'قيرغيزستان',
    code: '996',
    format: '+996 ... ......',
  },
  {
    iso2: 'LV',
    en: 'Latvia',
    ar: 'لاتفيا',
    code: '371',
    format: '+371 .... ....',
  },
  {
    iso2: 'LB',
    en: 'Lebanon',
    ar: 'لبنان',
    code: '961',
    format: '+961 .. ... ...',
  },
  {
    iso2: 'LS',
    en: 'Lesotho',
    ar: 'ليسوتو',
    code: '266',
    format: '+266 .... ....',
  },
  {
    iso2: 'LR',
    en: 'Liberia',
    ar: 'ليبيريا',
    code: '231',
    format: '+231 .. ... ....',
  },
  {
    iso2: 'LI',
    en: 'Liechtenstein',
    ar: 'ليختنشتاين',
    code: '423',
    format: '+423 ... ....',
  },
  {
    iso2: 'LT',
    en: 'Lithuania',
    ar: 'ليتوانيا',
    code: '370',
    format: '+370 ... ....',
  },
  {
    iso2: 'LU',
    en: 'Luxembourg',
    ar: 'لوكسمبورغ',
    code: '352',
    format: '+352 ......',
  },
  {
    iso2: 'MG',
    en: 'Madagascar',
    ar: 'مدغشقر',
    code: '261',
    format: '+261 ... ....',
  },
  {
    iso2: 'MW',
    en: 'Malawi',
    ar: 'مالاوي',
    code: '265',
    format: '+265 .........',
  },
  {
    iso2: 'MY',
    en: 'Malaysia',
    ar: 'ماليزيا',
    code: '60',
    format: '+60 . ... ....',
  },
  {
    iso2: 'MV',
    en: 'Maldives',
    ar: 'جزر المالديف',
    code: '960',
    format: '+960 ... ....',
  },
  {
    iso2: 'ML',
    en: 'Mali',
    ar: 'مالي',
    code: '223',
    format: '+223 .... ....',
  },
  {
    iso2: 'MT',
    en: 'Malta',
    ar: 'مالطا',
    code: '356',
    format: '+356 .... ....',
  },
  {
    iso2: 'MH',
    en: 'Marshall Islands',
    ar: 'جزر مارشال',
    code: '692',
    format: '+692 ... ....',
  },
  {
    iso2: 'MQ',
    en: 'Martinique',
    ar: 'مارتينيك',
    code: '596',
    format: '+596 ... .. .. ..',
  },
  {
    iso2: 'MR',
    en: 'Mauritania',
    ar: 'موريتانيا',
    code: '222',
    format: '+222 ... ....',
  },
  {
    iso2: 'MU',
    en: 'Mauritius',
    ar: 'موريشيوس',
    code: '230',
    format: '+230 ... ....',
  },
  {
    iso2: 'YT',
    en: 'Mayotte',
    ar: 'مايوت',
    code: '262',
    format: '+262 ... .. .. ..',
  },
  {
    iso2: 'MX',
    en: 'Mexico',
    ar: 'المكسيك',
    code: '52',
    format: '+52 ... ... ....',
  },
  {
    iso2: 'MC',
    en: 'Monaco',
    ar: 'موناكو',
    code: '377',
    format: '+377 .... ....',
  },
  {
    iso2: 'MN',
    en: 'Mongolia',
    ar: 'منغوليا',
    code: '976',
    format: '+976 .. ......',
  },
  {
    iso2: 'ME',
    en: 'Montenegro',
    ar: 'الجبل الأسود',
    code: '382',
    format: '+382 .. ......',
  },
  {
    iso2: 'MS',
    en: 'Montserrat',
    ar: 'مونتسرات',
    code: '1664',
    format: '+1 (664) ... ....',
  },
  {
    iso2: 'MA',
    en: 'Morocco',
    ar: 'المغرب',
    code: '212',
    format: '+212 .. ... ....',
  },
  {
    iso2: 'MM',
    en: 'Myanmar',
    ar: 'ميانمار',
    code: '95',
    format: '+95 .. ... ....',
  },
  {
    iso2: 'NA',
    en: 'Namibia',
    ar: 'ناميبيا',
    code: '264',
    format: '+264 .. ......',
  },
  {
    iso2: 'NR',
    en: 'Nauru',
    ar: 'ناورو',
    code: '674',
    format: '+674 ... ....',
  },
  {
    iso2: 'NP',
    en: 'Nepal',
    ar: 'نيبال',
    code: '977',
    format: '+977 ... ... ....',
  },
  {
    iso2: 'NL',
    en: 'Netherlands',
    ar: 'هولندا',
    code: '31',
    format: '+31 .. ... ....',
  },
  {
    iso2: 'NC',
    en: 'New Caledonia',
    ar: 'كاليدونيا الجديدة',
    code: '687',
    format: '+687 ......',
  },
  {
    iso2: 'NZ',
    en: 'New Zealand',
    ar: 'نيوزيلندا',
    code: '64',
    format: '+64 (.) ... ....',
  },
  {
    iso2: 'NI',
    en: 'Nicaragua',
    ar: 'نيكاراغوا',
    code: '505',
    format: '+505 .... ....',
  },
  {
    iso2: 'NE',
    en: 'Niger',
    ar: 'النيجر',
    code: '227',
    format: '+227 .. ......',
  },
  {
    iso2: 'NG',
    en: 'Nigeria',
    ar: 'نيجيريا',
    code: '234',
    format: '+234 ... ... ....',
  },
  {
    iso2: 'NU',
    en: 'Niue',
    ar: 'نييوي',
    code: '683',
    format: '+683 .......',
  },
  {
    iso2: 'NF',
    en: 'Norfolk Island',
    ar: 'جزيرة نورفولك',
    code: '672',
    format: '+672 . .. ...',
  },
  {
    iso2: 'MP',
    en: 'Northern Mariana Islands',
    ar: 'جزر ماريانا الشمالية',
    code: '1670',
    format: '+1 (670) ... ....',
  },
  {
    iso2: 'NO',
    en: 'Norway',
    ar: 'النرويج',
    code: '47',
    format: '+47 .... ....',
  },
  {
    iso2: 'OM',
    en: 'Oman',
    ar: 'عمان',
    code: '968',
    format: '+968 .... ....',
  },
  {
    iso2: 'PK',
    en: 'Pakistan',
    ar: 'باكستان',
    code: '92',
    format: '+92 .......',
  },
  {
    iso2: 'PW',
    en: 'Palau',
    ar: 'بالاو',
    code: '680',
    format: '+680 ... ....',
  },
  {
    iso2: 'PA',
    en: 'Panama',
    ar: 'بنما',
    code: '507',
    format: '+507 ... ....',
  },
  {
    iso2: 'PG',
    en: 'Papua New Guinea',
    ar: 'بابوا غينيا الجديدة',
    code: '675',
    format: '+675 ... ....',
  },
  {
    iso2: 'PY',
    en: 'Paraguay',
    ar: 'باراغواي',
    code: '595',
    format: '+595 ... ......',
  },
  {
    iso2: 'PE',
    en: 'Peru',
    ar: 'بيرو',
    code: '51',
    format: '+51 .. .........',
  },
  {
    iso2: 'PH',
    en: 'Philippines',
    ar: 'الفلبين',
    code: '63',
    format: '+63 .. ... ....',
  },
  {
    iso2: 'PL',
    en: 'Poland',
    ar: 'بولندا',
    code: '48',
    format: '+48 .. ... ....',
  },
  {
    iso2: 'PT',
    en: 'Portugal',
    ar: 'البرتغال',
    code: '351',
    format: '+351 ... ... ...',
  },
  {
    iso2: 'PR',
    en: 'Puerto Rico',
    ar: 'بورتوريكو',
    code: '1939',
    format: '+1939 ... ....',
  },
  {
    iso2: 'QA',
    en: 'Qatar',
    ar: 'قطر',
    code: '974',
    format: '+974 .... ....',
  },
  {
    iso2: 'RO',
    en: 'Romania',
    ar: 'رومانيا',
    code: '40',
    format: '+40 ... ... ...',
  },
  {
    iso2: 'RW',
    en: 'Rwanda',
    ar: 'رواندا',
    code: '250',
    format: '+250 ... ... ...',
  },
  {
    iso2: 'WS',
    en: 'Samoa',
    ar: 'ساموا',
    code: '685',
    format: '+685 ... ....',
  },
  {
    iso2: 'SM',
    en: 'San Marino',
    ar: 'سان مارينو',
    code: '378',
    format: '+378 .... ......',
  },
  {
    iso2: 'SN',
    en: 'Senegal',
    ar: 'السنغال',
    code: '221',
    format: '+221 .. ... ....',
  },
  {
    iso2: 'RS',
    en: 'Serbia',
    ar: 'صربيا',
    code: '381',
    format: '+381 .. ... ....',
  },
  {
    iso2: 'SC',
    en: 'Seychelles',
    ar: 'سيشل',
    code: '248',
    format: '+248 ... ....',
  },
  {
    iso2: 'SL',
    en: 'Sierra Leone',
    ar: 'سيراليون',
    code: '232',
    format: '+232 .. ......',
  },
  {
    iso2: 'SG',
    en: 'Singapore',
    ar: 'سنغافورة',
    code: '65',
    format: '+65 .... ....',
  },
  {
    iso2: 'SK',
    en: 'Slovakia',
    ar: 'سلوفاكيا',
    code: '421',
    format: '+421 .. ... ....',
  },
  {
    iso2: 'SI',
    en: 'Slovenia',
    ar: 'سلوفينيا',
    code: '386',
    format: '+386 . ... .. ..',
  },
  {
    iso2: 'SB',
    en: 'Solomon Islands',
    ar: 'جزر سليمان',
    code: '677',
    format: '+677 .....',
  },
  {
    iso2: 'ZA',
    en: 'South Africa',
    ar: 'جنوب أفريقيا',
    code: '27',
    format: '+27 .. ... ....',
  },
  {
    iso2: 'GS',
    en: 'South Georgia and the South Sandwich Islands',
    ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    code: '500',
    format: '+500 .....',
  },
  {
    iso2: 'ES',
    en: 'Spain',
    ar: 'إسبانيا',
    code: '34',
    format: '+34 ... ... ...',
  },
  {
    iso2: 'LK',
    en: 'Sri Lanka',
    ar: 'سريلانكا',
    code: '94',
    format: '+94 .. ... ....',
  },
  {
    iso2: 'SD',
    en: 'Sudan',
    ar: 'السودان',
    code: '249',
    format: '+249 .. ... ....',
  },
  {
    iso2: 'SR',
    en: 'Suriname',
    ar: 'سورينام',
    code: '597',
    format: '+597 ......',
  },
  {
    iso2: 'SZ',
    en: 'Swaziland',
    ar: 'إسواتيني',
    code: '268',
    format: '+268 . ... ....',
  },
  {
    iso2: 'SE',
    en: 'Sweden',
    ar: 'السويد',
    code: '46',
    format: '+46 .. ... ....',
  },
  {
    iso2: 'CH',
    en: 'Switzerland',
    ar: 'سويسرا',
    code: '41',
    format: '+41 .. ... ....',
  },
  {
    iso2: 'TJ',
    en: 'Tajikistan',
    ar: 'طاجيكستان',
    code: '992',
    format: '+992 .. ... ....',
  },
  {
    iso2: 'TH',
    en: 'Thailand',
    ar: 'تايلاند',
    code: '66',
    format: '+66 . ... ....',
  },
  {
    iso2: 'TG',
    en: 'Togo',
    ar: 'توغو',
    code: '228',
    format: '+228 . ... ....',
  },
  {
    iso2: 'TK',
    en: 'Tokelau',
    ar: 'توكيلاو',
    code: '690',
    format: '+690 ....',
  },
  {
    iso2: 'TO',
    en: 'Tonga',
    ar: 'تونغا',
    code: '676',
    format: '+676 ... ....',
  },
  {
    iso2: 'TT',
    en: 'Trinidad and Tobago',
    ar: 'ترينيداد وتوباغو',
    code: '1868',
    format: '+1 (868) ... ....',
  },
  {
    iso2: 'TN',
    en: 'Tunisia',
    ar: 'تونس',
    code: '216',
    format: '+216 .... ....',
  },
  {
    iso2: 'TR',
    en: 'Turkey',
    ar: 'تركيا',
    code: '90',
    format: '+90 ... ... ....',
  },
  {
    iso2: 'TM',
    en: 'Turkmenistan',
    ar: 'تركمانستان',
    code: '993',
    format: '+993 .. ......',
  },
  {
    iso2: 'TC',
    en: 'Turks and Caicos Islands',
    ar: 'جزر توركس وكايكوس',
    code: '1649',
    format: '+1 (649) ... ....',
  },
  {
    iso2: 'TV',
    en: 'Tuvalu',
    ar: 'توفالو',
    code: '688',
    format: '+688 .....',
  },
  {
    iso2: 'UG',
    en: 'Uganda',
    ar: 'أوغندا',
    code: '256',
    format: '+256 ... ......',
  },
  {
    iso2: 'UA',
    en: 'Ukraine',
    ar: 'أوكرانيا',
    code: '380',
    format: '+380 .. ... ....',
  },
  {
    iso2: 'AE',
    en: 'United Arab Emirates',
    ar: 'الإمارات العربية المتحدة',
    code: '971',
    format: '+971 .. ... ....',
  },
  {
    iso2: 'GB',
    en: 'United Kingdom of Great Britain and Northern Ireland',
    ar: 'المملكة المتحدة',
    code: '44',
    format: '+44 .... ......',
  },
  {
    iso2: 'UY',
    en: 'Uruguay',
    ar: 'الأوروغواي',
    code: '598',
    format: '+598 .... ....',
  },
  {
    iso2: 'UZ',
    en: 'Uzbekistan',
    ar: 'أوزبكستان',
    code: '998',
    format: '+998 .. ... ....',
  },
  {
    iso2: 'VU',
    en: 'Vanuatu',
    ar: 'فانواتو',
    code: '678',
    format: '+678 .....',
  },
  {
    iso2: 'WF',
    en: 'Wallis and Futuna',
    ar: 'والس وفوتونا',
    code: '681',
    format: '+681 .. ....',
  },
  {
    iso2: 'YE',
    en: 'Yemen',
    ar: 'اليمن',
    code: '967',
    format: '+967 . ......',
  },
  {
    iso2: 'ZM',
    en: 'Zambia',
    ar: 'زامبيا',
    code: '260',
    format: '+260 .. ... ....',
  },
  {
    iso2: 'ZW',
    en: 'Zimbabwe',
    ar: 'زيمبابوي',
    code: '263',
    format: '+263 .. ... ....',
  },
  {
    iso2: 'AX',
    en: 'Åland Islands',
    ar: 'جزر أولاند',
    code: '354',
    format: '+354 ... ....',
  },
  {
    iso2: 'BO',
    en: 'Bolivia (Plurinational State of)',
    ar: 'بوليفيا',
    code: '591',
    format: '+591 ... ... ....',
  },
  {
    iso2: 'BN',
    en: 'Brunei Darussalam',
    ar: 'بروناي',
    code: '673',
    format: '+673 ... ....',
  },
  {
    iso2: 'CC',
    en: 'Cocos (Keeling) Islands',
    ar: 'جزر كوكوس',
    code: '61',
    format: '+61 . .... ....',
  },
  {
    iso2: 'CD',
    en: 'Congo (Democratic Republic of the)',
    ar: 'جمهورية الكونغو الديمقراطية',
    code: '243',
    format: '+243 .. ... ....',
  },
  {
    iso2: 'CI',
    en: "Côte d'Ivoire",
    ar: 'ساحل العاج',
    code: '225',
    format: '+225 ........',
  },
  {
    iso2: 'FK',
    en: 'Falkland Islands (Malvinas)',
    ar: 'جزر فوكلاند',
    code: '500',
    format: '+500 .....',
  },
  {
    iso2: 'GG',
    en: 'Guernsey',
    ar: 'غيرنزي',
    code: '44',
    format: '+44 (.) .... ......',
  },
  {
    iso2: 'HK',
    en: 'Hong Kong',
    ar: 'هونغ كونغ',
    code: '852',
    format: '+852 .... ....',
  },
  {
    iso2: 'IR',
    en: 'Iran',
    ar: 'إيران',
    code: '98',
    format: '+98 ... ... ....',
  },
  {
    iso2: 'KP',
    en: "Korea (Democratic People's Republic of)",
    ar: 'كوريا الشمالية',
    code: '850',
    format: '+850 . ... ....',
  },
  {
    iso2: 'KR',
    en: 'Korea (Republic of)',
    ar: 'كوريا الجنوبية',
    code: '82',
    format: '+82 . ... ....',
  },
  {
    iso2: 'LA',
    en: "Lao People's Democratic Republic",
    ar: 'لاوس',
    code: '856',
    format: '+856 .. .... ....',
  },
  {
    iso2: 'LY',
    en: 'Libya',
    ar: 'ليبيا',
    code: '218',
    format: '+218 .. ... ....',
  },
  {
    iso2: 'MO',
    en: 'Macao',
    ar: 'ماكاو',
    code: '853',
    format: '+853 .... ....',
  },
  {
    iso2: 'MK',
    en: 'Macedonia (the former Yugoslav Republic of)',
    ar: 'مقدونيا',
    code: '389',
    format: '+389 . ... ....',
  },
  {
    iso2: 'FM',
    en: 'Micronesia (Federated States of)',
    ar: 'ولايات ميكرونيسيا المتحدة',
    code: '691',
    format: '+691 ... ....',
  },
  {
    iso2: 'MD',
    en: 'Moldova (Republic of)',
    ar: 'مولدوفا',
    code: '373',
    format: '+373 ... .....',
  },
  {
    iso2: 'MZ',
    en: 'Mozambique',
    ar: 'موزمبيق',
    code: '258',
    format: '+258 ... ......',
  },
  {
    iso2: 'PS',
    en: 'Palestine, State of',
    ar: 'فلسطين',
    code: '970',
    format: '+970 . ... ....',
  },
  {
    iso2: 'PN',
    en: 'Pitcairn',
    ar: 'جزر بيتكيرن',
    code: '64',
    format: '+64 . ... ....',
  },
  {
    iso2: 'RE',
    en: 'Réunion',
    ar: 'لا ريونيون',
    code: '262',
    format: '+262 ... .. .. ..',
  },
  {
    iso2: 'RU',
    en: 'Russian Federation',
    ar: 'روسيا',
    code: '7',
    format: '+7 (...) ...-..-..',
  },
  {
    iso2: 'BL',
    en: 'Saint Barthélemy',
    ar: 'سان بارتيلمي',
    code: '590',
    format: '+590 ... .. .. ..',
  },
  {
    iso2: 'SH',
    en: 'Saint Helena, Ascension and Tristan da Cunha',
    ar: 'سانت هيلانة وأسينشين وتريستان دا كونا',
    code: '290',
    format: '+290 ....',
  },
  {
    iso2: 'KN',
    en: 'Saint Kitts and Nevis',
    ar: 'سانت كيتس ونيفيس',
    code: '1869',
    format: '+1 (869) ... ....',
  },
  {
    iso2: 'LC',
    en: 'Saint Lucia',
    ar: 'سانت لوسيا',
    code: '1758',
    format: '+1 (758) ... ....',
  },
  {
    iso2: 'MF',
    en: 'Saint Martin (French part)',
    ar: 'تجمع سان مارتين',
    code: '590',
    format: '+590 ... ......',
  },
  {
    iso2: 'PM',
    en: 'Saint Pierre and Miquelon',
    ar: 'سان بيير وميكلون',
    code: '508',
    format: '+558 08 .. ..',
  },
  {
    iso2: 'VC',
    en: 'Saint Vincent and the Grenadines',
    ar: 'سانت فينسنت والغرينادين',
    code: '1784',
    format: '+1 (784) ... ....',
  },
  {
    iso2: 'ST',
    en: 'Sao Tome and Principe',
    ar: 'ساو تومي وبرينسيب',
    code: '239',
    format: '+239 ... ....',
  },
  {
    iso2: 'SO',
    en: 'Somalia',
    ar: 'الصومال',
    code: '252',
    format: '+252 .. ... ...',
  },
  {
    iso2: 'SJ',
    en: 'Svalbard and Jan Mayen',
    ar: 'سفالبارد ويان ماين',
    code: '47',
    format: '+47 .... ....',
  },
  {
    iso2: 'SY',
    en: 'Syrian Arab Republic',
    ar: 'سوريا',
    code: '963',
    format: '+963 .. ... ....',
  },
  {
    iso2: 'TW',
    en: 'Taiwan',
    ar: 'تايوان',
    code: '886',
    format: '+886 . .... ....',
  },
  {
    iso2: 'TZ',
    en: 'Tanzania, United Republic of',
    ar: 'تنزانيا',
    code: '255',
    format: '+255 .. ... ....',
  },
  {
    iso2: 'TL',
    en: 'Timor-Leste',
    ar: 'تيمور الشرقية',
    code: '670',
    format: '+670 ... ...',
  },
  {
    iso2: 'VE',
    en: 'Venezuela (Bolivarian Republic of)',
    ar: 'فنزويلا',
    code: '58',
    format: '+58 ... ... ....',
  },
  {
    iso2: 'VN',
    en: 'Vietnam',
    ar: 'فيتنام',
    code: '84',
    format: '+84 ... ... ....',
  },
  {
    iso2: 'VG',
    en: 'Virgin Islands (British)',
    ar: 'جزر العذراء البريطانية',
    code: '1284',
    format: '+1 (284) ... ....',
  },
  {
    iso2: 'VI',
    en: 'Virgin Islands (U.S.)',
    ar: 'جزر العذراء الأمريكية',
    code: '1340',
    format: '+1 (340) ... ....',
  },
];
