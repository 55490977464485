import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, alpha, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as Twitter } from '@userpath/components/assets/svg/icons/twitter.svg';
import { ReactComponent as LinkedIn } from '@userpath/components/assets/svg/icons/linked_in.svg';
import { ReactComponent as Instagram } from '@userpath/components/assets/svg/icons/instagram.svg';
import { useToastContext } from '@userpath/utils';
import { CanvasLines } from '../canvas-lines';
import { FooterVariant, Styled } from './styles';

const headerItems = [
  { title: 'header.home', path: '/' },
  { title: 'header.about', path: '/about' },
  { title: 'header.services', path: '/services' },
  { title: 'header.articles', path: '/articles' },
  { title: 'header.careers', path: '/careers' },
];

const Footer: FC = () => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const padTop =
    location.pathname != '/articles' &&
    location.pathname != '/services' &&
    !location.pathname.startsWith('/admin/');
  const variant: FooterVariant =
    location.pathname == '/' ? 'landing' : 'normal';

  const socialClick = useCallback(() => {
    addToast({
      message: t('contact.socialInfo'),
      type: 'info',
    });
  }, [addToast, t]);

  return (
    <CanvasLines
      active={!isMobile && location.pathname == '/'}
      flipPointsOnRTL={false}
      lineColor={({ theme }) =>
        alpha(
          theme.palette.primary.secondary10 ?? theme.palette.text.neutral10,
          0.17,
        )
      }
      getPoints={({ width, height, isRTL }) => {
        return [
          { x: isRTL ? 86 : width - 86, y: 0 },
          { x: isRTL ? 86 : width - 86, y: height },
        ];
      }}
    >
      <Styled.Container footerVariant={variant} padTop={padTop}>
        <Styled.BGFooter footerVariant={variant}>
          <Styled.Footer footerVariant={variant}>
            <Grid container rowSpacing="30px">
              <Grid
                item
                xs={12}
                md={false}
                flex={{ md: 1 }}
                maxWidth={{ md: 174 }}
                textAlign="center"
                alignContent="center"
              >
                {variant == 'normal' && <Styled.Logo />}
                {variant == 'landing' && <Styled.LogoWhite />}
              </Grid>
              <Grid
                item
                xs={12}
                md={false}
                flex={{ md: 1 }}
                alignContent="center"
              >
                <Grid container columnSpacing="24px" justifyContent="center">
                  {headerItems.map((item, i) => (
                    <Grid item key={i}>
                      <Styled.AppBarButton
                        key={i}
                        footerVariant={variant}
                        onClick={() => navigate(item.path)}
                      >
                        {t(item.title)}
                      </Styled.AppBarButton>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                md={false}
                flex={{ md: 1 }}
                maxWidth={{ md: 174 }}
                alignContent="center"
              >
                <Grid container columnSpacing="24px" justifyContent="center">
                  <Grid item>
                    <Styled.MediaButton footerVariant={variant}>
                      <Twitter onClick={socialClick} />
                    </Styled.MediaButton>
                  </Grid>
                  <Grid item>
                    <Styled.MediaButton footerVariant={variant}>
                      <LinkedIn onClick={socialClick} />
                    </Styled.MediaButton>
                  </Grid>
                  <Grid item>
                    <Styled.MediaButton footerVariant={variant}>
                      <Instagram onClick={socialClick} />
                    </Styled.MediaButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Styled.Footer>
        </Styled.BGFooter>
      </Styled.Container>
    </CanvasLines>
  );
};

export default Footer;
