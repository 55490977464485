import { TFunction, TOptions } from 'i18next';
export class AppError {
  constructor(
    public key: string,
    public translationParams?: TOptions,
  ) {}

  public static fromError(error: unknown): AppError {
    if (error instanceof AppError) {
      return error;
    } else if (
      typeof error === 'object' &&
      error !== null &&
      'code' in error &&
      typeof error.code === 'string'
    ) {
      return new AppError('errors.unknownCodeError', {
        code: error.code,
      });
    } else if (error instanceof Error) {
      throw new AppError('errors.unknownMessageError', {
        message: error.message,
      });
    } else {
      return new AppError('errors.unknownError');
    }
  }

  public translate(t: TFunction): string {
    if (this.translationParams) {
      return t(this.key, this.translationParams);
    } else {
      return t(this.key);
    }
  }
}
