import {
  QueryDocumentSnapshot,
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
} from 'firebase/firestore/lite';
import { Content, ContentDTO } from '@userpath/types';

export class ContentConverter
  implements FirestoreDataConverter<ContentDTO, Content>
{
  toFirestore(content: WithFieldValue<ContentDTO>): WithFieldValue<Content> {
    return {
      ...content,
    };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
  ): ContentDTO {
    const data = snapshot.data() as Content;
    return {
      id: snapshot.id,
      ...data,
    };
  }
}
