import { Box, styled } from '@mui/material';
import { shouldForwardProp } from '@userpath/utils';

export const Styled = {
  ItemsContainer: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemHeight']),
  })<{ itemHeight: number }>(({ theme, itemHeight }) => ({
    '--list-padding': '32px',
    '--list-height': `${itemHeight * 4 + 96}px`,
    overflow: 'hidden',
    height: 'var(--list-height)',
    [theme.breakpoints.down('md')]: {
      '--list-padding': '24px',
      '--list-height': `${itemHeight * 3 + 48}px`,
    },
  })),
  ItemsList: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemHeight']),
  })<{ itemHeight: number }>(({ theme, itemHeight }) => ({
    transition: 'transform 1500ms ease-in-out',
    transform: `translateY(calc((var(--list-page, 0) * var(--list-padding, 0px) * -1) + var(--list-page, 0) * var(--list-height) * -1))`,
    '> div': {
      height: itemHeight,
      marginBottom: '32px',
    },
    [theme.breakpoints.down('md')]: {
      '> div': {
        marginBottom: '24px',
      },
    },
  })),
  Item: styled(Box, {
    shouldForwardProp: shouldForwardProp(['itemColor']),
  })<{ itemColor: string }>(({ theme, itemColor }) => ({
    ...theme.typography.h8,
    color: theme.palette.text.neutral80,
    padding: '16px 0px',
    position: 'relative',
    ...theme.directional({
      paddingLeft: '40px',
    }),
    [theme.breakpoints.down('md')]: {
      ...theme.directional({
        paddingLeft: '20px',
      }),
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      background: itemColor,
      borderRadius: '8px',
      top: 0,
      bottom: 0,
      width: '8px',
      ...theme.directional({
        left: 0,
      }),
    },
  })),
};
