import React, { useEffect, useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Library } from '@userpath/constants';
import {
  AppButton,
  ArticleCard,
  CanvasLines,
  CustomContainer,
  NetworkState,
  GradientTypography,
  useFirebaseAuth,
} from '@userpath/components';
import { useArticles } from '@userpath/services';
import { AppError, ArticleDTO } from '@userpath/types';
import { Action, apiReducer, initialAPIState } from '@userpath/utils';
import { Styled } from './styles';

const ArticlesPage: React.FC = () => {
  const { t } = useTranslation(Library.userpath);
  const { isLoggedIn } = useFirebaseAuth();
  const navigate = useNavigate();
  const articlesServices = useArticles();
  const [{ data, loading, error }, dispatchArticles] = useReducer(
    apiReducer<ArticleDTO[], AppError>(),
    initialAPIState,
  );

  useEffect(() => {
    dispatchArticles({ type: Action.loading });

    articlesServices
      .getAllArticles()
      .then((payload) => dispatchArticles({ type: Action.success, payload }))
      .catch((error) => {
        dispatchArticles({
          type: Action.error,
          error: AppError.fromError(error),
        });
      });
  }, [articlesServices]);

  return (
    <React.Fragment>
      <CanvasLines
        getPoints={({ height, isMobile }) => {
          const st = isMobile ? height - 200 : Math.min(height - 200, 104);
          const p1 = isMobile ? 40 : 140;
          const extraX = isMobile ? 120 : 280;
          const radius = isMobile ? 70 : 140;
          return [
            { x: 0, y: st },
            { x: p1, y: st, radius: radius },
            { x: p1, y: height + 100 },
            { x: p1 + extraX, y: height + 100 },
            { x: p1 + extraX, y: height - 70, radius: radius },
            { x: 0, y: height - 70 },
          ];
        }}
      >
        <CustomContainer
          sx={{
            paddingTop: { xs: '50px', md: '104px' },
            paddingBottom: '80px',
          }}
        >
          <Stack textAlign="center" alignItems="center">
            <GradientTypography variant="h1" gradient="primary" animated>
              {t('articles.title')}
            </GradientTypography>
            <Typography
              variant="h5"
              sx={(theme) => ({
                margin: { md: '0px 64px', lg: '0px 124px', xl: '0px 194px' },
                marginTop: '32px',
                lineHeight: 1.5,
                maxWidth: '1024px',
                color: theme.palette.text.neutral80,
              })}
            >
              <Trans
                ns={Library.userpath}
                i18nKey="articles.details"
                components={{ 1: <br /> }}
              />
            </Typography>
          </Stack>
        </CustomContainer>
      </CanvasLines>
      <Styled.BGContainer>
        <Styled.Container>
          {isLoggedIn && !loading && !error && (
            <Box textAlign="center">
              <AppButton
                size="large"
                onClick={() => navigate('/admin/article')}
              >
                {t('admin.article.addArticle')}
              </AppButton>
            </Box>
          )}
          <Grid container spacing={{ xs: '28px', md: '42px', lg: '64px' }}>
            {error && (
              <Grid item xs={12}>
                <NetworkState
                  paddingTop="120px"
                  title={t('errors.sorry')}
                  details={error.translate(t)}
                />
              </Grid>
            )}
            {loading &&
              Array.from(Array(4)).map((_, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <Skeleton
                    variant="rounded"
                    sx={(theme) => ({
                      height: '800px',
                      [theme.breakpoints.down('md')]: {
                        height: '600px',
                      },
                    })}
                  />
                </Grid>
              ))}
            {data &&
              data.map((article, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <ArticleCard article={article} />
                </Grid>
              ))}
            {data && data.length == 0 && (
              <Grid item xs={12}>
                <NetworkState
                  type="empty"
                  paddingTop="120px"
                  details={t('articles.empty')}
                />
              </Grid>
            )}
          </Grid>
        </Styled.Container>
      </Styled.BGContainer>
    </React.Fragment>
  );
};

export default ArticlesPage;
