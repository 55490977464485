import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { Library } from '@userpath/constants';
import { Styled } from './styles';
import { ServicesConstants, ServiceIndex, ServiceIcon } from './';

interface IServiceItemProps {
  index: ServiceIndex;
}

const ServiceItem: React.FC<IServiceItemProps> = ({ index }) => {
  const { t } = useTranslation(Library.userpath);

  return (
    <Styled.Item itemColor={ServicesConstants.IconColors[index]}>
      <Stack
        alignItems="center"
        direction="row"
        height="100%"
        gap={{ xs: '12px', md: '32px' }}
      >
        <ServiceIcon index={index} size={{ xs: '32px', md: '48px' }} />
        <Stack gap="8px" flex={1}>
          <Typography variant="h5" fontWeight={500}>
            {t(`home.${index}.title`)}
          </Typography>
          <Typography variant="h6">{t(`home.${index}.details`)}</Typography>
        </Stack>
      </Stack>
    </Styled.Item>
  );
};

export default ServiceItem;
