import { Box, Typography, styled } from '@mui/material';

interface IListItemProps {
  totalItems: number;
}

export const Styled = {
  Container: styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '704px',
    [theme.breakpoints.down('md')]: {
      height: '650px',
    },
  })),
  With: styled(Typography)(({ theme }) => ({
    opacity: 0.5,
    fontWeight: 400,
    fontSize: theme.typography.sizes.h5,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.neutral,
  })),
  List: styled('ol')<IListItemProps>(({ totalItems, theme }) => ({
    '@keyframes slide': generateSlideKeyframes(totalItems),
    listStyle: 'none',
    padding: 0,
    margin: 0,
    height: `calc(${theme.typography.sizes.h5}px * 1.5)`,
    animation: `slide ${totalItems * 2.5}s infinite`,
  })),
  ListItem: styled('li')(({ theme }) => ({
    listStyle: 'none',
    height: `calc(${theme.typography.sizes.h5}px * 1.5)`,
  })),
  Item: styled('span')(({ theme }) => ({
    fill: `${theme.palette.background.default}`,
    fontWeight: 700,
    fontSize: theme.typography.sizes.h5,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.neutral,
    textAlign: 'left',
    lineHeight: 1.5,
  })),
  ItemsContainer: styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '402px',
    ...(theme.direction == 'ltr' && {
      left: 0,
    }),
    ...(theme.direction == 'rtl' && {
      right: 0,
    }),
    [theme.breakpoints.down('md')]: {
      top: '272px',
    },
  })),
  Title: styled(Typography)(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.palette.text.neutral,
    position: 'absolute',
    alignContent: 'end',
    top: '0px',
    ...(theme.direction == 'ltr' && {
      left: 0,
    }),
    ...(theme.direction == 'rtl' && {
      right: 0,
    }),
    height: '370px',
    maxWidth: '70vw',
    [theme.breakpoints.down('md')]: {
      height: '240px',
    },
  })),
  FlippedImage: styled('img')(({ theme }) => ({
    width: 'calc(min(100%, 480px))',
    ...(theme.direction == 'rtl' && {
      transform: 'scaleX(-1)',
    }),
  })),
};

const generateSlideKeyframes = (numItems: number) => {
  const keyframePercentage = 100 / (numItems + 1);
  const keyframes: Record<string, { transform: string }> = {};
  for (let i = 0; i < numItems; i++) {
    const startPercent = i * keyframePercentage;
    const endPercent = (i + 1) * keyframePercentage;

    keyframes[`${Math.max(0, startPercent)}%, ${Math.min(100, endPercent)}%`] =
      {
        transform: `translateY(${(-i - 1) * 100}%)`,
      };
  }
  const endPercent = numItems * keyframePercentage;
  keyframes[
    `${Math.min(100, endPercent + 0.001)}%, ${Math.min(100, endPercent + 0.001)}%`
  ] = {
    transform: `translateY(0%)`,
  };
  keyframes[`${(numItems + 0.75) * keyframePercentage}%, 100%`] = {
    transform: `translateY(-100%)`,
  };
  return keyframes;
};
