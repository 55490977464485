import React from 'react';

const EmptySVG: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="125"
      height="125"
      fill="none"
      viewBox="0 0 125 125"
    >
      <g>
        <path
          fill="url(#a)"
          clipPath="url(#clipPath)"
          d="M9.884 67.166c9.979-4.34 11.479-9.887 11.264-13.521-.03-4.248-3.52-7.717-7.81-7.717-3.89 0-7.118 2.847-7.716 6.568a1.955 1.955 0 0 0 3.858.62 3.908 3.908 0 0 1 7.764.625c0 .038.001.08.003.118.166 2.723-1.427 6.465-8.92 9.725a1.954 1.954 0 1 0 1.557 3.582Z"
        >
          <Animate />
        </path>
        <path
          fill="url(#b)"
          clipPath="url(#clipPath)"
          d="M9.81 67.195c14.837-5.74 23.68-11.36 40.337-11.585a1.954 1.954 0 0 0-.052-3.907c-17.205.233-26.371 5.92-41.694 11.85a1.953 1.953 0 1 0 1.408 3.643Z"
        >
          <Animate />
        </path>
        <path
          fill="url(#c)"
          clipPath="url(#clipPath)"
          d="M10.212 78.703c16.035-11.012 25.105-14.906 57.51-15.281 34.982-.406 40.244-18.082 39.947-25.352-.022-6.978-5.701-12.644-12.694-12.644-7.007 0-12.695 5.69-12.695 12.695 0 4.809 2.678 8.998 6.625 11.15a1.953 1.953 0 0 0 1.871-3.427 8.797 8.797 0 0 1-4.59-7.723c0-4.852 3.938-8.789 8.79-8.789 4.85 0 8.789 3.938 8.789 8.79 0 .026 0 .056.002.083.277 6.42-5.065 20.951-36.09 21.31-33.64.391-43.03 4.536-59.678 15.97a1.953 1.953 0 0 0 2.213 3.218Z"
        >
          <Animate />
        </path>
        <path
          fill="url(#d)"
          clipPath="url(#clipPath)"
          d="M45.439 78.653c5.345-4.035 16.052-4.85 23.744-2.1 4.691 1.678 8.312 4.721 8.295 9.537v.008a5.863 5.863 0 0 1-5.86 5.86 5.861 5.861 0 0 1-5.613-7.545 1.954 1.954 0 0 0-3.742-1.125 9.751 9.751 0 0 0-.41 2.81c0 5.39 4.375 9.766 9.765 9.766 5.389 0 9.764-4.373 9.766-9.762.023-6.527-4.528-10.953-10.887-13.226-8.887-3.178-21.236-2.004-27.412 2.66a1.953 1.953 0 1 0 2.354 3.117Z"
        >
          <Animate />
        </path>
        <path
          fill="url(#e)"
          clipPath="url(#clipPath)"
          d="M85.378 69.28c8.537-.446 18.099 2.767 21.654 8.804a1.954 1.954 0 0 0 2.674.693 1.954 1.954 0 0 0 .691-2.674c-4.166-7.08-15.213-11.248-25.222-10.726a1.954 1.954 0 0 0 .203 3.902Z"
        >
          <Animate />
        </path>
      </g>
      <path
        fill="url(#f)"
        d="M26.265 41.352c5.204.274 12.689-2.101 16.341-4.658a1.952 1.952 0 1 0-2.24-3.2c-3.105 2.175-9.47 4.19-13.895 3.958a1.954 1.954 0 0 0-.207 3.9Z"
      />
      <path
        fill="#E9E9EA"
        d="M38.91 36.662c-7.027-6.466-3.588-18.851 10.307-23.963 7.472-2.748 11.549-5.505 13.6-7.818a1.874 1.874 0 0 1 3.312.16c7.36 17.465-11.819 45.797-27.219 31.621Z"
      />
      <path
        fill="url(#g)"
        d="M66.256 5.354c7.006 17.484-12.037 45.4-27.348 31.308-1.514-1.392-2.54-3.059-3.084-4.867 13.051 13.283 32.94-8.897 30.432-26.441Z"
      />
      <path
        fill="url(#h)"
        d="M10.767 106.426c2.965-3.293 8.893-6.37 12.625-7.028a1.954 1.954 0 0 0-.68-3.848c-4.388.776-11.363 4.39-14.847 8.262a1.953 1.953 0 0 0 2.902 2.614Z"
      />
      <path
        fill="url(#i)"
        d="M20.12 98.19c-.397 9.539 10.793 15.865 24.232 9.654 7.227-3.34 12.059-4.271 15.147-4.088a1.874 1.874 0 0 0 1.855-.554c.467-.516.61-1.248.371-1.901C54.58 83.749 20.987 77.276 20.12 98.19Z"
      />
      <path
        fill="url(#j)"
        d="M23.079 89.852c-1.73 2.05-2.813 4.802-2.96 8.337-.396 9.54 10.794 15.866 24.233 9.655 7.227-3.34 12.059-4.272 15.147-4.088a1.87 1.87 0 0 0 1.73-.43 1.674 1.674 0 0 1-.19-.072c-3.035-.668-7.955-.422-15.584 2.01-14.187 4.521-24.4-3.901-22.68-13.953.087-.504.188-.99.304-1.46Z"
      />
      <path
        fill="url(#k)"
        d="M84.905 92.842c3.47.424 7.875 2.45 10.922 4.594a1.953 1.953 0 0 0 2.248-3.194c-3.537-2.488-8.67-4.787-12.7-5.277a1.952 1.952 0 1 0-.47 3.877Z"
      />
      <path
        fill="url(#l)"
        d="M95.371 93.526c-6.049 4.83-3.972 15.039 6.973 19.963 5.476 2.463 8.576 4.765 10.211 6.674a1.865 1.865 0 0 0 3.287-.073c6.318-13.779-7.387-37.011-20.47-26.564Z"
      />
      <path
        fill="url(#m)"
        d="M92.453 97.715c-1.653 5.174 1.533 12.014 9.89 15.773 5.477 2.463 8.577 4.766 10.211 6.674.338.6.977.963 1.664.947.344-.005.67-.107.952-.285a1.865 1.865 0 0 1-.76-.935c-1.578-2.586-5.02-5.953-11.705-9.916-5.762-3.416-9.104-7.914-10.252-12.258Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="-4.5"
          x2="52.5"
          y1="67"
          y2="29.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset=".41" stopColor="#E5774D" />
          <stop offset=".64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="-3.5"
          x2="86"
          y1="78"
          y2="34.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset=".41" stopColor="#E5774D" />
          <stop offset=".64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="-5"
          x2="148"
          y1="83"
          y2="17.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset=".41" stopColor="#E5774D" />
          <stop offset=".64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="35"
          x2="127"
          y1="74.5"
          y2="120.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset=".41" stopColor="#E5774D" />
          <stop offset=".64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="75.5"
          x2="145"
          y1="65"
          y2="98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FED573" />
          <stop offset=".41" stopColor="#E5774D" />
          <stop offset=".64" stopColor="#AE02A3" />
          <stop offset="1" stopColor="#1801A1" />
        </linearGradient>
        <linearGradient
          id="f"
          x1="64.356"
          x2="27.743"
          y1="5.354"
          y2="42.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <linearGradient
          id="g"
          x1="64.356"
          x2="27.742"
          y1="5.354"
          y2="42.645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <linearGradient
          id="h"
          x1="52.726"
          x2="7.929"
          y1="98.638"
          y2="106.008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <linearGradient
          id="i"
          x1="57.416"
          x2="21.928"
          y1="94.333"
          y2="99.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <linearGradient
          id="j"
          x1="52.725"
          x2="7.928"
          y1="98.639"
          y2="106.008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <linearGradient
          id="k"
          x1="93.645"
          x2="88.046"
          y1="88.301"
          y2="101.295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <linearGradient
          id="l"
          x1="114.322"
          x2="94.508"
          y1="92.393"
          y2="104.239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <linearGradient
          id="m"
          x1="93.646"
          x2="88.047"
          y1="88.301"
          y2="101.295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E9E9EA" />
          <stop offset="1" stopColor="#D3D4D4" />
        </linearGradient>
        <clipPath id="clipPath" clipPathUnits="objectBoundingBox">
          <rect x="0" y="0" width="1" height="1">
            <animate
              attributeName="width"
              values="0;1;1;1"
              dur="3.5s"
              fill="freeze"
              repeatCount="indefinite"
            />
          </rect>
        </clipPath>
      </defs>
    </svg>
  );
};

const Animate: React.FC = () => {
  return (
    <animate
      attributeName="opacity"
      values="1;1;0;0"
      dur="3.5s"
      fill="freeze"
      repeatCount="indefinite"
    />
  );
};

export default EmptySVG;
