import {
  QueryDocumentSnapshot,
  FirestoreDataConverter,
  WithFieldValue,
  FieldValue,
  Timestamp,
  DocumentData,
} from 'firebase/firestore/lite';
import { Article, ArticleDTO } from '@userpath/types';

export class ArticleConverter
  implements FirestoreDataConverter<ArticleDTO, Article>
{
  toFirestore(article: WithFieldValue<ArticleDTO>): WithFieldValue<Article> {
    return {
      ...article,
      createdDate: this._convertDate(article.createdDate),
      updatedDate: article.updatedDate
        ? this._convertDate(article.updatedDate)
        : undefined,
    };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
  ): ArticleDTO {
    const data = snapshot.data() as Article;
    return {
      ...data,
      id: snapshot.id,
      createdDate: data.createdDate.toMillis(),
      updatedDate: data.updatedDate?.toMillis(),
    };
  }

  _convertDate(date: number | FieldValue): Timestamp | FieldValue {
    if (typeof date !== 'number') {
      return date;
    }
    return Timestamp.fromMillis(date);
  }
}
