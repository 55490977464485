import { Box, alpha, styled } from '@mui/material';
import { FadeTransition } from '@userpath/components';
import { shouldForwardProp } from '@userpath/utils';
import arrows1 from '@userpath/userpath/assets/images/arrows1.png';
import arrows2 from '@userpath/userpath/assets/images/arrows2.png';
import arrows3 from '@userpath/userpath/assets/images/arrows3.png';
import arrows4 from '@userpath/userpath/assets/images/arrows4.png';

const colors = [
  {
    degree: '53.25deg',
    stop1: '-18.22%',
    stop2: '32.69%',
    stop3: '61.25%',
    stop4: '105.95%',
  },
  {
    degree: '104.69deg',
    stop1: '-36.81%',
    stop2: '21.28%',
    stop3: '53.87%',
    stop4: '104.87%',
  },
  {
    degree: '153.72deg',
    stop1: '-34.66%',
    stop2: '17.3%',
    stop3: '46.45%',
    stop4: '92.07%',
  },
  {
    degree: '229.91deg',
    stop1: '-25.8%',
    stop2: '31.74%',
    stop3: '64.01%',
    stop4: '114.53%',
  },
];

interface IContainerProps {
  index: number;
  path: string;
}

export const Styled = {
  Container: styled(Box, {
    shouldForwardProp: shouldForwardProp(['index', 'path']),
  })<IContainerProps>(({ index, path, theme }) => ({
    clipPath: `path("${path}")`,
    position: 'relative',
    height: '632px',
    paddingTop: '72px',
    transition: 'background 800ms',
    background: `linear-gradient(${colors[index].degree},
            ${theme.palette.secondary.secondary} ${colors[index].stop1},
            ${theme.palette.secondary.main} ${colors[index].stop2},
            ${theme.palette.primary.secondary} ${colors[index].stop3},
            ${theme.palette.primary.main} ${colors[index].stop4})`,
    [theme.breakpoints.down('md')]: {
      height: '522px',
      paddingTop: '124px',
      background: `linear-gradient(64.15deg,
            ${theme.palette.secondary.secondary} -39.09%,
            ${theme.palette.secondary.main} 20.81%,
            ${theme.palette.primary.secondary} 54.41%,
            ${theme.palette.primary.main} 107%)`,
    },
  })),
  Transition: styled(FadeTransition)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '26px',
  }),
  IconContainer: styled(Box)(({ theme }) => ({
    width: '104px',
    height: '104px',
    [theme.breakpoints.down('md')]: {
      width: '64px',
      height: '64px',
    },
  })),
  Title: styled('span')(({ theme }) => ({
    ...theme.typography.h2,
    color: theme.palette.text.neutral,
  })),
  Subtitle: styled('span')(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.palette.text.neutral,
    maxWidth: '70%',
    textAlign: 'center',
    padding: '0px 24px',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'unset',
    },
  })),
  Indicator: styled(Box, {
    shouldForwardProp: shouldForwardProp(['index', 'path', 'flip']),
  })<IContainerProps & { flip: boolean }>(({ index, path, flip, theme }) => ({
    width: '36px',
    height: '36px',
    background: '#F3F3F3',
    offsetPath: `path("${path}")`,
    offsetDistance: `${flip ? 100 - (10 + index * (80 / 3)) : 10 + index * (80 / 3)}%`,
    transition: 'offset-distance 800ms',
    borderRadius: '50%',
    [theme.breakpoints.down('md')]: {
      width: '14px',
      height: '14px',
    },
  })),
  Arrows: styled('div')(({ theme }) => ({
    '@keyframes fadeNavigateImage': {
      [`0%, ${300 / 44}%`]: {
        backgroundImage: `url(${arrows1})`,
      },
      [`${1100 / 44}%, ${1400 / 44}%`]: {
        backgroundImage: `url(${arrows2})`,
      },
      [`${2200 / 44}%, ${2500 / 44}%`]: {
        backgroundImage: `url(${arrows3})`,
      },
      [`${3300 / 44}%, ${3600 / 44}%`]: {
        backgroundImage: `url(${arrows4})`,
      },
      '100%': {
        backgroundImage: `url(${arrows1})`,
      },
    },
    height: '48px',
    width: '75px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    animation: 'fadeNavigateImage 4.4s linear infinite',
    animationFillMode: 'forwards',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  })),
  ArrowsInfo: styled('span')(({ theme }) => ({
    ...theme.typography.h9,
    '@keyframes fadeNavigateText': {
      [`0%, ${300 / 44}%`]: {
        color: alpha(theme.palette.text.neutral, 0.56),
      },
      [`${1100 / 44}%, ${1400 / 44}%`]: {
        color: alpha(theme.palette.text.neutral, 0.16),
      },
      [`${2200 / 44}%, ${2500 / 44}%`]: {
        color: alpha(theme.palette.text.neutral, 0.56),
      },
      [`${3300 / 44}%, ${3600 / 44}%`]: {
        color: alpha(theme.palette.text.neutral, 0.16),
      },
      '100%': {
        color: alpha(theme.palette.text.neutral, 0.56),
      },
    },
    animation: 'fadeNavigateText 4.4s linear infinite',
    animationFillMode: 'forwards',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  })),
};
