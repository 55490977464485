import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { TimeoutProps } from 'react-transition-group/Transition';
import { Styled } from './styles';

export type TransitionProps<
  RefElement extends undefined | HTMLElement = undefined,
> = TimeoutProps<RefElement>;

const FadeTransition: React.FC<React.PropsWithChildren<TransitionProps>> = ({
  children,
  timeout,
  ...props
}) => {
  if (!children) return;
  return (
    <CSSTransition
      {...props}
      timeout={timeout}
      classNames={{
        enter: 'fade-enter',
        enterActive: 'fade-enter-active',
        exit: 'fade-exit',
        exitActive: 'fade-exit-active',
      }}
    >
      <Styled.FadeTransition
        timeout={
          typeof timeout === 'number'
            ? timeout
            : timeout.appear ?? timeout.enter ?? timeout.exit ?? 0
        }
      >
        {children}
      </Styled.FadeTransition>
    </CSSTransition>
  );
};

export default FadeTransition;
