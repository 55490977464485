import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from '@mui/icons-material';
import { ArticleDTO } from '@userpath/types';
import { Lang } from '@userpath/localization';
import { Library } from '@userpath/constants';
import { formatDate, getLocalized } from '@userpath/utils';
import { Styled } from './styles';

interface IArticleCardProps {
  article: ArticleDTO;
  disableInteractions?: boolean;
}

const ArticleCard: React.FC<IArticleCardProps> = ({
  article,
  disableInteractions,
}) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const isArabic = i18n.language == Lang.AR;
  const navigate = useNavigate();
  const Icon = useMemo(
    () => (isArabic ? ArrowCircleLeftOutlined : ArrowCircleRightOutlined),
    [isArabic],
  );
  const image = useMemo(
    () => article.horizontalImage ?? article.verticalImage,
    [article.horizontalImage, article.verticalImage],
  );
  const getValue = useCallback(
    (english?: string, arabic?: string) =>
      getLocalized(isArabic, english, arabic),
    [isArabic],
  );
  const title = useMemo(
    () => getValue(article.titleEnglish, article.titleArabic),
    [getValue, article.titleArabic, article.titleEnglish],
  );
  const description = useMemo(
    () => getValue(article.descriptionEnglish, article.descriptionArabic),
    [getValue, article.descriptionArabic, article.descriptionEnglish],
  );
  const writer = useMemo(
    () => getValue(article.writerEnglish, article.writerArabic),
    [getValue, article.writerArabic, article.writerEnglish],
  );
  const tags = useMemo(
    () =>
      article.tags?.map((tag) => ({
        title: getValue(tag.titleEnglish, tag.titleArabic),
        color: tag.color,
      })),
    [article.tags, getValue],
  );

  return (
    <Styled.Container>
      {image && <Styled.ArticleImage src={image} />}
      <Styled.ArticleContainer hasImage={image != undefined}>
        {tags && tags.length > 0 && (
          <Styled.FlexContainer>
            {tags.map((tag, index) => (
              <Styled.Tag key={index} itemColor={tag.color}>
                {tag.title}
              </Styled.Tag>
            ))}
          </Styled.FlexContainer>
        )}
        <Styled.ArticleTitle>{title}</Styled.ArticleTitle>
        <Styled.FlexContainer>
          <Box flex={{ xs: '0 0 100%', sm: 'unset' }}>
            <Styled.DetailsKey>{t('articles.by')}</Styled.DetailsKey>{' '}
            <Styled.DetailsValue>{writer}</Styled.DetailsValue>
          </Box>
          <Styled.Divider sx={{ display: { xs: 'none', sm: 'block' } }} />
          {article.readingTime != undefined && article.readingTime > 0 && (
            <React.Fragment>
              <Box flex={{ xs: '0 0 100%', sm: 'unset' }}>
                <Styled.DetailsKey>
                  {t('articles.readingTime')}
                </Styled.DetailsKey>{' '}
                <Styled.DetailsValue>
                  {t('articles.minutes', { count: article.readingTime })}
                </Styled.DetailsValue>
              </Box>
              <Styled.Divider sx={{ display: { xs: 'none', sm: 'block' } }} />
            </React.Fragment>
          )}
          <Box flex={{ xs: '0 0 100%', sm: 'unset' }}>
            <Styled.DetailsKey>{t('articles.published')}</Styled.DetailsKey>{' '}
            <Styled.DetailsValue>
              {article.createdDate
                ? formatDate(new Date(article.createdDate))
                : '????'}
            </Styled.DetailsValue>
          </Box>
        </Styled.FlexContainer>
        <Styled.ArticleDetails lines={image ? '5' : '10'}>
          {description}
        </Styled.ArticleDetails>
        <Button
          variant="text"
          sx={(theme) => ({
            borderRadius: '4px',
            '& .MuiButton-endIcon': {
              marginRight: isArabic ? '8px' : '-4px',
              marginLeft: isArabic ? '-4px' : '8px',
            },
            ...theme.directional({
              marginLeft: '-8px',
            }),
          })}
          endIcon={<Icon />}
          onClick={
            disableInteractions
              ? undefined
              : () => {
                  navigate(`/article/${article.id}`);
                }
          }
        >
          {t('home.readMore')}
        </Button>
      </Styled.ArticleContainer>
    </Styled.Container>
  );
};

export default ArticleCard;
