import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { ContactForm } from '@userpath/components';
import { Styled } from './styles';
import { About, Articles, Lines, Services } from './components';

const HomePage: React.FC = () => {
  const viewAllRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const aboutContainerRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const articlesRef = useRef<HTMLDivElement>(null);
  return (
    <Box position="relative">
      <Lines
        viewAllRef={viewAllRef}
        contentRef={contentRef}
        buttonsRef={buttonsRef}
        aboutRef={aboutRef}
        aboutContainerRef={aboutContainerRef}
        videoRef={videoRef}
        videoContainerRef={videoContainerRef}
        articlesRef={articlesRef}
      />
      <Services
        viewAllRef={viewAllRef}
        contentRef={contentRef}
        buttonsRef={buttonsRef}
      />
      <Styled.BGContainer sx={{ paddingTop: '50px' }} ref={aboutRef}>
        <Styled.Container
          ref={aboutContainerRef}
          sx={(theme) => ({
            position: 'relative',
            marginTop: { xs: '0px', md: '72px' },
            paddingBottom: '170px',
            paddingTop: '100px',
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: '210px', md: '190px' },
            '&::before': {
              content: '""',
              zIndex: -5,
              background: theme.palette.background.primary,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            },
          })}
        >
          <About videoRef={videoRef} videoContainerRef={videoContainerRef} />
          <Box paddingTop={{ md: '74px' }} ref={articlesRef}>
            <Articles />
          </Box>
          <ContactForm
            variant="services"
            withBackground
            sx={{ marginBottom: { md: '100px' } }}
          />
        </Styled.Container>
      </Styled.BGContainer>
    </Box>
  );
};

export default HomePage;
