import {
  type Icon,
  CardCoin,
  MonitorMobbile,
  User,
  ChartSuccess,
  Edit,
  ClipboardTick,
  Book,
  MagicStar,
} from 'iconsax-react';

export type ServiceIndex =
  | 'service1'
  | 'service2'
  | 'service3'
  | 'service4'
  | 'service5'
  | 'service6'
  | 'service7'
  | 'service8';

const IconColors: { [key in ServiceIndex]: string } = {
  service1: '#1801A1',
  service2: '#AE02A3',
  service3: '#E5774D',
  service4: '#FED573',
  service5: '#7A0172',
  service6: '#E16332',
  service7: '#5D4DBD',
  service8: '#F0AB05',
};

const SecondaryIconColors: { [key in ServiceIndex]: string } = {
  ...IconColors,
  service4: '#976C05',
};

const Icons: { [key in ServiceIndex]: Icon } = {
  service1: CardCoin,
  service2: MonitorMobbile,
  service3: User,
  service4: ChartSuccess,
  service5: Edit,
  service6: ClipboardTick,
  service7: Book,
  service8: MagicStar,
};

export default {
  IconColors,
  SecondaryIconColors,
  Icons,
};
