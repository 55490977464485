export enum Action {
  success,
  error,
  loading,
}

export interface IDispatch<T, K> {
  type: Action;
  payload?: T;
  error?: K;
}

export interface IState<T, K> {
  loading?: boolean;
  data?: T;
  error?: K;
}

export const initialAPIState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const apiReducer =
  <T, K>() =>
  (state: IState<T, K>, dispatch: IDispatch<T, K>): IState<T, K> => {
    const { type, payload, error } = dispatch;

    switch (type) {
      case Action.success:
        return {
          loading: false,
          data: payload,
          error: undefined,
        };
      case Action.error:
        return {
          loading: false,
          data: undefined,
          error: error,
        };
      case Action.loading:
        return {
          loading: true,
          data: undefined,
          error: undefined,
        };

      default: {
        return state;
      }
    }
  };
