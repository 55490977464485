import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertColor,
  Typography,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import { Lang } from '@userpath/localization';

interface IParams {
  message: string | (() => string);
  type?: AlertColor;
  autoClose?: boolean;
}

interface IContext {
  addToast: (params: IParams) => void;
}

interface IState extends SnackbarOrigin {
  open: boolean;
  message: string | (() => string);
  type: AlertColor;
  autoClose: boolean;
}

const TOAST_DELAY = 5000;
const DEFAULT_CONTEXT_VALUE = {
  addToast: () => undefined,
};
const DEFAULT_STATE: IState = {
  open: false,
  vertical: 'top',
  horizontal: 'right',
  message: '',
  type: 'success',
  autoClose: true,
};

const ToastContext = createContext<IContext>(DEFAULT_CONTEXT_VALUE);

export const ToastContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<IState>(DEFAULT_STATE);
  const { vertical, horizontal, open, message, type, autoClose } = state;
  const { i18n } = useTranslation();
  const position = i18n.language === Lang.EN ? 'right' : 'left';

  useEffect(() => {
    if (open && horizontal !== position) {
      setState((prev) => ({
        ...prev,
        horizontal: position,
      }));
    }
  }, [open, horizontal, position]);

  const addToast = useCallback(
    (params: IParams) => {
      setState({
        ...DEFAULT_STATE,
        open: true,
        horizontal: position,
        ...params,
      });
    },
    [position],
  );

  const closeToast = useCallback(() => {
    setState((old) => ({ ...old, open: false }));
  }, []);

  const resetParams = useCallback(() => {
    setState(DEFAULT_STATE);
  }, []);

  const value = useMemo(
    () => ({
      addToast,
    }),
    [addToast],
  );

  const toastText = typeof message === 'function' ? message() : message;

  return (
    <ToastContext.Provider value={value}>
      {children}
      <Snackbar
        key={toastText}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={autoClose ? TOAST_DELAY : null}
        onClose={closeToast}
        TransitionProps={{
          onExited: resetParams,
        }}
        ClickAwayListenerProps={autoClose ? {} : { mouseEvent: false }}
      >
        <Alert onClose={closeToast} severity={type}>
          <Typography
            component="div"
            dangerouslySetInnerHTML={{ __html: toastText }}
          />
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext<IContext>(ToastContext);
