import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import { type ResponsiveStyleValue } from '@mui/system';
import { resolveBreakpointValues } from '@mui/system/breakpoints';

export interface StyleResult {
  currentBreakpoint: Breakpoint;
  isXS: boolean;
  isSM: boolean;
  isMD: boolean;
  isLG: boolean;
  isXL: boolean;
}

export const useStyleResolver = <T>(
  value: T | ResponsiveStyleValue<T> | undefined,
): T | undefined => {
  const theme = useTheme();
  const mq_xl = useMediaQuery(theme.breakpoints.only('xl'));
  const mq_lg = useMediaQuery(theme.breakpoints.only('lg'));
  const mq_md = useMediaQuery(theme.breakpoints.only('md'));
  const mq_sm = useMediaQuery(theme.breakpoints.only('sm'));

  if (value == undefined) return undefined;
  const resolved = resolveBreakpointValues({
    values: value,
    breakpoints: theme.breakpoints.values,
  });
  if (typeof resolved === 'object') {
    if (mq_xl) {
      return (
        resolved['xl'] ||
        resolved['lg'] ||
        resolved['md'] ||
        resolved['sm'] ||
        resolved['xs']
      );
    } else if (mq_lg) {
      return (
        resolved['lg'] || resolved['md'] || resolved['sm'] || resolved['xs']
      );
    } else if (mq_md) {
      return resolved['md'] || resolved['sm'] || resolved['xs'];
    } else if (mq_sm) {
      return resolved['sm'] || resolved['xs'];
    } else {
      return resolved['xs'];
    }
  } else {
    return resolved;
  }
};
