import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flipped, spring } from 'react-flip-toolkit';
import { Library } from '@userpath/constants';
import { ServicesConstants, ServiceIndex } from '../../../common';
import { Styled } from './styles';

interface IServiceItemsProps {
  index: ServiceIndex;
}

const ServiceItems: React.FC<IServiceItemsProps> = ({ index }) => {
  const { t, i18n } = useTranslation(Library.userpath);
  const [height, setHeight] = useState<number>(0);
  const items = useMemo(() => {
    const key = `services.${index}.items`;
    return i18n.exists(key, { ns: Library.userpath })
      ? t<string, { returnObjects: true }, string[]>(key, {
          returnObjects: true,
        })
      : [];
  }, [i18n, t, index]);
  const updateHeight = useCallback(
    (el: HTMLElement) => {
      if (items.length > 0) {
        setHeight(el.offsetHeight);
      }
    },
    [items],
  );
  return (
    <Flipped flipId="list" onStart={updateHeight} onComplete={updateHeight}>
      <Styled.ItemsContainer
        itemColor={ServicesConstants.SecondaryIconColors[index]}
        empty={items.length == 0}
      >
        {items.map((item, i) => (
          <Flipped
            key={item}
            flipId={`item_${i}`}
            onAppear={onElementAppear}
            onExit={onExit(
              ServicesConstants.SecondaryIconColors[index],
              items.length - i,
            )}
          >
            <Styled.ServiceItem
              itemColor={ServicesConstants.SecondaryIconColors[index]}
            >
              {item}
            </Styled.ServiceItem>
          </Flipped>
        ))}
        {items.length == 0 && <Styled.AnimatedHeight lastHeight={height} />}
      </Styled.ItemsContainer>
    </Flipped>
  );
};

const onElementAppear = (el: HTMLElement, index: number) =>
  spring({
    onUpdate: (val) => {
      el.style.opacity = val.toString();
    },
    delay: index * 50,
  });

const onExit =
  (color: string, i: number) =>
  (el: HTMLElement, _: number, removeElement: () => void) => {
    spring({
      config: { overshootClamping: true },
      onUpdate: (val) => {
        el.style.color = color;
        if (typeof val === 'number') el.style.transform = `scaleY(${1 - val})`;
      },
      delay: i * 20,
      onComplete: removeElement,
    });

    return () => {
      el.style.opacity = '';
      removeElement();
    };
  };

export default ServiceItems;
