import {
  QueryDocumentSnapshot,
  FirestoreDataConverter,
  WithFieldValue,
  FieldValue,
  Timestamp,
  DocumentData,
} from 'firebase/firestore/lite';
import { ContactForm, ContactFormDTO } from '@userpath/types';

export class ContactFormConverter
  implements FirestoreDataConverter<ContactFormDTO, ContactForm>
{
  toFirestore(
    contactForm: WithFieldValue<ContactFormDTO>,
  ): WithFieldValue<ContactForm> {
    return {
      ...contactForm,
      createdDate: this._convertDate(contactForm.createdDate),
    };
  }

  fromFirestore(
    snapshot: QueryDocumentSnapshot<DocumentData, DocumentData>,
  ): ContactFormDTO {
    const data = snapshot.data() as ContactForm;
    return {
      ...data,
      id: snapshot.id,
      createdDate: data.createdDate.toMillis(),
    };
  }

  _convertDate(date: number | FieldValue): Timestamp | FieldValue {
    if (typeof date !== 'number') {
      return date;
    }
    return Timestamp.fromMillis(date);
  }
}
