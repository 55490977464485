import React, { forwardRef, useState } from 'react';
import { Flipper } from 'react-flip-toolkit';
import { Box, BoxProps } from '@mui/material';
import { ServicesConstants, ServiceIndex } from '../../../common';
import ServiceHeaderItem from './ServiceHeaderItem';
import ServiceContent from './ServiceContent';
import ServiceItems from './ServiceItems';
import { Styled } from './styles';

const Services = forwardRef<HTMLDivElement, BoxProps>(({ ...props }, ref) => {
  const [selected, setSelected] = useState<ServiceIndex>('service1');

  return (
    <Box ref={ref} {...props}>
      <Styled.Header>
        {(Object.keys(ServicesConstants.Icons) as ServiceIndex[]).map(
          (item, i) => (
            <ServiceHeaderItem
              key={i}
              index={item}
              selected={selected == item}
              onSelected={() => setSelected(item)}
            />
          ),
        )}
      </Styled.Header>
      <Flipper flipKey={selected} spring="gentle">
        <Styled.ContentContainer>
          {/* <SwitchTransition>
            <Styled.Transition key={selected} timeout={300}>
              <ServiceContent index={selected} />
            </Styled.Transition>
          </SwitchTransition> */}
          <ServiceContent index={selected} />
          <ServiceItems index={selected} />
        </Styled.ContentContainer>
      </Flipper>
    </Box>
  );
});

export default Services;
