import { useMemo } from 'react';
import {
  collection,
  doc,
  getCount,
  getDoc,
  getDocs,
  query,
  orderBy,
  limit,
  CollectionReference,
  WithFieldValue,
  PartialWithFieldValue,
  setDoc,
  deleteDoc,
  serverTimestamp,
} from 'firebase/firestore/lite';
import { useFirestore } from '@userpath/components';
import {
  Article,
  ArticleDTO,
  ArticleConverter,
  ContentConverter,
  ContentDTO,
} from '@userpath/types';

const getLatestArticles = (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
) => {
  return getAllArticles(articlesRef, 5);
};

const getAllArticles = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  limitArticles?: number,
) => {
  const latestArticlesQuery = query(
    articlesRef,
    orderBy('createdDate', 'desc'),
  );
  const articles = await getDocs(
    limitArticles
      ? query(latestArticlesQuery, limit(limitArticles))
      : latestArticlesQuery,
  );
  return articles.docs.map((item) => item.data());
};

const getArticle = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  id: string,
) => {
  const article = await getDoc(doc(articlesRef, id));
  return article.data();
};

const getArticleContent = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  id: string,
  lang: string,
) => {
  const content = await getDoc(
    doc(articlesRef, id, 'contents', lang).withConverter(
      new ContentConverter(),
    ),
  );
  return content.data();
};

const getArticleLikes = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  id: string,
) => {
  const articleDocRef = doc(articlesRef, id);
  const likesRef = collection(articleDocRef, 'likes');
  const count = await getCount(likesRef);
  return count.data().count;
};

const didUserLikeArticle = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  uid: string,
  id: string,
) => {
  const articleDocRef = doc(articlesRef, id);
  const likesRef = collection(articleDocRef, 'likes');
  const likeRef = doc(likesRef, uid);
  return (await getDoc(likeRef)).exists();
};

const likeArticle = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  uid: string,
  id: string,
) => {
  const articleDocRef = doc(articlesRef, id);
  const likesRef = collection(articleDocRef, 'likes');
  const likeRef = doc(likesRef, uid);
  await setDoc(likeRef, {
    time: serverTimestamp(),
  });
};

const dislikeArticle = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  uid: string,
  id: string,
) => {
  const likeRef = doc(articlesRef, id, 'likes', uid);
  await deleteDoc(likeRef);
};

const updateArticle = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  id: string,
  article: PartialWithFieldValue<ArticleDTO>,
) => {
  const articleDocRef = doc(articlesRef, id);
  await setDoc(articleDocRef, article, { merge: true });
};

const updateArticleContent = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  id: string,
  lang: string,
  content: PartialWithFieldValue<ContentDTO>,
) => {
  const contentDocRef = doc(articlesRef, id, 'contents', lang).withConverter(
    new ContentConverter(),
  );
  await setDoc(contentDocRef, content, { merge: true });
};

const deleteArticle = async (
  articlesRef: CollectionReference<ArticleDTO, WithFieldValue<Article>>,
  id: string,
) => {
  await deleteDoc(doc(articlesRef, id));
  await deleteDoc(doc(articlesRef, id, 'contents', 'en'));
  await deleteDoc(doc(articlesRef, id, 'contents', 'ar'));
};

export const useArticles = () => {
  const firestore = useFirestore();
  const articlesRef = useMemo(
    () =>
      collection(firestore, 'articles').withConverter(new ArticleConverter()),
    [firestore],
  );
  const result = useMemo(
    () => ({
      articlesReference: articlesRef,
      generateId: () => doc(articlesRef).id,
      updateArticle: (id: string, article: PartialWithFieldValue<ArticleDTO>) =>
        updateArticle(articlesRef, id, article),
      getArticle: (id: string) => getArticle(articlesRef, id),
      getArticleLikes: (id: string) => getArticleLikes(articlesRef, id),
      didUserLikeArticle: (uid: string, id: string) =>
        didUserLikeArticle(articlesRef, uid, id),
      likeArticle: (uid: string, id: string) =>
        likeArticle(articlesRef, uid, id),
      dislikeArticle: (uid: string, id: string) =>
        dislikeArticle(articlesRef, uid, id),
      getArticleContent: (id: string, lang: string) =>
        getArticleContent(articlesRef, id, lang),
      updateArticleContent: (
        id: string,
        lang: string,
        content: PartialWithFieldValue<ContentDTO>,
      ) => updateArticleContent(articlesRef, id, lang, content),
      getLatestArticles: () => getLatestArticles(articlesRef),
      getAllArticles: (limit?: number) => getAllArticles(articlesRef, limit),
      deleteArticle: (id: string) => deleteArticle(articlesRef, id),
    }),
    [articlesRef],
  );
  return result;
};
